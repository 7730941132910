import * as actionTypes from "./actionTypes";
import { firebase } from "../../components/Auth/firebase";
import { addPushNotificationId, getCheckedId } from "../../components/Utils/API/apiUsers";
import { SecondsToDiscardDoorRing } from "../../components/Utils/Constants/validationValues";
// import { showErrorInterceptor } from "./general_actions";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";

const requestPushNotificationPermission = () => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_PERMISSION_REQUEST,
  };
};

const receivePushNotificationToken = (pushNotificationToken: string) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_PERMISSION_SUCCESS,
    pushNotificationToken,
  };
};

const errorRequestPushNotification = (errorCode: string) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_PERMISSION_FAILURE,
    errorCode,
  };
};

const messagePushNotification = (message: any, listener: any) => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_MESSAGE,
    message,
    listener,
  };
};

const deletedPushNotification = () => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_DELETED_TOKEN,
  };
};

const unsubscribePushNotification = () => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_UNSUBSCRIBE,
  };
};

export const readALLPushNotification = () => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_READ_ALL,
  };
};
export const removeALLPushNotification = () => {
  return {
    type: actionTypes.PUSH_NOTIFICATION_REMOVE_ALL,
  };
};
///////////FIRESTORE
export const addNewFaceEnrollment = (userId: string) => {
  return {
    type: actionTypes.FIRESTORE_FACEENROLLMENT_ADD,
    userId,
  };
};

export const deleteForFaceEnrollment = (userId: string) => {
  return {
    type: actionTypes.FIRESTORE_FACEENROLLMENT_DELETE,
    userId,
  };
};

export const addNewNetworkSettings = (serialNumber: string) => {
  return {
    type: actionTypes.FIRESTORE_NETWORKSETTINGS_ADD,
    serialNumber,
  };
};

export const deleteNetworkSettings = (serialNumber: string) => {
  return {
    type: actionTypes.FIRESTORE_NETWORKSETTINGS_DELETE,
    serialNumber,
  };
};

export const addNewCardEnrollment = (userId: string) => {
  return {
    type: actionTypes.FIRESTORE_CARDENROLLMENT_ADD,
    userId,
  };
};

export const deleteForCardEnrollment = (userId: string) => {
  return {
    type: actionTypes.FIRESTORE_CARDENROLLMENT_DELETE,
    userId,
  };
};

const savedFireStoreDoorRingSerial = (serialNumber: string) => {
  return {
    type: actionTypes.FIRESTORE_DOORRING_SAVE,
    serialNumber,
  };
};

export const removedFireStoreDoorRingSerial = (serialNumber: string) => {
  return {
    type: actionTypes.FIRESTORE_DOORRING_DELETE,
    serialNumber,
  };
};
////////////

// Dispatch section
///////////////////
export const receivedSerialNumberDoorRingNotification = (serialNumber: string) => {
  return async (dispatch: any, getState: any) => {
    dispatch(savedFireStoreDoorRingSerial(serialNumber));
    setTimeout(() => {
      dispatch(removedFireStoreDoorRingSerial(serialNumber));
    }, SecondsToDiscardDoorRing * 1000);
  };
};

async function getPushNotificationToken(): Promise<string> {
  const messaging = firebase.messaging();
  const swRegistration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
  const response: any = await messaging
    .getToken({
      serviceWorkerRegistration: swRegistration,
      vapidKey: process.env.REACT_APP_FIREBASE_WEB_PushNotification_vapidKey,
    }) //https://firebase.google.com/docs/reference/js/v8/firebase.messaging.Messaging
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.error("No registration token available. Request permission to generate one.");
        return "Error_NotificationNoRegistrationTokenAvailable";
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
      return "Error_NotificationAnErrorOcurred";
    });
  return response;
}

const removeNotificationToken = async (dispatch: any) => {
  const messaging = firebase.messaging();
  //Locally removing the id
  await messaging
    .deleteToken()
    .then(async (value: any) => {
      if (value) {
        dispatch(deletedPushNotification());
      }
    })
    .catch((error) => {
      if (error.status === "404") {
        console.error("Not Found");
      } else {
        console.error("Denied Notification Permission");
      }
    });
};

const addNewNotificationToken = async (dispatch: any, counterEntry: number) => {
  //Obtaining a new token again
  const newIdConnection: string = await getPushNotificationToken();
  const temp = newIdConnection.split("_");
  const messaging = firebase.messaging();

  if (counterEntry <= 2) {
    if (temp[0] != "Error") {
      try {
        const response = await addPushNotificationId(newIdConnection);
        if (!response.data.hasErrors) {
          dispatch(receivePushNotificationToken(newIdConnection));
          const pushNotificationListener = messaging.onMessage((payload: any) => {
            //https://firebase.google.com/docs/reference/js/firebase.messaging.Messaging#onmessage
            dispatch(messagePushNotification(payload, pushNotificationListener));
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      dispatch(deletedPushNotification());
      await addNewNotificationToken(dispatch, counterEntry);
      counterEntry++;
    }
  } else {
    Sentry.captureMessage(newIdConnection, {
      level: Severity.Error,
    });
  }
};

const obtainNewNotificationToken = async (dispatch: any) => {
  // Obtain the new token
  const idConnection: string = await getPushNotificationToken();
  const temp = idConnection.split("_");
  const messaging = firebase.messaging();
  const counterEntry = 0;

  if (temp[0] != "Error") {
    //Checking if the id belongs to the user
    try {
      const response = await getCheckedId(idConnection);
      if (response.data.hasErrors) {
        await removeNotificationToken(dispatch);
        await addNewNotificationToken(dispatch, counterEntry);
      } else {
        if (!response.data.payload) {
          try {
            const response = await addPushNotificationId(idConnection);
            if (!response.data.hasErrors) {
              dispatch(receivePushNotificationToken(idConnection));
              const pushNotificationListener = messaging.onMessage((payload: any) => {
                //https://firebase.google.com/docs/reference/js/firebase.messaging.Messaging#onmessage
                dispatch(messagePushNotification(payload, pushNotificationListener));
              });
            }
          } catch (error) {
            console.log("error", error);
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  } else {
    dispatch(errorRequestPushNotification(idConnection));
  }
};

export const requestPermissionPushNotification = () => {
  return async (dispatch: any, getState: any) => {
    if (!("Notification" in window)) {
      //TODO: Do I need to inform the backend about this?
      dispatch(errorRequestPushNotification("NotificationNoSupported"));
    } else if (Notification.permission === "granted") {
      await obtainNewNotificationToken(dispatch);
    } else if (Notification.permission === "denied") {
      console.log("Notification Permission Denied");
      // notifications disabled
      //TODO: Read a cooking if I showed this, if not set a cookee for 5 days and show the before message
      //TODO: check if the Store if was granted permission before, and if was granted delete the permission from the store and inform the API
      // getState().notifications.isNotificationEnabled
    } else {
      // dispatch(requestPushNotificationPermission());
      await Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          await obtainNewNotificationToken(dispatch);
        } else {
          //TODO: What to do here?
          console.error("Unable to get permission to notify.");
        }
      });
    }
  };
};
