import React from "react";
import EventVideoHLS from "../../Utils/Custom/VideoDoorWebRTC/EventVideoHLS";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spaceTop: {
      //
    },
    media: {
      objectFit: "cover",
      width: "15%",
      marginTop: theme.spacing(1),
    },
  })
);
interface Props {
  url: string;
  deviceName: string;
  time: string;
}
export default function ShowPublicEventVideo({ url, deviceName, time }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justify="center" style={{ width: "100%" }}>
      <Grid container justify="center">
        <img src={"/images/alocity-logo-color.png"} alt="Alocity logo" className={classes.media} />
      </Grid>
      {/* <Grid item className={classes.spaceTop}>
        <Typography>{t("EventVideo")}</Typography>
      </Grid> */}
      <Grid item xs={12} className={classes.spaceTop}>
        <EventVideoHLS
          deviceName={deviceName}
          serialNumber="public"
          time={time + "Z"}
          isPublic={true}
          url={url}
        />
      </Grid>
    </Grid>
  );
}
