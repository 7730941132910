import React from "react";
import "./ArrowLoading.css";
import { useTheme } from "@material-ui/core/styles";
interface Props {
  color: string;
  style: any;
}
const ArrowLoading = ({ color, style }: Props) => {
  const theme = useTheme();
  return (
    <div className={"center"} style={style}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="arrow">
          <path
            id="Vector1"
            d="M3 4.414L10.293 11.707L3 19L4.414 20.414L13.121 11.707L4.414 3L3 4.414Z"
            fill={color}
          />
          <path
            id="Vector2"
            d="M7 4.414L14.293 11.707L7 19L8.414 20.414L17.121 11.707L8.414 3L7 4.414Z"
            fill={color}
          />

          {/* <path
            id="Vector3"
            d="M11 4.414L18.293 11.707L11 19L12.414 20.414L21.121 11.707L12.414 3L11 4.414Z"
            fill={color}
          /> */}
        </g>
      </svg>
    </div>
  );
};
export default ArrowLoading;
