export enum CatalogUnitType {
  Unit = 0,
  Apt = 1,
  Home = 2,
  Lobby = 4,
  None = 5,
}

interface CatalogEntryVM {
  displayName: string;
  unitType: CatalogUnitType;
  entryId: string;
  siteId: number;
  fullName: string;
  siteName: string;
  userId: string;
  id: string;
  floorId: string;
  floorName: string;
  intercomCallMode: IntercomCallModeType;
  from: string;
  to: string;
}
export interface CatalogEntryModel extends CatalogEntryVM {
  //
}

export enum IntercomCallModeType {
  MobileApp = 0,
  CallLandline = 1,
}
