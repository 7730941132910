import React, { Fragment, useEffect, useState } from "react";
import Popper from "@material-ui/core/Popper";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckedIcon from "@material-ui/icons/Check";
import Divider from "@material-ui/core/Divider";
import { AccountModel } from "../../Utils/Models/account";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "@material-ui/core";
import Data from "../../Utils/Constants/values";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 9999,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.9em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
      },

      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.9em",
        width: "3em",
        height: "1em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.9em",
        height: "3em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.9em",
        height: "3em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
      },
    },
    arrow: {
      zIndex: 9999,
      position: "absolute",
      fontSize: 7,
      width: "3em",
      height: "3em",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    cardContentRoot: {
      padding: "10px",
      paddingTop: "0px",
      "&:last-child": {
        paddingBottom: "0",
      },
    },
  })
);
interface Props {
  open: boolean;
  reference: any;
  onClose: () => void;
  onClickProfile: () => void;
  onClickSignOut: () => void;
  onClickSubscriptions: () => void;
  userName: string;
  roleName: string;
  totalOfAccounts: AccountModel[];
  selectedAccount: AccountModel;
  onChangeAccount: (account: AccountModel) => void;
  loadingAccounts: boolean;
  hasPermissionSubscriptionRead: boolean;
  showProfileOption: boolean;
}
const ProfileMenu = ({
  open,
  reference,
  onClose,
  onClickProfile,
  onClickSignOut,
  userName,
  roleName,
  totalOfAccounts,
  selectedAccount,
  onChangeAccount,
  loadingAccounts,
  onClickSubscriptions,
  hasPermissionSubscriptionRead,
  showProfileOption,
}: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const [arrowRef, setArrowRef] = React.useState(null);
  const [openLegal, setOpenLegal] = useState(false);
  return (
    <Fragment>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={reference}
        placement="bottom"
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <Collapse {...TransitionProps} timeout={350}>
              {/* <Card variant={"elevation"}> */}
              <div className={classes.arrow} ref={setArrowRef as any} />
              <ClickAwayListener
                onClickAway={() => onClose()}
                // touchEvent={false}
              >
                <Card>
                  <CardContent
                    classes={{
                      root: classes.cardContentRoot,
                    }}
                  >
                    <List>
                      {loadingAccounts ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress size={20} />
                        </div>
                      ) : (
                        totalOfAccounts.map((option, index) => (
                          <ListItem
                            disableGutters
                            dense
                            button
                            key={index}
                            // selected={option.accountId === selectedAccount.accountId}
                            onClick={(event) => {
                              onChangeAccount(option);
                            }}
                          >
                            <Typography variant="inherit" noWrap>
                              {option.name}
                            </Typography>
                            <ListItemSecondaryAction>
                              {option.id === selectedAccount.id ? (
                                <CheckedIcon color={"primary"} fontSize={"small"} />
                              ) : null}
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))
                      )}
                      <Divider />
                      {/* <ListItem
                        disableGutters
                        dense
                        divider
                      >
                        <ListItemText
                          primary={userName}
                          secondary={userRole}
                          primaryTypographyProps={{
                            variant: "h6"
                          }}
                          secondaryTypographyProps={{
                            variant: "subtitle2"
                          }}
                        />

                      </ListItem> */}
                      {showProfileOption ? (
                        <ListItem disableGutters dense button onClick={onClickProfile}>
                          <ListItemText primary={t("Profile")} />
                        </ListItem>
                      ) : null}
                      {/* {hasPermissionSubscriptionRead  ? (
                        <ListItem disableGutters dense button onClick={onClickSubscriptions}>
                          <ListItemText primary={t("BillingSubscriptions")} />
                        </ListItem>
                      ) : null} */}
                      <ListItem
                        disableGutters
                        dense
                        button
                        onClick={() => setOpenLegal((prev) => !prev)}
                      >
                        <ListItemText primary={t("Legal")} />
                        {openLegal ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openLegal} timeout="auto" unmountOnExit>
                        <List
                          component="div"
                          disablePadding
                          style={{ backgroundColor: theme.palette.grey[50] }}
                        >
                          <ListItem
                            dense
                            button
                            onClick={() => {
                              window.open(Data.PRIVACY_MAIN, "_blank", "noopener");
                            }}
                            // style={{ paddingLeft: 24 }}
                          >
                            <ListItemText primary={t("MainServicesAgreement")} />
                          </ListItem>
                          <ListItem
                            // style={{ paddingLeft: 24 }}

                            dense
                            button
                            onClick={() => {
                              window.open(Data.PRIVACY_BIO, "_blank", "noopener");
                            }}
                          >
                            <ListItemText primary={t("BiometricPrivacyConsent")} />
                          </ListItem>
                        </List>
                      </Collapse>

                      <ListItem disableGutters dense button onClick={onClickSignOut}>
                        <ListItemText primary={t("SignOut")} />
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </ClickAwayListener>
            </Collapse>
          );
        }}
      </Popper>
    </Fragment>
  );
};
export default ProfileMenu;
