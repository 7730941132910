import { iconColor } from "../palette";

export const MuiIcon = {
  MuiIcon: {
    root: {
      // color: iconColor, //this change color to Icon component and font component
    },
  },
  MuiSvgIcon: {
    root: {
      // color: iconColor, //this change color to SVG component
    },
  },
};
