import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../components/Utils/Custom/HelperCode";
import { GeneralReducerModel } from "../../components/Utils/Models/store";
import { PortalIds } from "../../components/Utils/Models/account";

const initialState: GeneralReducerModel = {
  showLoadingComponent: false,
  interceptorErrorMessage: "",
  interceptorErrorId: 0,
  portalId: PortalIds.AccessControl,
  totalDevicesConnectedWebRTC: [],
  isGeneralLockdownActivated: false,
  isLockdownPlanSetUp: false,
  generalLockdownStartTime: "",
  generalLockdownDuration: 0,
  didCallAPILockdownInfo: false,
  liveStringFromDevice: null,
  pendingDeviceInfoDownloadFromCloud: undefined,
  downloadingPercent: 0,
};

//////////////Reducer Slice//////////////////////
const showLoadingComponent_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, { showLoadingComponent: action.activate } as GeneralReducerModel);
};

const showErrorTextInterceptor_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    interceptorErrorMessage: action.errorCode,
    interceptorErrorId: Math.random(),
  } as GeneralReducerModel);
};

const changePortal_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, { portalId: action.portalId } as GeneralReducerModel);
};

const reset_Reducer = (state: GeneralReducerModel, action: any) => {
  const valuePortalId = state.portalId;
  const initial = { ...initialState };
  initial.portalId = valuePortalId;
  return updateObject(state, { ...initial } as GeneralReducerModel);
};

const addDeviceActiveWebRTCConnection_Reducer = (state: GeneralReducerModel, action: any) => {
  const total = [...state.totalDevicesConnectedWebRTC];
  if (total.findIndex((value) => value === action.serialNumber) === -1) {
    total.push(action.serialNumber);
  }
  return updateObject(state, {
    totalDevicesConnectedWebRTC: total,
  } as GeneralReducerModel);
};

const deleteDeviceActiveWebRTCConnection_Reducer = (state: GeneralReducerModel, action: any) => {
  const total: string[] = [...state.totalDevicesConnectedWebRTC];
  if (total.findIndex((value) => value === action.serialNumber) > -1) {
    total.splice(
      total.findIndex((value) => value === action.serialNumber),
      1
    );
  }
  return updateObject(state, {
    totalDevicesConnectedWebRTC: total,
  } as GeneralReducerModel);
};

const updateStatusGeneralLockdown_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    isGeneralLockdownActivated: action.isGeneralLockdownActivated,
    generalLockdownStartTime: action.generalLockdownStartTime,
    generalLockdownDuration: action.generalLockdownDuration,
    isLockdownPlanSetUp: action.isLockdownPlanSetUp,
    didCallAPILockdownInfo: true,
  } as GeneralReducerModel);
};

const isLockdownPlanSetUp_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    isLockdownPlanSetUp: action.value,
  } as GeneralReducerModel);
};

const didCallAPILockdownInfo_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    didCallAPILockdownInfo: action.value,
  } as GeneralReducerModel);
};

const liveStringFromDevice_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    liveStringFromDevice: action.value,
  } as GeneralReducerModel);
};
const downloadVideoFromCloud_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    pendingDeviceInfoDownloadFromCloud: action.value,
  } as GeneralReducerModel);
};
const downloadVideoFromCloudPercent_Reducer = (state: GeneralReducerModel, action: any) => {
  return updateObject(state, {
    downloadingPercent: action.value,
  } as GeneralReducerModel);
};
/////REDUCER//////
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADING_COMPONENT:
      return showLoadingComponent_Reducer(state, action);
    case actionTypes.SHOW_ERROR_TEXT_INTERCEPTOR:
      return showErrorTextInterceptor_Reducer(state, action);
    case actionTypes.CHANGE_PORTAL:
      return changePortal_Reducer(state, action);
    case actionTypes.RESET_GENERALSTORE_VALUES:
      return reset_Reducer(state, action);
    case actionTypes.ADD_DEVICE_WEBRTC_ACTIVE_CONNECTION:
      return addDeviceActiveWebRTCConnection_Reducer(state, action);
    case actionTypes.DELETE_DEVICE_WEBRTC_ACTIVE_CONNECTION:
      return deleteDeviceActiveWebRTCConnection_Reducer(state, action);
    case actionTypes.IS_ACTIVE_GENERAL_LOCKDOWN:
      return updateStatusGeneralLockdown_Reducer(state, action);
    case actionTypes.IS_LOCKDOWN_PLAN_SETUP:
      return isLockdownPlanSetUp_Reducer(state, action);
    case actionTypes.DID_CALL_API_LOCKDOWN_INFO:
      return didCallAPILockdownInfo_Reducer(state, action);
    case actionTypes.LIVE_STRING_FROM_DEVICE:
      return liveStringFromDevice_Reducer(state, action);
    case actionTypes.DOWNLOAD_VIDEO_FROM_CLOUD:
      return downloadVideoFromCloud_Reducer(state, action);
    case actionTypes.DOWNLOAD_VIDEO_FROM_CLOUD_PERCENT:
      return downloadVideoFromCloudPercent_Reducer(state, action);
    default:
      return state;
  }
};
export default reducer;
