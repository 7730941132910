import { tableHeaderColor } from "../palette";

export const MuiTable = {
  MuiTableHead: {
    root: {
      backgroundColor: tableHeaderColor,
    },
  },
  MuiTableCell: {
    // head: {
    //   color: "#fff"
    // }
  },
  MuiTableSortLabel: {
    root: {
      // "&:hover": {
      //   color: "#fff !important",
      //   opacity: 0.5
      // },
      // "&:focus": {
      //   color: "#fff !important"
      // }
    },
    // active: {

    //   color: "#fff !important"
    // },
    // icon: {
    //   color: "#fff !important"

    // }
  },
  MuiTablePagination: {
    // actions: {
    //   border: "1px solid"
    // }
  },
  MuiTableRow: {
    root: {},
  },
  MuiTableContainer: {
    root: {
      // border: "1px solid #e2e4ef"
    },
  },
};
