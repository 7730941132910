import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { backgroundSignInSignUpColor } from "../../../../assets/theme/palette";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    placeItems: "center",
    minHeight: "100vh",
    width: "100%",
    overflowY: "auto",
    backgroundColor: backgroundSignInSignUpColor,
  },
}));
interface Props {
  children: any;
}

const WrapperSignInSignUp = ({ children }: Props) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default WrapperSignInSignUp;
