import React, { useState } from "react";
import { toast } from "react-toastify";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import { useFormik } from "formik";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { emailValidation, phoneNumberValidation } from "../../../validations";
import { shareEventVideo, shareVideoPlaylist } from "../../../../API/apiDevices";
import axios, { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CustomPhoneNumberInput from "../../../CustomPhoneNumberInput";
import LoadingButton from "../../../LoadingButton";
import Typography from "@material-ui/core/Typography";
import styles from "./OperationsOverTimespanSelection.module.css";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import RadioCheckedIcon from "@material-ui/icons/Check";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { InfoToDownloadFromCloud } from "../../../../Models/devices";
import StoreStateModel from "../../../../Models/store";
import * as actions from "../../../../../../store/actions";

interface Props {
  startDateTime: string;
  endDateTime: string;
  serialNumber: string;
  deviceName: string;
  isPublic: boolean;
  isDynamicPlaylist: boolean;
  onClose: () => void;
  refPlaylistToDownload: string;
  refSignedUrls: string[];
  onShowDialogToShareUnderDynamicPlaylist: () => void;
  onShowDialogToDownloadUnderDynamicPlaylist: () => void;
  onStartDownloadTask: () => void;
  onStartShareTask: () => void;
  timeZoneOffset: string;
}
export default function OperationsOverTimespanSelection({
  startDateTime,
  serialNumber,
  onClose,
  deviceName,
  endDateTime,
  isPublic,
  isDynamicPlaylist,
  refPlaylistToDownload,
  refSignedUrls,
  onShowDialogToShareUnderDynamicPlaylist,
  onShowDialogToDownloadUnderDynamicPlaylist,
  onStartDownloadTask,
  onStartShareTask,
  timeZoneOffset,
}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("share");
  const [openShareDialog, setOpenShareDialog] = useState(false);

  const dispatch = useDispatch();
  let pendingDeviceInfoDownloadFromCloud: InfoToDownloadFromCloud | undefined = undefined;
  let downloadingPercent = 0;
  let accountId = -1;
  let userName;
  if (!isPublic) {
    pendingDeviceInfoDownloadFromCloud = useSelector(
      (state: StoreStateModel) => state.general.pendingDeviceInfoDownloadFromCloud,
      shallowEqual
    );

    downloadingPercent = useSelector(
      (state: StoreStateModel) => state.general.downloadingPercent,
      shallowEqual
    );

    accountId = useSelector((state: StoreStateModel) => state.auth.accountId, shallowEqual);

    userName = useSelector((state: StoreStateModel) => state.auth.userName, shallowEqual);
  }

  return (
    <Grid container className={styles.root}>
      <Grid item xs={12}>
        <Typography variant="body1">{`${t(
          "SelecWhatOperationYouWantOverTheRangeOfTimes"
        )}:`}</Typography>
      </Grid>
      <Grid container justify="space-evenly" className={styles.spaceTop}>
        <Grid item>
          <Typography variant="body2">
            {moment(startDateTime).utcOffset(timeZoneOffset).local().format("LL hh:mm:ss a")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {moment(endDateTime).utcOffset(timeZoneOffset).local().format("LL hh:mm:ss a")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="center" className={styles.spaceTop}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="operation selection"
            name="operation-selection"
            value={selectedRadio}
            onChange={(event) => setSelectedRadio(event.target.value)}
          >
            <FormControlLabel
              value={"share"}
              control={
                <Radio
                  size="small"
                  color={"primary"}
                  checkedIcon={<RadioCheckedIcon fontSize="small" color={"primary"} />}
                />
              }
              label={t("Share")}
              disabled={isPublic}
            />
            <FormControlLabel
              value={"download"}
              control={
                <Radio
                  size="small"
                  color={"primary"}
                  checkedIcon={<RadioCheckedIcon fontSize="small" color={"primary"} />}
                />
              }
              label={
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {t("Download")}
                  {!pendingDeviceInfoDownloadFromCloud ? null : (
                    <Tooltip title={t("ThereIsADownloadInProccess")}>
                      <div
                        style={{
                          marginLeft: 2,
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "center",
                          // padding: 10,
                        }}
                      >
                        <CircularProgress
                          color="primary"
                          size={25}
                          variant="static"
                          value={downloadingPercent}
                        />
                        <Typography
                          variant="caption"
                          style={{ position: "absolute", left: 5, top: 3 }}
                          color="inherit"
                        >
                          {downloadingPercent}
                        </Typography>
                      </div>
                    </Tooltip>
                  )}
                </div>
              }
              disabled={isPublic || Boolean(pendingDeviceInfoDownloadFromCloud)}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid container justify="center" className={styles.spaceTop}>
        <Grid item>
          <LoadingButton
            // type="submit"
            variant="contained"
            color="primary"
            disabled={isPublic}
            pending={loading}
            onClick={() => {
              switch (selectedRadio) {
                case "share":
                  if (isDynamicPlaylist) {
                    onShowDialogToShareUnderDynamicPlaylist();
                  } else {
                    onStartShareTask();
                  }
                  break;
                case "download":
                  if (isDynamicPlaylist) {
                    onShowDialogToDownloadUnderDynamicPlaylist();
                  } else {
                    onStartDownloadTask();
                  }
                  break;
              }
            }}
          >
            {t("Start")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
