import { Theme } from "@material-ui/core/styles";

const appStyles = (theme: Theme) => ({
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // modalPaper: {
  //   backgroundColor: theme.palette.background.paper,
  //   // border: '1px solid #000',
  //   // boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  app__container__marginTop: {
    marginTop: theme.spacing(2),
  },
  openDoorButton: {
    backgroundColor: "#4BB543",
    color: "#fff",

    "&:hover": {
      backgroundColor: "#008410",
    },
  },
});

export default appStyles;
