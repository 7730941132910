import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./en/translation";
import translationES from "./es/translation";

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    load: "languageOnly", // we only provide en, de -> no region specific locals like en-US, de-DE
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
      // useSuspense: false
    },

    saveMissing: true,

    missingKeyHandler: function (locale, ns, key) {
      console.error("The key does not exist in the translation file: " + key);
      if (process.env.NODE_ENV === "development") {
        // dev code
        throw new Error("The key does not exist in the translation file: " + key);
      } else {
        // production code, nothing to do
      }
    },
  });
export default i18n;
