interface SensorVM {
  id: string;
  name: string; //50
  deviceSerialNumber: string;
  description: string; //max length 150
  sensorType: SensorType;
  sensorMac: string;
  shortAddress: string;
  sensorState: SensorState;
  batteryPercent: number;
  signalIntensity: number; //number from 0 -255
  isConnected: boolean;
  // isDeviceContact: boolean;
  // isDeviceExitButton: boolean;
  // isDeviceRelay: boolean;
}
export interface SensorModel extends SensorVM {
  //
}
export enum SensorType {
  Contact = 0,
  Motion = 1,
  Relay = 2,
  PushButton = 3,
  LightSwitch = 4,
  Alarm = 5,
}

export enum SensorState {
  Off = 0,
  On = 1,
}
interface SensorLogVM {
  id: string;
  eventTime: string; //DateTime
  accountId: number;
  deviceSerialNumber: string;
  timeZoneOffset: string;
  sensorMAC: string;
  shortAddress: string;
  sensorState: SensorState;
  sensorType: SensorType;
  sensorLogArtifacts?: SensorLogArtifactModel[];
  hasImage: boolean;
  hasVideo: boolean;
}
export interface SensorLogModel extends SensorLogVM {
  //
}

interface SensorLogArtifactVM {
  id: string;
  isImage: boolean;
  deviceSerialNumber: string;
  deviceName: string;
}
export interface SensorLogArtifactModel extends SensorLogArtifactVM {
  //
}
export enum RelayCommandType {
  On = 0,
  Off = 1,
  Toggle = 2,
}
