import { ReCaptchaInstance, IRenderParameters } from "./instance";

enum ELoadingState {
  NOT_LOADED,
  LOADING,
  LOADED,
}

/**
 * This is a loader which takes care about loading the
 * official recaptcha script (https://www.google.com/recaptcha/api.js).
 *
 * The main method {@link ReCaptchaLoader#load(siteKey: string)} also
 * prevents loading the recaptcha script multiple times.
 */
class ReCaptchaLoader {
  private static loadingState: ELoadingState;
  private static instance: ReCaptchaInstance;
  private static instanceSiteKey: string;

  private static successfulLoadingConsumers: Array<(instance: ReCaptchaInstance) => void> = [];
  private static errorLoadingRunnable: Array<(reason: Error) => void> = [];

  private static readonly SCRIPT_LOAD_DELAY = 25;

  /**
   * Loads the recaptcha library with the given site key.
   *
   * @param siteKey The site key to load the library with.
   * @param options The options for the loader
   * @return The recaptcha wrapper.
   */
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  public static load(siteKey: string): Promise<ReCaptchaInstance> {
    // Check if grecaptcha is already registered.
    if (ReCaptchaLoader.getLoadingState() === ELoadingState.LOADED) {
      if (ReCaptchaLoader.instance.getSiteKey() === siteKey) {
        return Promise.resolve(ReCaptchaLoader.instance);
      } else {
        return Promise.reject(new Error("reCAPTCHA already loaded with different site key!"));
      }
    }

    // Set states
    ReCaptchaLoader.instanceSiteKey = siteKey;
    ReCaptchaLoader.setLoadingState(ELoadingState.LOADING);

    // Throw error if the recaptcha is already loaded
    const loader = new ReCaptchaLoader();

    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      loader
        .loadScript()
        .then(() => {
          ReCaptchaLoader.setLoadingState(ELoadingState.LOADED);

          const augmentedParameters: IRenderParameters = {
            sitekey: siteKey,
          };

          const widgetID = grecaptcha.render(augmentedParameters);

          const instance = new ReCaptchaInstance(siteKey, widgetID, grecaptcha);
          ReCaptchaLoader.successfulLoadingConsumers.forEach((v) => v(instance));
          ReCaptchaLoader.successfulLoadingConsumers = [];

          ReCaptchaLoader.instance = instance;
          resolve(instance);
        })
        .catch((error) => {
          ReCaptchaLoader.errorLoadingRunnable.forEach((v) => v(error));
          ReCaptchaLoader.errorLoadingRunnable = [];
          reject(error);
        });
    });
  }

  private static setLoadingState(state: ELoadingState): void {
    ReCaptchaLoader.loadingState = state;
  }

  private static getLoadingState(): ELoadingState {
    if (ReCaptchaLoader.loadingState === null) {
      return ELoadingState.NOT_LOADED;
    } else {
      return ReCaptchaLoader.loadingState;
    }
  }

  /**
   * This method will create a new script element
   * and append it to the "<head>" element.
   */
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  private loadScript(): Promise<HTMLScriptElement> {
    const scriptElement: HTMLScriptElement = document.createElement("script");
    scriptElement.setAttribute("recaptcha-v3-script", "");
    scriptElement.src = "https://www.google.com/recaptcha/api.js?render=explicit";

    return new Promise<HTMLScriptElement>((resolve, reject) => {
      scriptElement.addEventListener(
        "load",
        this.waitForScriptToLoad(() => {
          resolve(scriptElement);
        }),
        false
      );

      scriptElement.onerror = (error): void => {
        ReCaptchaLoader.setLoadingState(ELoadingState.NOT_LOADED);
        reject(error);
      };

      document.head.appendChild(scriptElement);
    });
  }

  private waitForScriptToLoad(callback: () => void) {
    return (): void => {
      if (window.grecaptcha === undefined) {
        setTimeout(() => {
          this.waitForScriptToLoad(callback);
        }, ReCaptchaLoader.SCRIPT_LOAD_DELAY);
      } else {
        window.grecaptcha.ready(() => {
          callback();
        });
      }
    };
  }
}

export const load = ReCaptchaLoader.load;
