import React from "react";
import { Redirect, Route } from "react-router-dom";
import { PathStateObject } from "../Utils/Models/routesModel";
import { pathRoute } from "../Utils/Routes/routes";
import { hasSomeAuthorization, isActionsAllowed } from "./actionsCheck";
interface Props {
  children: any;
  autorization: string[];
  path: string;
  exact: boolean;
  isAuthenticated: boolean;
  isVerifying: boolean;
  scopes: string[];
}
export default function PrivateRoute({
  path,
  exact,
  autorization,
  children,
  isAuthenticated,
  isVerifying,
  scopes,
}: Props) {
  let authorized = false;
  /**
   * this is to avoid showing a page if the user doesn't have some of the authorization to see it. This authorization is set in the route model
   */
  if (isAuthenticated && hasSomeAuthorization(autorization, scopes)) {
    authorized = true;
  }
  /////
  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        isVerifying ? null : isAuthenticated ? (
          authorized ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: pathRoute.Dashboard,
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: pathRoute.SignIn.Index,
              state: { from: location } as PathStateObject,
            }}
          />
        )
      }
    />
  );
}
