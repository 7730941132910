import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: 0,
      padding: 0,
      paddingTop: theme.spacing(2),

      paddingLeft: theme.spacing(2),
    },
    actions: { marginBottom: theme.spacing(2) },
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  onClose?: () => void;
  open: boolean;
  children: any;
  title?: string;
  fullWidth?: boolean;
  maxWidth?: any;
  removePaddingContent?: boolean;
  subTitle?: string;
  disableBackdropClick?: boolean;
  rest?: any;
}

function DialogWrapper({
  open,
  onClose,
  title,
  children,
  fullWidth,
  maxWidth,
  removePaddingContent,
  subTitle,
  disableBackdropClick,
  ...rest
}: Props) {
  const classes = useStyles();
  const handleClose = (event: any, reason: any) => {
    if (reason && reason == "backdropClick" && disableBackdropClick) {
      return;
    }
    onClose && onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.root}
      aria-labelledby="form-dialog-title"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...rest}
    >
      <DialogTitle className={classes.title} disableTypography id="form-dialog-title">
        <Typography variant="h6">{title}</Typography>
        {subTitle && <Typography variant="caption">{subTitle}</Typography>}
      </DialogTitle>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
      {removePaddingContent ? (
        <Grid container className={classes.actions}>
          {children}
        </Grid>
      ) : (
        <DialogContent className={classes.actions}>{children}</DialogContent>
      )}
    </Dialog>
  );
}

export default DialogWrapper;
