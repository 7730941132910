import { MuiDrawer } from "./MuiDrawer";
import { MuiTypography } from "./MuiTypography";
import { Stepper } from "./MuiStepConnector";
import { MuiButton } from "./MuiButton";
import { MuiTab } from "./MuiTab";
import { MuiTable } from "./MuiTable";
import { MuiIcon } from "./MuiIcon";

const overrides = {
  ...MuiDrawer,
  ...MuiTypography,
  ...Stepper,
  ...MuiButton,
  ...MuiTab,
  ...MuiTable,
  ...MuiIcon,
};
export default overrides;
