import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";
//https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=00c6e2&secondary.color=f9ae07&primary.text.color=000000

export const primaryColor = "#04AED6";
export const secondaryColor = "#1A2033";

export const warningColor = "#ECC34E";
export const successColor = "#76E5B9";

export const errorColor = "#E15142";

export const tableHeaderColor = "#F4F6F9";

export const backgroundDefaultColor = "#fafafa";

export const backgroundSignInSignUpColor = "#F3F5FA";

export const iconColor = "#8C92A1";
export const switchBaseUnCheckColor = colors.grey[300];
export const lightPalette = {
  type: "light",
  // "common": {
  //   "black": "#000",
  //   "white": "#fff"
  // },
  background: {
    // "paper": "#fff",
    default: backgroundDefaultColor,
  },
  primary: {
    main: primaryColor,
    contrastText: "#fff",
  },
  secondary: {
    main: secondaryColor,
    dark: "#0F1320",
    contrastText: "#fff",
  },
  error: {
    main: errorColor,
  },
  warning: {
    main: warningColor,
  },
  success: {
    main: successColor,
  },
  text: {
    primary: "#1A2033",
    secondary: "#676F86",
    // disabled: "#ECF0F4",
    // hint: "#ECF0F4"
  },
};
export const darkPalette = {
  type: "dark",
  primary: {
    // "light": "rgba(102, 249, 255, 1)",
    main: "rgba(0, 198, 226, 1)",
    // "dark": "rgba(0, 149, 176, 1)",
    // "contrastText": "rgba(0, 0, 0, 1)"
  },
  secondary: {
    // "light": "rgba(255, 224, 77, 1)",
    main: "rgba(249, 174, 8, 1)",
    // "dark": "rgba(193, 127, 0, 1)",
    // "contrastText": "rgba(0, 0, 0, 1)"
  },
  error: {
    // "light": "#e57373",
    main: "#f44336",
    // "dark": "#d32f2f",
    // "contrastText": "#fff"
  },
};
