import React from "react";
import PagesRouteLogic from "./components/PagesRouteLogic";
import {
  BrowserRouter as Router,
  // Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import initStore from "./store";
import { PersistGate } from "redux-persist/integration/react";
import GeneralLoading from "./components/Utils/Custom/CustomLoading/GeneralLoading";
import "./translation/i18n";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./assets/css/generalStyles.css";
///////////////////////////////
export default function App() {
  return (
    <Provider store={initStore().store}>
      <PersistGate loading={<GeneralLoading />} persistor={initStore().persistor}>
        <Router>
          <PagesRouteLogic />
        </Router>
      </PersistGate>
    </Provider>
  );
}
