import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../components/Utils/Custom/HelperCode";
import { ReducerNotificationModel } from "../../components/Utils/Models/store";
import { v4 as uuid } from "uuid";
import { NotificationModel } from "../../components/Utils/Models/notifications";

function create_UUID() {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

const initialState: ReducerNotificationModel = {
  tokenPushNotification: "",
  isNotificationEnabled: false,
  listenerPushNotification: null,
  pushNotifications: [],

  isTokenPushNotificationRequesting: false,
  isTokenPushNotificationSuccess: false,
  pushNotificationErrorCode: "",

  totalFaceEnrollmentSubscriptions: [],
  totalNetworkSettingsSubscriptions: [],
  totalCardEnrollmentSubscriptions: [],
  totalDoorRingSerials: [],
};

//////////////Reducer Slice//////////////////////
const pushNotificationRequest_Reducer = (state: ReducerNotificationModel, action: any) => {
  return updateObject(state, {
    isTokenPushNotificationRequesting: true,
    isTokenPushNotificationSuccess: false,
    pushNotificationErrorCode: "",
  } as ReducerNotificationModel);
};

const pushNotificationSuccess_Reducer = (state: ReducerNotificationModel, action: any) => {
  return updateObject(state, {
    isTokenPushNotificationRequesting: false,
    isTokenPushNotificationSuccess: true,
    tokenPushNotification: action.pushNotificationToken,
  } as ReducerNotificationModel);
};

const pushNotificationFailure_Reducer = (state: ReducerNotificationModel, action: any) => {
  return updateObject(state, {
    isTokenPushNotificationRequesting: false,
    isTokenPushNotificationSuccess: false,
    pushNotificationErrorCode: action.errorCode,
  } as ReducerNotificationModel);
};

const pushNotificationMessage_Reducer = (state: ReducerNotificationModel, action: any) => {
  const notifications = [...state.pushNotifications];
  notifications.push({
    Code: action.message.data.Code,
    Data: action.message.data.Data,
    isNoRead: true,
    id: uuid(),
  });
  return updateObject(state, {
    pushNotifications: notifications,
    listenerPushNotification: action.listener,
  } as ReducerNotificationModel);
};

const pushNotificationDeletedToken_Reducer = (state: ReducerNotificationModel, action: any) => {
  return updateObject(state, {
    tokenPushNotification: "",
    listenerPushNotification: null,
  } as ReducerNotificationModel);
};

const pushNotificationUnsubscribe_Reducer = (state: ReducerNotificationModel, action: any) => {
  state.listenerPushNotification();
  return updateObject(state, {
    tokenPushNotification: "",
    listenerPushNotification: null,
  } as ReducerNotificationModel);
};

const pushNotificationReadAll_Reducer = (state: ReducerNotificationModel, action: any) => {
  const notifications = state.pushNotifications.map((notif) => ({
    ...notif,
    isNoRead: false,
  }));
  return updateObject(state, {
    pushNotifications: notifications,
  } as ReducerNotificationModel);
};

const pushNotificationRemoveAll_Reducer = (state: ReducerNotificationModel, action: any) => {
  const notifications: NotificationModel[] = [];
  return updateObject(state, {
    pushNotifications: notifications,
  } as ReducerNotificationModel);
};

const addFaceEnrollment_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total = [...state.totalFaceEnrollmentSubscriptions];
  total.push(action.userId);
  return updateObject(state, {
    totalFaceEnrollmentSubscriptions: total,
  } as ReducerNotificationModel);
};

const deleteUserIdFaceEnrollment_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total: string[] = [...state.totalFaceEnrollmentSubscriptions];
  if (total.findIndex((value) => value === action.userId) > -1) {
    total.splice(
      total.findIndex((value) => value === action.userId),
      1
    );
  }
  return updateObject(state, {
    totalFaceEnrollmentSubscriptions: total,
  } as ReducerNotificationModel);
};

const addNetworkSettings_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total = [...state.totalNetworkSettingsSubscriptions];
  if (!total.find((value) => value === action.serialNumber)) {
    total.push(action.serialNumber);
  }
  return updateObject(state, {
    totalNetworkSettingsSubscriptions: total,
  } as ReducerNotificationModel);
};

const deleteSerialNumberNetworkSettings_Reducer = (
  state: ReducerNotificationModel,
  action: any
) => {
  const total: string[] = [...state.totalNetworkSettingsSubscriptions];
  if (total.findIndex((value) => value === action.serialNumber) > -1) {
    total.splice(
      total.findIndex((value) => value === action.serialNumber),
      1
    );
  }
  return updateObject(state, {
    totalNetworkSettingsSubscriptions: total,
  } as ReducerNotificationModel);
};

const addCardEnrollment_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total = [...state.totalCardEnrollmentSubscriptions];
  total.push(action.userId);
  return updateObject(state, {
    totalCardEnrollmentSubscriptions: total,
  } as ReducerNotificationModel);
};

const deleteUserIdCardEnrollment_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total: string[] = [...state.totalCardEnrollmentSubscriptions];
  if (total.findIndex((value) => value === action.userId) > -1) {
    total.splice(
      total.findIndex((value) => value === action.userId),
      1
    );
  }
  return updateObject(state, {
    totalCardEnrollmentSubscriptions: total,
  } as ReducerNotificationModel);
};

const addDoorRingSerial_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total = [...state.totalDoorRingSerials];
  if (total.findIndex((value) => value === action.serialNumber) === -1) {
    total.push(action.serialNumber);
  }
  return updateObject(state, {
    totalDoorRingSerials: total,
  } as ReducerNotificationModel);
};

const deleteDoorRingSerial_Reducer = (state: ReducerNotificationModel, action: any) => {
  const total: string[] = [...state.totalDoorRingSerials];
  if (total.findIndex((value) => value === action.serialNumber) > -1) {
    total.splice(
      total.findIndex((value) => value === action.serialNumber),
      1
    );
  }
  return updateObject(state, {
    totalDoorRingSerials: total,
  } as ReducerNotificationModel);
};
/////REDUCER//////
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.PUSH_NOTIFICATION_PERMISSION_REQUEST:
      return pushNotificationRequest_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_PERMISSION_SUCCESS:
      return pushNotificationSuccess_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_PERMISSION_FAILURE:
      return pushNotificationFailure_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_MESSAGE:
      return pushNotificationMessage_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_DELETED_TOKEN:
      return pushNotificationDeletedToken_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_UNSUBSCRIBE:
      return pushNotificationUnsubscribe_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_READ_ALL:
      return pushNotificationReadAll_Reducer(state, action);
    case actionTypes.PUSH_NOTIFICATION_REMOVE_ALL:
      return pushNotificationRemoveAll_Reducer(state, action);

    case actionTypes.FIRESTORE_FACEENROLLMENT_ADD:
      return addFaceEnrollment_Reducer(state, action);
    case actionTypes.FIRESTORE_FACEENROLLMENT_DELETE:
      return deleteUserIdFaceEnrollment_Reducer(state, action);

    case actionTypes.FIRESTORE_NETWORKSETTINGS_ADD:
      return addNetworkSettings_Reducer(state, action);
    case actionTypes.FIRESTORE_NETWORKSETTINGS_DELETE:
      return deleteSerialNumberNetworkSettings_Reducer(state, action);

    case actionTypes.FIRESTORE_CARDENROLLMENT_ADD:
      return addCardEnrollment_Reducer(state, action);
    case actionTypes.FIRESTORE_CARDENROLLMENT_DELETE:
      return deleteUserIdCardEnrollment_Reducer(state, action);

    case actionTypes.FIRESTORE_DOORRING_SAVE:
      return addDoorRingSerial_Reducer(state, action);
    case actionTypes.FIRESTORE_DOORRING_DELETE:
      return deleteDoorRingSerial_Reducer(state, action);

    default:
      return state;
  }
};
export default reducer;
