import React from "react";

import { SvgIcon } from "@material-ui/core";
export default function HomeIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M9,13H15V19H18V10L12,5.5L6,10V19H9V13M4,21V9L12,3L20,9V21H4Z" />
    </SvgIcon>
  );
}
