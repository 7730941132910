export interface CountryVM {
  name: string;
  isO2: string;
  phoneCode: string;
  currency: string;
}
export interface StateVM {
  id: number;
  countryIso2: string;
  name: string;
  code: string;
}

export interface DevicesDirty {
  label: string;
  value: string;
}

export interface AddressModel {
  line1: string;
  line2?: string;
  countryISO2: string;
  city: string;
  postalCode: string;
  stateId?: number;
}
interface ShippingAddressVM {
  address: AddressModel;
  shippingAndCompanyAddressAreEquals: boolean;
}
export interface ShippingAddressModel extends ShippingAddressVM {}

class Logger {
  logger?: Console;
  logPrefix = "[alocity-web]";
  constructor(logger?: Console) {
    this.logger = logger;
  }
  private _log = (message: unknown, prefix?: string, prefixStyle?: string): void => {
    this.logger?.log(
      `%c${this.logPrefix} ${prefixStyle ? "%c" : ""}${prefix || ""}`,
      "color: gray;",
      prefixStyle,
      message
    );
  };
  log = (message: unknown, prefix?: string, prefixStyle?: string): void => {
    this._log(message, prefix, prefixStyle);
  };
  logMaster = (message: unknown): void => {
    this.log(message, `MASTER:`, "color: royalblue; font-weight:bold;");
  };
  logViewer = (message: unknown): void => {
    this.log(message, `VIEWER:`, "color: blue; font-weight: bold;");
  };
  logError = (message: unknown): void => {
    this.log(message, `ERROR:`, "color: red; font-weight: bold;");
  };
}

export const getLogger = ({ debug = false }: { debug?: boolean } = {}): Logger =>
  process.env.NODE_ENV != "production" ? new Logger(console) : new Logger();

export enum EMicPermissionType {
  Asked = -1,
  Denied = 0,
  Granted = 1,
}

export enum EEventsType {
  AccessLog = "AccessLog",
}
