import React, { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { CircularProgress } from "@material-ui/core";
import ConfirmationEmailVerified from "./components/ConfirmationEmailVerified";
import ManageResetPassword from "./components/ManageResetPassword";

import Page404 from "../../Utils/Custom/Page404";

import CloudKeyOpenDoor from "./CloudKeyOpenDoor";
import ProvisioningFaceInvitation from "./ProvisioningFaceInvitation";
import RedirectFromCheckout from "./RedirectFromCheckout";
import ShowPublicEventVideo from "./ShowPublicEventVideo";
import ReadCatalogNFCTag from "./ReadCatalogNFCTag";
import ShowPublicPlaylistVideo from "./ShowPublicPlaylistVideo";
import * as actions from "../../../store/actions";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(15),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const TempLayout = ({ children }: { children: any }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>{children}</div>
    </Container>
  );
};

interface Props {
  deviceType: string;
  innerWidth: number;
  innerHeight: number;
}

export default function RedirectEmailActions({ deviceType, innerWidth, innerHeight }: Props) {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState<string>("");
  const [oobCode, setOobCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [deviceName, setDeviceName] = useState<string>("");
  const [time, setTime] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<string | null>("");
  const [connected, setConnected] = useState<boolean>(false);
  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const [timeZoneOffset, setTimeZoneOffset] = useState<string>("");
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    setLoading(true);
    const url = new URL(window.location.href);

    const mode = url.searchParams.get("mode") as string;

    const oobCode = url.searchParams.get("oobCode") as string;

    const email = url.searchParams.get("email") as string;

    const tempUrl = url.searchParams.get("url") as string;
    const tempDeviceName = url.searchParams.get("deviceName") as string;
    const tempTime = url.searchParams.get("time") as string;
    let serial = url.searchParams.get("serialNumber");
    const isConnected = url.searchParams.get("connected");
    const fromDate = url.searchParams.get("from") as string;
    const toDate = url.searchParams.get("to") as string;
    const timeZone = url.searchParams.get("timezone") as string;

    if (mode === "catalog") {
      serial = url.searchParams.get("sn");
    }

    // https://dev-portal.alocity.com/action?mode=catalog&sn=3C62F0042336-2109DLT000023&connected=true

    setMode(mode);
    setOobCode(oobCode);
    setEmail(email);
    setLoading(false);
    setUrl(tempUrl);
    setDeviceName(tempDeviceName);
    setTime(tempTime);
    setSerialNumber(serial);
    setConnected(isConnected === "true" ? true : false);
    setFrom(fromDate);
    setTo(toDate);
    setTimeZoneOffset(timeZone);
  }, []);

  //I replicate this, because Layout component isnt loaded
  useEffect(() => {
    const updateWindowDimensions = () => {
      dispatch(actions.changesInWindowDimenssions(window.innerHeight, window.innerWidth));
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);
  /* Design depending of the mode */

  const design = () => {
    let section: any;
    switch (mode) {
      case "verifyEmail":
        section = (
          <TempLayout>
            <ConfirmationEmailVerified oobCode={oobCode} />
          </TempLayout>
        );
        break;
      case "resetPassword":
        section = (
          <TempLayout>
            <ManageResetPassword emailPasswordToReset={email} oobCode={oobCode} />
          </TempLayout>
        );
        break;
      case "cloudkey":
        section = <CloudKeyOpenDoor oobCode={oobCode} deviceType={deviceType} />;
        break;
      case "provisioninginvitation":
        section = <ProvisioningFaceInvitation deviceType={deviceType} oobCode={oobCode} />;
        break;
      case "checkout":
        section = <RedirectFromCheckout oobCode={oobCode} />;
        break;
      case "eventVideo":
        section = <ShowPublicEventVideo deviceName={deviceName} url={url} time={time} />;
        break;
      case "catalog":
        section = <ReadCatalogNFCTag serialNumber={serialNumber} isConnected={connected} />;
        break;
      case "cloudVideo":
        section = (
          <ShowPublicPlaylistVideo
            deviceName={deviceName}
            url={url}
            from={from}
            to={to}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            timeZoneOffset={timeZoneOffset}
          />
        );
        break;
      default:
        section = <Page404 />;
        break;
    }

    return (
      <Container
        component="main"
        maxWidth={mode === "eventVideo" || mode === "cloudVideo" ? "lg" : "xs"}
        fixed={mode === "eventVideo" || mode === "cloudVideo" ? true : undefined}
      >
        {loading ? <CircularProgress variant="indeterminate" /> : section}
      </Container>
    );
  };

  return design();
}
