import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import { CardContent, CardMedia, Typography } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions/CardActions";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { pathRoute } from "../../../Utils/Routes/routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 345,
    },
    media: {
      // height: 140,
    },
    title: {
      display: "flex",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
interface Props {
  accountName?: string;
}
export default function ConfirmationAccountCreated({ accountName }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace(pathRoute.SignIn.Index);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
          {t("AccountWasCreated")}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
          {t("YouWillReceiveAnEmail")}
        </Typography>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}
