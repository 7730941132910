import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { emailValidation } from "../../Utils/Custom/validations";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { sendResetPasswordAPI } from "../../Utils/API/apiAuth";
import { CardContent, LinearProgress } from "@material-ui/core";

import { pathRoute } from "../../Utils/Routes/routes";
import ConfirmationSuccessSentResetPassword from "./components/ConfirmationSuccessSentResetPassword";
import Copyright from "../../Utils/Custom/Copyright";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(15),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  title: {
    margin: theme.spacing(0, 0, 1),
    display: "flex",
    justifyContent: "center",
  },
  subTitle: {
    margin: theme.spacing(0, 0, 1),
  },
  logo: {
    objectFit: "contain",
    height: "100px",
    width: "200px",
  },
  logo_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);

  const resetPassword = async (values: any) => {
    setLoading(true);

    await sendResetPasswordAPI(values?.email as any)
      .then((response: any) => {
        if (!response.data.hasErrors) {
          setShowMessageSuccess(true);
        }
      })
      .catch(function (error: any) {
        //interceptor capture the error
      })
      .finally(() => {
        setLoading(false);
      });

    setLoading(false);
  };

  const validate = (values: { email: string }) => {
    const errors: any = {};
    if (emailValidation(values.email)) {
      errors.email = emailValidation(values.email);
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: async (values: any) => {
      await resetPassword(values);
    },
    onReset: (values: any) => {
      //
    },
  });
  let emptyForm = true;
  if (formik.values.email) {
    emptyForm = false;
  }
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {showMessageSuccess ? (
          <ConfirmationSuccessSentResetPassword />
        ) : (
          <>
            <Card>
              <CardContent>
                <div className={classes.logo_container}>
                  <img
                    src={"/images/alocity-logo.png"}
                    alt="Alocity logo"
                    className={classes.logo}
                  />
                </div>
                <Typography component="h1" variant="h5" color="primary" className={classes.title}>
                  {t("RecoveryPassword")}
                </Typography>
                <form
                  className={classes.form}
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}
                >
                  <Grid container justify="flex-start">
                    <Grid item className={classes.subTitle}>
                      <Typography component="h1" variant="body2">
                        {t("TroubleForgotPasswordText")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    id="email"
                    label={t("Email")}
                    // name="email"
                    autoComplete="email"
                    // autoFocus
                    {...formik.getFieldProps("email")}
                    disabled={formik.isSubmitting}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    helperText={
                      formik.touched.email && formik.errors.email ? t(formik.errors.email) : ""
                    }
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={formik.isSubmitting || emptyForm}
                  >
                    {t("Continue")}
                  </Button>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Link href={pathRoute.SignIn.Index} variant="body2">
                        {t("ReturnToSignIn")}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </>
        )}
      </div>
      {loading ? <LinearProgress /> : null}
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}
