import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, Container, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Copyright from "../../Utils/Custom/Copyright";
import { pathRoute } from "../../Utils/Routes/routes";
import ConfirmationAccountCreated from "./components/ConfirmationAccountCreated";
import AccountCreation from "./components/AccountCreation";
import WrapperSignInSignUp from "./components/WrapperSignInSignUp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    placeItems: "center",
    height: "100%",
    paddingTop: "1em",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyright: {
    marginTop: theme.spacing(2),
  },
  logo: {
    margin: theme.spacing(1),
    objectFit: "contain",
    height: "100px",
    width: "200px",
  },
  signUpPrompt: {
    marginRight: theme.spacing(0.5),
  },
  underline: {
    textDecoration: "underline",
  },
  fontWeight: {
    fontWeight: "normal",
  },
}));

function SignUp() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [openModalAccountCreated, setOpenModalAccountCreated] = useState(false);

  useEffect(() => {
    let lang: string = i18n.language;

    if (lang === "es") {
      lang = "es";
    } else if (lang.indexOf("-") > -1) {
      const values: string[] = lang.split("-");
      if (values[0] === "es") {
        lang = "es";
      } else {
        lang = "en";
      }
    } else {
      lang = "en";
    }

    i18n.changeLanguage(lang, () => {
      //
    });
  }, []);

  const onSuccess = () => setOpenModalAccountCreated(true);

  return (
    <WrapperSignInSignUp>
      <Container component="main" maxWidth="sm" className={classes.root}>
        <div>
          {openModalAccountCreated ? (
            <ConfirmationAccountCreated />
          ) : (
            <Card elevation={0}>
              <CardContent>
                <div className={classes.paper}>
                  <img
                    src={"/images/alocity-logo.png"}
                    alt="Alocity logo"
                    className={classes.logo}
                  />
                  <Typography component="h1" variant="h5">
                    {t("SignUp")}
                  </Typography>

                  <AccountCreation onSuccess={onSuccess} />

                  <Grid container justify="center">
                    <Grid item>
                      <span className={classes.signUpPrompt}>{t("HaveAnAccount")}</span>
                      <Link
                        variant="body2"
                        href={pathRoute.SignIn.Index}
                        className={classes.underline}
                      >
                        {t("SignIn")}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          )}
          <Box className={classes.copyright}>
            <Copyright />
          </Box>
        </div>
      </Container>
    </WrapperSignInSignUp>
  );
}

export default SignUp;
