import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints, QueryFilter, Response } from "../Models/api";
import { RoleModel } from "../Models/roles";

export const getRoles = (getAll?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/roles",
    method: axiosMethods.get,
    params: {
      getAll: getAll,
    },
  });
};

export const deleteARole = (id: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/role/" + id,
    method: axiosMethods.delete,
  });
};

export const updateARole = (role: RoleModel) => {
  const temp: RoleModel = { ...role };
  delete temp.scopes;
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/role",
    method: axiosMethods.put,
    data: temp,
  });
};

export const getScopes = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/scopes",
    method: axiosMethods.get,
  });
};

export const createARole = (role: RoleModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/role",
    method: axiosMethods.post,
    data: {
      displayName: role.displayName,
      description: role.description,
      isAdmin: false, //TODO: waiting for the definition of this
      scopesIds: role.scopes?.map((scope) => scope.id),
    },
  });
};

export const deleteScopesFromRole = (id: number, scopesIds: number[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/role/" + id + "/scopes",
    method: axiosMethods.delete,
    data: scopesIds,
  });
};

export const addScopesToRole = (id: number, scopesIds: number[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/role/" + id + "/scopes",
    method: axiosMethods.post,
    data: scopesIds,
  });
};

export const getAccountAddons = (token: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/addons",
    method: axiosMethods.get,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
