import React from "react";
import "./AlocityLoading.css";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  position?: string;
}

const AlocityLoading = ({ position }: Props) => {
  const theme = useTheme();
  return (
    <div className={position === "absolute" ? "center" : ""}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 442 315"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="alocity-icon-loading" clipPath="url(#clip0_101_2)">
          <g id="original">
            <path
              id="Vector_1"
              d="M105.31 92.4845L166.185 159.784L204.879 117.038C209.142 112.376 214.898 109.761 220.897 109.761C226.895 109.761 232.651 112.376 236.914 117.038L275.608 159.784L336.528 92.4845L276.103 25.2348C245.688 -8.4149 196.645 -8.4149 166.725 25.2348L105.31 92.4845Z"
              fill="#F9AE08"
            />
            <path
              id="Vector_2"
              d="M9.79043 247.015L47.9893 289.213L166.185 159.784L105.31 92.4845L10.3303 194.577C-3.0775 208.792 -3.61742 232.75 9.79043 247.015Z"
              fill="#1A2033"
            />
            <path
              id="Vector_3"
              d="M220.897 220.224L166.185 159.784L47.9893 289.76C78.4494 323.41 127.492 323.41 157.412 289.76L220.897 220.224Z"
              fill="#00C6E2"
            />
            <path
              id="Vector_4"
              d="M432.003 247.015L393.804 289.213L276.103 159.784L336.528 93.0809L431.463 194.577C437.824 201.035 441.603 210.019 441.968 219.555C442.333 229.09 439.255 238.395 433.411 245.424C432.958 245.971 432.489 246.501 432.003 247.015Z"
              fill="#1A2033"
            />
            <path
              id="Vector_5"
              d="M220.897 220.224L276.103 159.784L393.804 289.76C363.344 323.41 314.302 323.41 284.381 289.76L220.897 220.224Z"
              fill="#00C6E2"
            />
          </g>
          <g id="step1">
            <path
              id="Vector_11"
              d="M105.31 92.4845L166.185 159.784L204.879 117.038C209.142 112.376 214.898 109.761 220.897 109.761C226.895 109.761 232.651 112.376 236.914 117.038L275.608 159.784L336.528 92.4845L276.103 25.2348C245.688 -8.4149 196.645 -8.4149 166.725 25.2348L105.31 92.4845Z"
              fill="#F9AE08"
            />
          </g>
          <g id="step2">
            <path
              id="Vector_21"
              d="M432.003 247.015L393.804 289.213L276.103 159.784L336.528 93.0809L431.463 194.577C437.824 201.035 441.603 210.019 441.968 219.555C442.333 229.09 439.255 238.395 433.411 245.424C432.958 245.971 432.489 246.501 432.003 247.015Z"
              fill="#1A2033"
            />
            <path
              id="Vector_22"
              d="M220.897 220.224L276.103 159.784L393.804 289.76C363.344 323.41 314.302 323.41 284.381 289.76L220.897 220.224Z"
              fill="#00C6E2"
            />
          </g>
          <g id="step3">
            <path
              id="Vector_31"
              d="M9.79043 247.015L47.9893 289.213L166.185 159.784L105.31 92.4845L10.3303 194.577C-3.0775 208.792 -3.61742 232.75 9.79043 247.015Z"
              fill="#1A2033"
            />
            <path
              id="Vector_32"
              d="M220.897 220.224L166.185 159.784L47.9893 289.76C78.4494 323.41 127.492 323.41 157.412 289.76L220.897 220.224Z"
              fill="#00C6E2"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_101_2">
            <rect width="442" height="315" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
export default AlocityLoading;
