const PRIVACY_MAIN = "https://www.alocity.com/pages/main-services-agreement";
const PRIVACY_BIO = "https://www.alocity.com/pages/biometric-privacy-consent";
const TimeToCloseWebRTCConnection = 60000;
const delimiterBetweenFirstAndLastName = "44BBC99CBB44";
const delimiterOfTheGeneratedUuid = "2a88mo88a2";
const Data = {
  PRIVACY_MAIN,
  PRIVACY_BIO,
  TimeToCloseWebRTCConnection,
  delimiterBetweenFirstAndLastName,
  delimiterOfTheGeneratedUuid,
};
export default Data;
