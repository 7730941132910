import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory, useLocation } from "react-router-dom";
import { emailValidation, passwordValidation } from "../../Utils/Custom/validations";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { LinearProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DescisionMessage from "../../Utils/Custom/DecisionMessage";
import { pathRoute } from "../../Utils/Routes/routes";
import Copyright from "../../Utils/Custom/Copyright";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WrapperSignInSignUp from "./components/WrapperSignInSignUp";
import { PathStateObject } from "../../Utils/Models/routesModel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    placeItems: "center",
    height: "100vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
  },
  // card: {
  //   margin: "40px",
  //   borderRadius: "4px",
  //   boxShadow: "0px 0px 10px #E7E9F3",
  // },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  box: {
    marginTop: theme.spacing(2),
  },
  logo: {
    margin: theme.spacing(1),
    objectFit: "contain",
    height: "100px",
    width: "200px",
  },
  signUpPrompt: {
    // color: "#04AED6",
    marginRight: theme.spacing(0.3),
  },
  underline: {
    textDecoration: "underline",
  },
  fontWeight: {
    fontWeight: "normal",
  },
}));
interface Props {
  signInUser: (email: string, password: string) => void;
  isSigningIn: boolean;
  signInErrorCode: string;
  signOutErrorCode: string;
  isAuthenticated: boolean;
  isEmailVerified: boolean;
  signOutUser: () => void;
  sendVerificationEmail: () => void;
  isSendVerificationEmailSuccess: boolean;
  sendVerificationEmailErrorCode: string;
  isSendVerificationEmailRequesting: boolean;
}

const SignIn = ({
  signInUser,
  isSigningIn,
  signInErrorCode,
  signOutErrorCode,
  isAuthenticated,
  isEmailVerified,
  signOutUser,
  sendVerificationEmail,
  isSendVerificationEmailSuccess,
  sendVerificationEmailErrorCode,
  isSendVerificationEmailRequesting,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const loading = isSigningIn || isSendVerificationEmailRequesting;

  const [openModalEmailNotVerified, setOpenModalEmailNotVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const location: any = useLocation();

  const pathStateObject: PathStateObject =
    location.state ||
    ({
      from: pathRoute.Dashboard,
      message: "",
    } as PathStateObject);

  useEffect(() => {
    if (pathStateObject.message === "InvitedUserCreated") {
      toast.success(t("Saved"));
    }
  }, []);

  const signInWithEmailPassword = async (values: any) => {
    signInUser(values?.email as any, values?.password as any);
  };

  useEffect(() => {
    if (isAuthenticated) {
      // history.replace(pathStateObject.from) // TODO: delete this, this is temporally for testing
      if (isEmailVerified) {
        history.replace(pathStateObject.from);
      } else {
        setOpenModalEmailNotVerified(true);
      }
    }
  }, [isEmailVerified, isAuthenticated]);

  useEffect(() => {
    if (signInErrorCode && signInErrorCode.length > 0) {
      //https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
      switch (signInErrorCode) {
        case "auth/invalid-email":
          toast.error(t("Error") + ": " + t("auth_invalid_email"));
          break;
        case "auth/wrong-password":
          toast.error(t("Error") + ": " + t("auth_wrong_password"));
          break;
        case "auth/user-not-found":
          toast.error(t("Error") + ": " + t("auth_user_not_found"));
          break;
        case "auth/user-disabled":
          toast.error(t("Error") + ": " + t("auth_user_disabled"));
          break;
        case "auth/weak-password":
          toast.error(t("Error") + ": " + t("auth_weak_password"));
          break;
        default:
          toast.error(t("Error") + ": " + t("auth_wrong_password"));
          break;
      }
      formik.resetForm();
    }
  }, [signInErrorCode]);

  useEffect(() => {
    if (signOutErrorCode && signOutErrorCode.length > 0) {
      //https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signout
      toast.error(t("Error"));
    }
  }, [signOutErrorCode]);

  useEffect(() => {
    if (isSendVerificationEmailSuccess) {
      toast.success(t("EmailSent"));
      formik.resetForm();
    }
  }, [isSendVerificationEmailSuccess]);

  useEffect(() => {
    if (sendVerificationEmailErrorCode && sendVerificationEmailErrorCode.length > 0) {
      formik.resetForm();
    }
  }, [sendVerificationEmailErrorCode]);

  const validate = (values: { email: string; password: string }) => {
    const errors: any = {};
    if (emailValidation(values.email)) {
      errors.email = emailValidation(values.email);
    }
    if (passwordValidation(values.password)) {
      errors.password = passwordValidation(values.password);
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: async (values: any) => {
      await signInWithEmailPassword(values);
    },
    onReset: (values: any) => {
      setOpenModalEmailNotVerified(false);
    },
  });

  let emptyForm = true;
  if (formik.values.email && formik.values.password) {
    emptyForm = false;
  }

  return (
    <WrapperSignInSignUp>
      <Container component="main" maxWidth="xs" className={classes.root}>
        <div>
          <Card elevation={0}>
            <CardContent>
              <div className={classes.paper}>
                <img src={"/images/alocity-logo.png"} alt="Alocity logo" className={classes.logo} />

                <Typography component="h1" variant="h5">
                  {t("SignInToYourAccount")}
                </Typography>

                <form
                  className={classes.form}
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}
                >
                  <TextField
                    autoComplete="email"
                    size={"small"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label={t("Email")}
                    {...formik.getFieldProps("email")}
                    disabled={formik.isSubmitting}
                    error={formik.touched.email && formik.errors.email ? true : false}
                    helperText={
                      formik.touched.email && formik.errors.email ? t(formik.errors.email) : ""
                    }
                  />
                  <TextField
                    autoComplete="current-password"
                    size={"small"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={t("Password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    {...formik.getFieldProps("password")}
                    disabled={formik.isSubmitting}
                    error={formik.touched.password && formik.errors.password ? true : false}
                    helperText={
                      formik.touched.password && formik.errors.password
                        ? t(formik.errors.password)
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                              event.preventDefault()
                            }
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    style={{ opacity: formik.isSubmitting || emptyForm ? 0.4 : 1 }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={`${classes.submit} ${classes.fontWeight}`}
                    disableElevation

                    // disabled={formik.isSubmitting || emptyForm}
                  >
                    {t("SignIn")}
                  </Button>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Link
                        href={pathRoute.SignIn.ForgotPassword}
                        variant="body2"
                        className={`${classes.underline} ${classes.fontWeight}`}
                      >
                        {t("ForgotPassword")}
                      </Link>
                    </Grid>
                    {/* <Grid item>
                      <span className={`${classes.signUpPrompt} ${classes.fontWeight} `}>
                        {t("DontHaveAnAccount")}
                      </span>
                      <Link
                        href={pathRoute.SignUp.Index}
                        variant="body2"
                        className={`${classes.underline} ${classes.fontWeight}`}
                      >
                        {t("SignUp")}
                      </Link>
                    </Grid> */}
                  </Grid>
                </form>
              </div>
              {loading ? <LinearProgress /> : null}
            </CardContent>
          </Card>
          <Box className={classes.box}>
            <Copyright />
          </Box>
        </div>

        <Dialog
          open={openModalEmailNotVerified}
          onClose={() => {
            formik.resetForm();
            signOutUser(); //TODO: uncomment this
          }}
          aria-labelledby="modal email not verified"
          aria-describedby="modal email not verified"
          fullWidth={false}
          scroll={"paper"}
          transitionDuration={{
            appear: 100,
            enter: 500,
            exit: 200,
          }}
        >
          <DescisionMessage
            labelOkButton={t("Yes")}
            labelCancelButton={t("No")}
            title={t("EmailNotVerified")}
            contentText={t("DoYouWantToResendEmail")}
            onOK={
              () => sendVerificationEmail() //TODO: uncomment this
              // alert("No yet: Resend Email Verification")
            }
            onCancel={() => {
              formik.resetForm();
              signOutUser(); //TODO: uncomment this
            }}
          />
        </Dialog>
      </Container>
    </WrapperSignInSignUp>
  );
};
const mapStateToProps = (state: any /*, ownProps*/) => {
  return {
    isSigningIn: state.auth.isSigningIn,
    signInErrorCode: state.auth.signInErrorCode,
    signOutErrorCode: state.auth.signOutErrorCode,
    isAuthenticated: state.auth.isAuthenticated,
    isEmailVerified: state.auth.isEmailVerified,
    isSendVerificationEmailSuccess: state.auth.isSendVerificationEmailSuccess,
    sendVerificationEmailErrorCode: state.auth.sendVerificationEmailErrorCode,
    isSendVerificationEmailRequesting: state.auth.isSendVerificationEmailRequesting,
  };
};
function mapDispatchToProps(dispatch: any) {
  return {
    signInUser: (email: string, password: string) => dispatch(actions.signInUser(email, password)),
    signOutUser: () => dispatch(actions.signOutUser()),
    sendVerificationEmail: () => dispatch(actions.sendVerificationEmail()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
