import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";

import { Tooltip, Typography } from "@material-ui/core";
interface Props {
  title: string;
}
const CustomHelpIcon = ({ title }: Props) => {
  {
    /* </IconButton> */
  }

  return (
    <Tooltip
      arrow
      enterDelay={100}
      enterTouchDelay={200}
      placement="right-start"
      style={{ color: "#8C92A1", marginLeft: "2px" }}
      title={<Typography variant="caption">{title}</Typography>}
    >
      <InfoOutlineIcon fontSize="small" color="primary" />
    </Tooltip>
  );
};
export default CustomHelpIcon;
