import React from "react";
import { forwardRef } from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "#fff",
  },
}));

const TextFieldForwardRef = (props: TextFieldProps, ref: any) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      InputProps={{
        className: classes.input,
      }}
      inputRef={ref}
      fullWidth
      name="phone"
    />
  );
};
export default forwardRef(TextFieldForwardRef);
