// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_ApiKey,
  authDomain: process.env.REACT_APP_FIREBASE_AuthDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_DatabaseURL,
  projectId: process.env.REACT_APP_FIREBASE_ProjectId,
  storageBucket: process.env.REACT_APP_FIREBASE_StorageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MessagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_AppId,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  !process.env.REACT_APP_TESTING &&
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
} else {
  firebase.app(); // if already initialized, use that one
}
export { firebase };
