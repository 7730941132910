import React, { useEffect, useState } from "react";
import { lighten, makeStyles, Theme, fade, createStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { Button, CircularProgress, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},

    //////////////////////////////////////////////////
    search: {
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      // marginLeft: "10px",
    },
    search__root: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        // marginLeft: theme.spacing(0),
        // width: "100%",s
      },
      minWidth: "115px",
      borderRadius: theme.shape.borderRadius, //40,
      border: "1px solid",
      borderColor: theme.palette.divider,
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper, //'#fff',
      // "&:hover:not($disabled)": {
      //   borderColor: theme.palette.primary.main, //grey[500]
      // },
      "& > svg": {
        color: theme.palette.divider,
      },
    },
    search__input: {
      paddingLeft: "0.5rem",
      // padding: "0.625rem 1rem", //this for vertical and horizontal space
      "&:not(:first-child)": {
        paddingLeft: "0.5rem",
      },
      "&:not(:last-child)": {
        paddingRight: "0.5rem",
      },
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "25ch",
        // "&:focus": {
        //   width: "30ch",
        // },
      },
    },
    search__focused: {
      borderColor: theme.palette.primary.main,
      // "&:hover:not($disabled)": {
      //   borderColor: theme.palette.primary.main,
      // },
      // "&$adornedStart": {
      //   "& > svg": {
      //     color: theme.palette.primary.light,
      //   },
      // },
      // "&$colorSecondary": {
      //   borderColor: theme.palette.secondary.main,
      //   "&:hover:not($disabled)": {
      //     borderColor: theme.palette.secondary.main,
      //   },
      //   "&$adornedStart": {
      //     "& > svg": {
      //       color: theme.palette.secondary.light,
      //     },
      //   },
      // },
    },
    search__adornedStart: {
      paddingLeft: "0.5rem",
    },
    search__adornedEnd: {
      paddingRight: "0.5rem",
    },
    button__root: {
      minWidth: "auto",
    },
  })
);

interface Props {
  searchInputForwardedRef?: any;
  textSearchInput?: string;
  onSearchHandler?: () => void;
  disableButton?: boolean;
  cleanSearchText?: boolean;
  textSearchSaved?: string;
}
export default function HeaderSearch({
  searchInputForwardedRef,
  textSearchInput,
  onSearchHandler,
  disableButton,
  cleanSearchText,
  textSearchSaved,
}: Props) {
  const classes = useStyles();
  let timer: any;
  useEffect(() => {
    return clearTimeout(timer);
  });

  return (
    <div className={classes.search}>
      <InputBase
        inputRef={searchInputForwardedRef}
        classes={{
          root: classes.search__root,
          input: classes.search__input,
          focused: classes.search__focused,
          adornedStart: classes.search__adornedStart,
          adornedEnd: classes.search__adornedEnd,
        }}
        color={"primary"}
        placeholder={textSearchInput}
        // startAdornment={<SearchIcon />}

        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.stopPropagation();
            onSearchHandler && onSearchHandler();
          } else if (event.key === "Escape") {
            onSearchHandler && onSearchHandler();
          }
        }}
      />
      <Button
        size="small"
        color={"primary"}
        variant={"contained"}
        disableElevation
        onClick={(event) => {
          event.stopPropagation();
          onSearchHandler && onSearchHandler();
        }}
        classes={{
          root: classes.button__root,
        }}
        style={{ marginLeft: 1 }}
        disabled={disableButton}
      >
        <SearchIcon color={"inherit"} />
      </Button>
    </div>
  );
}
