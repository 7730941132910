import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
  AppBar,
  Badge,
  Button,
  CircularProgress,
  Fab,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  SettingsOverscan as SettingsOverscanIcon,
  CameraEnhance as CameraEnhanceIcon,
  Mic as MicIcon,
  MicOff as MicOffIcon,
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
  MeetingRoom as MeetingRoomIcon,
} from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import ErrorIconOutlined from "@material-ui/icons/ErrorOutline";
import { toast } from "react-toastify";
import MediaOnboardingDialog from "./RequestVideoOrMicPermission";
import useTypedTranslation from "../Hooks/useTypedTranslation";
import {
  AccessControlStateUpdateModel,
  DoorStateType,
  LockStateType,
  SourceType,
} from "../../Models/devices";
import { setLockState } from "../../API/apiDevices";
import useFireStoreSubscription from "../Hooks/useFireStoreSubscription";
import {
  FirestorePathRoute,
  NotificationDoorStateEnum,
  NotificationLockStateEnum,
  NotificationModel,
} from "../../Models/notifications";
import { shallowEqual, useSelector } from "react-redux";
import StoreStateModel from "../../Models/store";
import appStyles from "../../../../assets/theme/appStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...appStyles(theme),
    root: {},
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: (props: any) =>
        props.fullScreenMediaMobile
          ? props.isWall
            ? theme.palette.background.default
            : theme.palette.common.black
          : theme.palette.background.default,
      position: (props: any) => (props.isClickedFullScreen ? "fixed" : "inherit"),
      opacity: (props: any) => (props.isClickedFullScreen ? 0.8 : 1),
    },
    secondRow: {
      display: "flex",
      position: "relative",
      width: "100%",
      //   height: "60px", //to change bar
      //   paddingLeft: "10px",
      //   paddingRight: "10px",
    },
    toolbar: {
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    fabButton: {
      //   position: "absolute",
      //   zIndex: 1,
      //   top: -50,
      //   left: 0,
      //   right: 0,
      marginTop: "5px",
    },
    fontBold: {
      fontWeight: "bold",
    },
    spaceY: {
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
    favContainer: {
      marginBottom: 1,
      justifyContent: "center",
    },
    textWhite: {
      // color: theme.palette.common.white,
    },
    doorStateContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: theme.spacing(1),
    },
    "@keyframes blinker": {
      from: { opacity: 1 },
      to: { opacity: 0.2 },
    },
    buttonBlinking: {
      color: theme.palette.success.dark,
      animationName: "$blinker",
      animationDuration: "1s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
    },
  })
);
interface Props {
  deviceName: string;
  videoRef: HTMLVideoElement;
  onFullScreen?: () => void;
  disabled: boolean;
  isMicEnabled: boolean;
  onChangeMicStatus: () => void;
  isSpeakerEnabled: boolean;
  onChangeSpeakerStatus: () => void;
  serialNumber: string;
  micPermissionsError: boolean;
  isWall?: boolean;
  isClickedFullScreen: boolean;
  hasWebCredential: boolean;
  isAudioEnabled: boolean;
}
export default function VideoBarOptions({
  isClickedFullScreen,
  serialNumber,
  deviceName,
  videoRef,
  onFullScreen,
  disabled,
  isMicEnabled,
  onChangeMicStatus,
  isSpeakerEnabled,
  onChangeSpeakerStatus,
  micPermissionsError,
  isWall,
  hasWebCredential,
  isAudioEnabled,
}: Props): any {
  const theme = useTheme();
  const { t } = useTypedTranslation();
  const fullScreenMediaMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ fullScreenMediaMobile, isClickedFullScreen, isWall });

  const [doorState, setDoorState] = useState<number>(0);
  const [localLockState, setLocalLockState] = useState<number>(0);
  const [doorLoading, setDoorLoading] = useState(false);
  const isButtonClickedRef = useRef(false);
  function taking() {
    videoRef.style.filter = "brightness(2)";
    setTimeout(() => {
      videoRef.style.filter = "";
      takeSnapshoot();
    }, 50);
  }

  function takeSnapshoot() {
    const video = videoRef; //document.getElementById("videojs");
    const canvas = document.createElement("canvas");
    canvas.width = video?.videoWidth;
    canvas.height = video?.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(video as any, 0, 0, canvas.width, canvas.height);
    const dataURI = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.setAttribute("href", dataURI);
    link.setAttribute(
      "download",
      "alocity_snapshot_" + deviceName + "_" + new Date().toISOString() + ".png"
    );
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const hasPermissionDoorRemoteAccessWrite = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionDoorRemoteAccessWrite,
    shallowEqual
  );
  const doorOpenedRef = useRef<string | null>(null);

  const changeLockState = async (newAccessControlState: AccessControlStateUpdateModel) => {
    try {
      const response = await setLockState(newAccessControlState);
      if (!response.data.hasErrors) {
        if (newAccessControlState.lockStateType === LockStateType.Unlocked) {
          toast.info(t("OperationSent"));
          doorOpenedRef.current = serialNumber;
        }
      }
    } catch (error) {
      //manage in the interceptor
    } finally {
      //
    }
  };

  useFireStoreSubscription(
    FirestorePathRoute.Devices.Index,
    FirestorePathRoute.Devices.DoorState,
    (data: NotificationModel[] | NotificationModel) =>
      newDoorStateNotification(data as NotificationModel),
    serialNumber
  );

  const newDoorStateNotification = (data: NotificationModel) => {
    if (data.id === serialNumber) {
      switch (data.Code) {
        case NotificationDoorStateEnum.NotificationDoorState:
          setDoorState(data.Data);

          break;
      }
    }
  };

  useFireStoreSubscription(
    FirestorePathRoute.Devices.Index,
    FirestorePathRoute.Devices.LockState,
    (data: NotificationModel[] | NotificationModel) =>
      newLockStateNotification(data as NotificationModel),
    serialNumber
  );

  const newLockStateNotification = (noti: NotificationModel) => {
    if (noti.Code === NotificationLockStateEnum.NotificationLockState) {
      setLocalLockState(noti.Data);

      if (noti.Data === LockStateType.Unlocked) {
        setDoorLoading(true);

        if (doorOpenedRef.current) {
          toast.success(t("Opened") + ": " + deviceName);
        }
      } else if (noti.Data === LockStateType.Locked) {
        doorOpenedRef.current = null;
        setDoorLoading(false);
      }
    }
  };

  const isMicOff = !isMicEnabled || micPermissionsError;
  const CustomMicIcon = isMicOff ? MicOffIcon : MicIcon;

  const CustomSpeakerIcon = !isSpeakerEnabled ? VolumeOffIcon : VolumeUpIcon;

  const doorStateText = useMemo(() => {
    let text = "";

    switch (doorState) {
      case DoorStateType.Closed:
        text = t("Closed");
        break;
      case DoorStateType.OpenVerified:
        text = t("OpenVerified");
        break;
      case DoorStateType.OpenRequestToExit:
        text = t("OpenRequestToExit");
        break;
      case DoorStateType.OpenForcedEntry:
        text = t("OpenForcedEntry");
        break;
      case DoorStateType.OpenTimedOut:
        text = t("OpenTimedOut");
        break;
      case DoorStateType.NoSensor:
        text = t("NoSensor");
        break;
    }

    return text;
  }, [doorState]);

  const lockStateText = useMemo(() => {
    let text = "";

    switch (localLockState) {
      case LockStateType.Locked:
        text = t("Locked");
        break;
      case LockStateType.Unlocked:
        text = t("Unlocked");
        break;
      case LockStateType.Lockdown:
        text = t("Lockdown");
        break;
      case LockStateType.KeepOpen:
        text = t("KeepOpen");
        break;
      case LockStateType.UnlockedWithSchedule:
        text = t("UnlockedWithSchedule");
        break;
      case LockStateType.UnlockedRequestToExit:
        text = t("UnlockedRequestToExit");
        break;
    }

    return text;
  }, [localLockState]);
  const handleMicStatus = () => {
    onChangeMicStatus();
    if (!isButtonClickedRef.current) {
      handleSpeakerStatus();
    }
  };
  const handleSpeakerStatus = () => {
    onChangeSpeakerStatus();
    isButtonClickedRef.current = true;
  };
  return (
    <AppBar position="sticky" color="default" className={classes.appBar} elevation={0}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <Grid container className={classes.secondRow}>
          <Grid container alignContent="center" alignItems="center" justify="space-between">
            {fullScreenMediaMobile ? (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                spacing={1}
                className={classes.favContainer}
              >
                {isWall && (
                  <Grid item xs={4} md="auto" className={classes.doorStateContainer}>
                    <Typography variant="caption" className={classes.textWhite}>
                      <Typography
                        variant="caption"
                        component="span"
                        className={clsx(classes.fontBold, classes.textWhite)}
                      >
                        {`${t("LockState")}: `}
                      </Typography>
                      {lockStateText}
                    </Typography>
                    <Typography variant="caption" className={classes.textWhite}>
                      <Typography
                        variant="caption"
                        component="span"
                        className={clsx(classes.fontBold, classes.textWhite)}
                      >
                        {`${t("DoorState")}: `}
                      </Typography>
                      {doorStateText}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Fab
                    disabled={disabled}
                    size="small"
                    color="primary"
                    aria-label="snapshoot"
                    className={classes.fabButton}
                    onClick={() => taking()}
                  >
                    <CameraEnhanceIcon fontSize="small" />
                  </Fab>
                </Grid>
                <Grid item>
                  <Badge
                    invisible={!micPermissionsError}
                    badgeContent={
                      <ErrorIcon
                        fontSize="small"
                        style={{
                          color: theme.palette.warning.main,
                          marginTop: 12,
                          fontSize: 16,
                          marginRight: 8,
                        }}
                      />
                    }
                    overlap="circle"
                  >
                    <Fab
                      style={{ backgroundColor: "#fff" }}
                      disabled={disabled}
                      size="small"
                      color="inherit"
                      aria-label="mic"
                      className={classes.fabButton}
                      onClick={() => {
                        if (!micPermissionsError) {
                          if (!isAudioEnabled) {
                            toast.info(t("LiveVideoAudioDisabled"));
                          } else {
                            handleMicStatus();
                          }
                        } else {
                          toast.info(t("ErrorMicrophonePermissionDenied"));
                        }
                      }}
                    >
                      <CustomMicIcon
                        className={clsx({
                          [classes.buttonBlinking]: !isMicOff,
                        })}
                        fontSize="small"
                        color={isMicOff ? "error" : "inherit"}
                      />
                    </Fab>
                  </Badge>
                </Grid>

                <Grid item>
                  <Fab
                    style={{ backgroundColor: "#fff" }}
                    disabled={disabled}
                    size="small"
                    color="inherit"
                    aria-label="speaker"
                    className={classes.fabButton}
                    onClick={() => {
                      if (!isAudioEnabled) {
                        toast.info(t("LiveVideoAudioDisabled"));
                      } else {
                        handleSpeakerStatus();
                      }
                    }}
                  >
                    <CustomSpeakerIcon
                      className={clsx({
                        [classes.buttonBlinking]: isSpeakerEnabled,
                      })}
                      fontSize="small"
                      color={!isSpeakerEnabled ? "error" : "inherit"}
                    />
                  </Fab>
                </Grid>
                {hasPermissionDoorRemoteAccessWrite ? (
                  <Grid item>
                    <Fab
                      disabled={
                        disabled ||
                        doorLoading ||
                        localLockState === LockStateType.Lockdown ||
                        localLockState === LockStateType.Unlocked ||
                        localLockState === LockStateType.KeepOpen ||
                        localLockState === LockStateType.UnlockedWithSchedule ||
                        !hasWebCredential
                      }
                      size="small"
                      aria-label="open-door"
                      color="inherit"
                      className={clsx(classes.openDoorButton, classes.fabButton)}
                      onClick={() =>
                        changeLockState({
                          lockStateType: LockStateType.Unlocked,
                          serialNumbers: [serialNumber],
                          source: SourceType.web,
                        } as AccessControlStateUpdateModel)
                      }
                      style={doorLoading ? { backgroundColor: theme.palette.grey[300] } : {}}
                    >
                      {doorLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <MeetingRoomIcon fontSize="small" />
                      )}
                    </Fab>
                  </Grid>
                ) : null}
              </Grid>
            ) : (
              <>
                {isWall && (
                  <Grid item className={classes.spaceY}>
                    <Typography variant="caption" display="block">
                      <Typography variant="caption" component="span" className={classes.fontBold}>
                        {`${t("LockState")}: `}
                      </Typography>
                      {lockStateText}
                    </Typography>
                    <Typography variant="caption">
                      <Typography variant="caption" component="span" className={classes.fontBold}>
                        {`${t("DoorState")}: `}
                      </Typography>
                      {doorStateText}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <IconButton disabled={disabled} onClick={() => taking()}>
                    <CameraEnhanceIcon fontSize="small" />
                  </IconButton>
                </Grid>
                <Tooltip
                  title={
                    micPermissionsError ? (t("ErrorMicrophonePermissionDenied") as string) : ""
                  }
                  placement="top"
                  arrow
                >
                  <Grid item>
                    <Badge
                      invisible={!micPermissionsError}
                      badgeContent={
                        <ErrorIcon
                          fontSize="small"
                          style={{ color: theme.palette.warning.main, marginTop: 10, fontSize: 16 }}
                        />
                      }
                      overlap="circle"
                    >
                      <IconButton
                        disabled={disabled}
                        onClick={() => {
                          if (!micPermissionsError) {
                            if (!isAudioEnabled) {
                              toast.info(t("LiveVideoAudioDisabled"));
                            } else {
                              handleMicStatus();
                            }
                          } else {
                            toast.info(t("ErrorMicrophonePermissionDenied"));
                          }
                        }}
                      >
                        <CustomMicIcon
                          className={clsx({
                            [classes.buttonBlinking]: !isMicOff,
                          })}
                          fontSize="small"
                          color={isMicOff ? "error" : "inherit"}
                        />
                      </IconButton>
                    </Badge>
                  </Grid>
                </Tooltip>
                <Grid item>
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      if (!isAudioEnabled) {
                        toast.info(t("LiveVideoAudioDisabled"));
                      } else {
                        handleSpeakerStatus();
                      }
                    }}
                  >
                    <CustomSpeakerIcon
                      className={clsx({
                        [classes.buttonBlinking]: isSpeakerEnabled,
                      })}
                      fontSize="small"
                      color={!isSpeakerEnabled ? "error" : "inherit"}
                    />
                  </IconButton>
                </Grid>
                {hasPermissionDoorRemoteAccessWrite ? (
                  <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={
                        localLockState === LockStateType.Unlocked ||
                        localLockState === LockStateType.KeepOpen
                          ? (t("UnlockedAlready") as string)
                          : localLockState === LockStateType.Lockdown
                          ? (t("Lockdown") as string)
                          : localLockState === LockStateType.UnlockedWithSchedule
                          ? (t("UnlockedWithSchedule") as string)
                          : !hasWebCredential
                          ? t("TheDoorDoesntHaveTheWebCredential")
                          : ""
                      }
                    >
                      <span>
                        <Button
                          disabled={
                            disabled ||
                            doorLoading ||
                            localLockState === LockStateType.Lockdown ||
                            localLockState === LockStateType.Unlocked ||
                            localLockState === LockStateType.KeepOpen ||
                            localLockState === LockStateType.UnlockedWithSchedule ||
                            !hasWebCredential
                          }
                          variant="contained"
                          size="small"
                          className={classes.openDoorButton}
                          onClick={() =>
                            changeLockState({
                              lockStateType: LockStateType.Unlocked,
                              serialNumbers: [serialNumber],
                              source: SourceType.web,
                            } as AccessControlStateUpdateModel)
                          }
                        >
                          {doorLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <Typography noWrap variant="caption">
                              {t("Unlock")}
                            </Typography>
                          )}
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                ) : null}
                {!isWall && (
                  <Grid item>
                    <IconButton disabled={disabled} onClick={onFullScreen && onFullScreen}>
                      <SettingsOverscanIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
