import React, { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam"; //https://github.com/mozmorris/react-webcam
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: "#fff",
      marginRight: ".3rem",
    },
    webcamContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      marginTop: theme.spacing(4),
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: theme.spacing(2),
    },
  })
);
interface ScreenshotDimensions {
  width: number;
  height: number;
}
interface Props {
  onSuccess: (image: string) => void;
  hideSubmitButton?: boolean;
  submitLabel?: string;
  forceScreenshotSourceSize?: boolean;
  screenshotDimensions?: ScreenshotDimensions;
}

export const FaceCapture = ({
  onSuccess,
  submitLabel,
  hideSubmitButton,
  forceScreenshotSourceSize,
  screenshotDimensions,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [image, setImage] = useState<string | null>("");

  const { t } = useTranslation();
  const webcamRef = useRef<Webcam>(null);
  const [webcamError, setWebCamError] = useState("");

  const [loading, setLoading] = useState(true);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot(
        forceScreenshotSourceSize ? undefined : screenshotDimensions
      );
      setImage(imageSrc);
    }
  }, [webcamRef]);

  const createAFace = async () => {
    if (onSuccess) {
      onSuccess(image as string);
    }
  };

  return (
    <>
      <div className={classes.webcamContainer}>
        <div>
          {webcamError.length ? (
            <Typography variant="body1" style={{ padding: "10px" }}>
              {webcamError ? t(webcamError) : ""}
            </Typography>
          ) : (
            <div>
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography
                  variant={matches ? "body2" : "caption"}
                  style={matches ? {} : { fontSize: "2.4vw" }}
                >
                  {t("FaceNotStraightForward")}
                </Typography>
              )}
              {image == "" ? null : (
                <img
                  width={matches ? "640" : "240"}
                  height={"auto"}
                  src={image as any}
                  alt="webcam screenshot"
                />
              )}

              <div style={{ position: "relative" }}>
                <Webcam
                  forceScreenshotSourceSize={forceScreenshotSourceSize}
                  screenshotQuality={1}
                  style={{
                    display: image == "" ? (loading ? "none" : "block") : "none",
                  }}
                  audio={false}
                  width={matches ? "640" : "240"}
                  height="auto"
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  onUserMedia={(stream) => {
                    setLoading(false);
                  }}
                  videoConstraints={{
                    facingMode: "user",
                    // width: { ideal: 1920, max: 1920 },
                    // height: { ideal: 1080, max: 1080 },
                  }}
                  onUserMediaError={(error: string | DOMException) => {
                    setWebCamError("EnableCameraAccess");
                    console.error(error);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={classes.buttonContainer}>
        {image != "" ? (
          <Grid container justify="space-evenly">
            <Button
              variant="outlined"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setImage("");
              }}
            >
              {t("Retake")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                if (hideSubmitButton) {
                  onSuccess(image as string);
                }
              }}
            >
              {t("Confirm")}
            </Button>
          </Grid>
        ) : (
          <Button
            disabled={(webcamError.length ? true : false) || loading}
            type="submit"
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
          >
            {t("Capture")}
          </Button>
        )}

        {!hideSubmitButton && (
          <Button
            style={{ marginLeft: "10px" }}
            variant="contained"
            disabled={!image}
            onClick={createAFace}
            color="primary"
          >
            {submitLabel || t("Submit")}
          </Button>
        )}
      </div>
    </>
  );
};
