import React from "react";
import Page500 from "./Page500";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";

interface IState {
  hasError: boolean;
  eventId: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
  children: any;
}
class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, eventId: "" };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    const eventId = Sentry.captureException(error, {
      level: Severity.Fatal,
      contexts: {
        contexts: {
          errorInfo: {
            errorInfo: JSON.stringify(errorInfo),
          },
        },
      },
    });

    this.setState({
      eventId,
    });
  }
  render() {
    if (this.state.hasError) {
      return <Page500 eventId={this.state.eventId} />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
