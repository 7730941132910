import { createStore, applyMiddleware } from "redux";

// developmentOnly this is to only show the redux state in the browser in develop mode, in .env file this variable MUST be like this for production: NODE_ENV=production
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import thunkMiddleware from "redux-thunk";

import createReducers from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
// import storage from 'redux-persist/lib/storage'
import localforage from "localforage";
import { verifyAuth } from "./actions/auth_actions";
import { interceptors1 } from "./actions/auth_actions";

localforage.config({
  name: "alocity",
});
//////////////////////////////////
const createStoreFromClient = (props?: any) => {
  const rootPersistConfig = {
    key: "alocity",
    storage: localforage,
    whitelist: [], //here you will persist entire reducer
  };
  const reducerPersist = persistReducer(rootPersistConfig, createReducers());

  const store = makeConfiguredStore(reducerPersist, props);
  const persistor = persistStore(store);

  /* The function will be called every time our app starts up, which also happens when the user refreshes the page. I like to dispatch verifyAuth() here because it ensures it will be the first thing happens to our redux store, and if a user session exists it will authenticate our user before the rest of the app loads which will let us route to our secure route without our login page showing. */
  store.dispatch(verifyAuth());
  /////////////////////////////
  /*Sending the store, allow me to use 'dispatch' inside of interceptors */
  interceptors1(store);
  ////////////////////////////
  return { store, persistor };
};
/////////////////////////////////////////
const makeConfiguredStore = (reducer: any, props?: any) =>
  createStore(
    reducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware.withExtraArgument(props)))
  );
////////////////////////////////////

const initStore = (props?: any) => createStoreFromClient(props);

export default initStore;
