import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function GeneralLoading(): JSX.Element {
  return (
    <Grid container justify={"center"}>
      <Grid item style={{ position: "absolute", left: "50%", top: "50%" }}>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
