import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints, QueryFilter, Response } from "../Models/api";
import { CloudKeyModel } from "../Models/cloudkey";

export const getAllCloudKeyByUser = (userId: string, isVisitor?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/cloudkey",
    method: axiosMethods.get,
    params: {
      userId: userId,
      isVisitor,
    },
  });
};

export const deleteACloudKey = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/cloudkey/" + id,
    method: axiosMethods.delete,
  });
};

export const addNewCloudkey = (data: CloudKeyModel, isVisitor?: boolean) => {
  const data2: any = data;
  delete data2.id;
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/cloudkey",
    method: axiosMethods.post,
    data: data2,
    params: {
      isVisitor,
    },
  });
};

export const readDoorsOfCloudKey = (key: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.scheduleManagement + "/schedule/cloudKey/" + key + "/devices",
    method: axiosMethods.get,
  });
};

export const openTheDoorUsingCloudKey = (doorSerialNumber: string, key: string, pin: number) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/open-door/" + doorSerialNumber + "/cloud-key/" + key,
    method: axiosMethods.get,
    params: {
      pin,
    },
  });
};
