import React, { useEffect, useState } from "react";
import {
  DescribeSignalingChannelCommand,
  DescribeSignalingChannelCommandOutput,
  GetSignalingChannelEndpointCommand,
  GetSignalingChannelEndpointOutput,
  KinesisVideo,
  ResourceEndpointListItem,
  Status,
} from "@aws-sdk/client-kinesis-video";
import * as KVSWebRTC from "amazon-kinesis-video-streams-webrtc";
import { ERROR_CHANNEL_NAME_MISSING } from "../constants";

export function useChannelARN(config: { channelName: string; kinesisVideoClient: KinesisVideo }): {
  error: Error | undefined;
  channelARN: string;
} {
  const { channelName, kinesisVideoClient } = config;
  const [error, setError] = useState<Error>();
  const [channelARN, setChannelARN] = useState<string>("");

  if (!channelName) {
    throw new Error(ERROR_CHANNEL_NAME_MISSING);
  }

  useEffect(() => {
    let isCancelled = false;

    const params = {
      ChannelName: channelName,
    };
    const command = new DescribeSignalingChannelCommand(params);

    kinesisVideoClient
      .send(command)
      .then((response: DescribeSignalingChannelCommandOutput) => {
        if (isCancelled) {
          return;
        }
        if (
          response.ChannelInfo?.ChannelARN !== "" &&
          response.ChannelInfo?.ChannelStatus === Status.ACTIVE
        ) {
          setChannelARN(response.ChannelInfo?.ChannelARN as string);
        }
      })
      .catch((error: Error) => {
        console.error("setChannelARN", error);
        if (isCancelled) {
          return;
        }
        setError(error);
      });

    return function cleanup() {
      isCancelled = true;
    };
  }, [channelName, kinesisVideoClient]);

  return { error, channelARN };
}
