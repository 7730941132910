import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@material-ui/core";
import DialogWrapper from "./DialogWrapper";

interface Props {
  open: boolean;
  onClose?: () => void;
}

export default function CaptchaErrorDialog({ open, onClose }: Props) {
  const { t } = useTranslation();

  const reloadPage = () => window.location.reload();

  return (
    <DialogWrapper
      open={open}
      onClose={() => {
        reloadPage();

        onClose && onClose();
      }}
      aria-labelledby="schedule upgrade"
      aria-describedby="schedule upgrade"
      maxWidth="xs"
      fullWidth
      title={t("Error")}
    >
      <Typography>{t("captcha_verification_error")}</Typography>

      <Box mt={4} display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={reloadPage}>
          {t("Accept")}
        </Button>
      </Box>
    </DialogWrapper>
  );
}
