import { useTranslation } from "react-i18next";
import en from "../../../../translation/en/translation";

export type ValidI18nKey = keyof typeof en;

const useTypedTranslation = () => {
  const { t: i18T } = useTranslation();

  const typedTranslation = (message: ValidI18nKey) => i18T(message);

  return { t: typedTranslation };
};

export default useTypedTranslation;
