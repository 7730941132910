import React from "react";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { TransitionProps } from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: 0,
      padding: 0,
      paddingTop: theme.spacing(1),
      // paddingBottom: theme.spacing(1),

      paddingLeft: theme.spacing(2),
      color: (props: any) =>
        props.isFullScreen
          ? theme.palette.getContrastText(theme.palette.common.black)
          : theme.palette.getContrastText(theme.palette.background.default),
    },
    actions: {
      //
    },
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      // top: theme.spacing(1),
      color: (props: any) =>
        props.isFullScreen
          ? theme.palette.getContrastText(theme.palette.common.black)
          : theme.palette.getContrastText(theme.palette.background.default),
    },
    paper: {
      backgroundColor: (props: any) =>
        props.isFullScreen ? theme.palette.common.black : theme.palette.background.default,
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        borderRadius: 10,
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  onClose: () => void;
  open: boolean;
  children: any;
  title?: string;
  fullWidth?: boolean;
  maxWidth?: any;
  rest?: any;
  fullScreen: boolean;
  isDeviceIntercom?: boolean;
}

function DialogWrapper({
  open,
  onClose,
  title,
  children,
  fullWidth = true,
  maxWidth = "md",
  fullScreen,
  isDeviceIntercom,
  ...rest
}: Props) {
  const theme = useTheme();
  const fullScreenMedia = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const isFullScreen = fullScreen || fullScreenMedia;
  const classes = useStyles({ isFullScreen });
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason: string) => {
        if (!reason) {
          onClose();
        }
      }}
      TransitionComponent={Transition}
      className={classes.root}
      aria-labelledby="video-live"
      fullWidth={fullWidth}
      maxWidth={matches ? "sm" : "md"}
      fullScreen={isFullScreen}
      {...rest}
      {...(isDeviceIntercom && {
        PaperProps: {
          style: {
            objectFit: "contain",
            maxWidth: fullScreenMedia ? "100%" : "60%",
            maxHeight: "100%",
          },
        },
      })}
    >
      <DialogTitle className={classes.title} disableTypography id="video-dialog-title">
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
      <div className={classes.actions}>{children}</div>
    </Dialog>
  );
}

export default DialogWrapper;
