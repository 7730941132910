import * as actionTypes from "./actionTypes";

export const changeSideBar = (value: boolean) => {
  return {
    type: actionTypes.SIDEBARNAV_POSITION,
    value,
  };
};

export const changeTheme = () => {
  return {
    type: actionTypes.CHANGE_THEME,
  };
};

export const changesInWindowDimenssions = (height: number, width: number) => {
  return {
    type: actionTypes.CHANGE_WINDOW_DIMENSSIONS,
    value: {
      innerHeight: height,
      innerWidth: width,
    },
  };
};
