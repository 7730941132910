declare global {
  const grecaptcha: IReCaptchaInstance;

  interface Window {
    grecaptcha: IReCaptchaInstance;
  }
}

export interface IReCaptchaInstance {
  ready(callback: () => void): void;
  execute(siteKey: string, options: IExecuteOptions): Promise<string>;
  render(parameters: IRenderParameters): string;
}

export declare interface IExecuteOptions {
  action?: string;
}

export declare interface IRenderParameters {
  sitekey: string;
}

export class ReCaptchaInstance {
  private readonly siteKey: string;
  private readonly recaptchaID: string;
  private readonly recaptcha: IReCaptchaInstance;

  public constructor(siteKey: string, recaptchaID: string, recaptcha: IReCaptchaInstance) {
    this.siteKey = siteKey;
    this.recaptchaID = recaptchaID;
    this.recaptcha = recaptcha;
  }

  public async execute(action?: string): Promise<string> {
    return this.recaptcha.execute(this.recaptchaID, { action });
  }

  public getSiteKey(): string {
    return this.siteKey;
  }
}
