import React, { useEffect } from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { Container, Grid, Typography } from "@material-ui/core";
import WrapperSignInSignUp from "./components/WrapperSignInSignUp";
import { useDispatch } from "react-redux";
import * as actions from "../../../store/actions";
import styles from "./MobileCredential.module.css";
import { useTranslation } from "react-i18next";

const MobileCredential = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.signOutUser());
  }, [dispatch]);

  return (
    <WrapperSignInSignUp>
      <Container component="main" maxWidth="xs" className={styles.root}>
        <div>
          <Card elevation={0}>
            <CardContent>
              <div className={styles.paper}>
                <img src={"/images/alocity-logo.png"} alt="Alocity logo" className={styles.logo} />

                <Grid container style={{ marginTop: "16px" }}>
                  <Typography component="h1" variant="subtitle1">
                    {t("OnlyCredentialsToAccesApp")}
                  </Typography>
                </Grid>
                <Grid container justify="space-between" style={{ marginTop: "16px" }}>
                  <Grid item xs={6}>
                    <a
                      href="https://apps.apple.com/us/app/alocity-1id/id6444294420"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/image_app_store.png" alt="App Store" />
                    </a>
                  </Grid>
                  <Grid item xs={6}>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.alocitymobileoneid"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/image_google_play.png" alt="Google Play Store" />
                    </a>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </div>
      </Container>
    </WrapperSignInSignUp>
  );
};

export default MobileCredential;
