import React from "react";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useTypedTranslation from "../../Utils/Custom/Hooks/useTypedTranslation";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import { pathRoute } from "../../Utils/Routes/routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successColor: {
      color: theme.palette.success.dark,
    },
    spaceTop: { marginTop: theme.spacing(2) },
    button: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
  })
);
interface Props {
  oobCode: string;
}
function RedirectFromCheckout({ oobCode }: Props) {
  const classes = useStyles();
  const { t } = useTypedTranslation();
  const history = useHistory();
  return (
    <Card variant="outlined" className={classes.spaceTop}>
      <CardContent>
        <Grid container direction="column" justify="center" alignItems="center">
          {oobCode === "success" ? (
            <>
              <Grid item className={classes.spaceTop}>
                <CheckCircleOutlineIcon
                  style={{ fontSize: 100 }}
                  className={classes.successColor}
                />
              </Grid>
              <Grid item className={classes.spaceTop}>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {t("ThanksForYourPayment")}
                </Typography>
              </Grid>
              <Grid item className={classes.spaceTop}>
                <Typography variant="subtitle2" style={{ textAlign: "center" }}>
                  {t("ReceiptThisTransaction")}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item className={classes.spaceTop}>
                <CancelIcon style={{ fontSize: 100 }} color="error" />
              </Grid>
              <Grid item className={classes.spaceTop}>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {t("UnableToProcessPayment")}
                </Typography>
              </Grid>
              <Grid item className={classes.spaceTop}>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  {t("WeAreSorryUnableToProccess")}
                </Typography>
              </Grid>
              <Grid item className={classes.spaceTop}>
                <Typography variant="body2" style={{ textAlign: "center" }}>
                  {t("IfYouNeedHelp") + " " + process.env.REACT_APP_CONTACT_PHONE}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <CardActions className={classes.button}>
          <Button
            onClick={() => {
              history.replace(pathRoute.Dashboard);
            }}
            variant="contained"
            color="primary"
          >
            {t("OK")}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
export default RedirectFromCheckout;
