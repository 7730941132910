import React, { useEffect, useState, useRef } from "react";
import { Response } from "../../Models/api";
import { downloadVideo, getEventVideoHLS } from "../../API/apiDevices";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import "./videojs_v7_nuevo_v10/skins/chrome/videojs.min.css";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "./videojs_v7_nuevo_v10/nuevo";
import { Card, CardContent, Grid, LinearProgress, Typography } from "@material-ui/core";
import en from "./videojs_v7_nuevo_v10/lang/en.json";
import es from "./videojs_v7_nuevo_v10/lang/es.json";

import AlocityLoading from "../CustomLoading/AlocityLoading";
import moment from "moment";
import { toast } from "react-toastify";
import ShareEventVideoComponent from "./ShareEventVideoComponent";
import DialogWrapper from "../DialogWrapper";
import axios, { CancelTokenSource } from "axios";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "5px",
      minHeight: 150,
      position: "relative",
    },
    errorText: {
      margin: "5px",
    },
  })
);
interface Props {
  time: string;
  serialNumber: string;
  deviceName: string;
  url?: string;
  isPublic?: boolean;
  endTime?: string;
  hideVolumePanel?: boolean;
  autoplay?: boolean;
}

export default function EventVideoHLS({
  serialNumber,
  time,
  deviceName,
  url,
  isPublic,
  endTime,
  hideVolumePanel,
  autoplay,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [connection, setConnection] = useState<string>("");
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const videoNode = useRef<HTMLVideoElement | any>();
  const player = useRef<VideoJsPlayer | any>();
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (url) {
      setConnection(url);
    }
  }, [url]);

  async function downloadTheVideo() {
    try {
      const cancelToken: CancelTokenSource = axios.CancelToken.source();

      setLoading(true);
      const response = await downloadVideo(
        serialNumber,
        time,
        cancelToken.token,
        endTime ? moment(endTime).diff(moment(time), "seconds") : undefined
      );
      const contentType = response.headers["content-type"];
      if (contentType.includes("application/json")) {
        //when the responseType is blob the data.hasErrors is not working, and if i remove the responseType=blob the video downloaded cannot reproduce
        toast.error(t("error_video_notfound"));
        return;
      }
      const data: any = response.data;

      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", "alocity_event_" + deviceName.trim() + "_" + time + ".mp4");
      document.body.appendChild(a);
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener("click", clickHandler);
          a.remove();
        }, 150);
      };
      a.addEventListener("click", clickHandler, false);
      a.click();
      toast.success(t("Done"));
    } catch (error) {
      toast.error(t("error_video_notfound"));
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    const cancelToken: CancelTokenSource = axios.CancelToken.source();
    const load = async () => {
      try {
        setLoading(true);
        const response: Response = await getEventVideoHLS(
          serialNumber,
          time,
          cancelToken.token,
          endTime ? moment(endTime).diff(moment(time), "seconds") : undefined
        );
        if (!response.data.hasErrors) {
          setConnection(response.data.payload);
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    };
    if (!url && serialNumber && time) {
      load();
    }
  }, [serialNumber, time]);

  useEffect(() => {
    if (connection) {
      //https://docs.videojs.com/tutorial-options.html#standard-video-element-options
      const videoJsOptions: VideoJsPlayerOptions = {
        fluid: true,
        autoplay: autoplay,
        controls: true,
        controlBar: {
          volumePanel: !hideVolumePanel,
        },
        muted: hideVolumePanel,
        language: i18n.language,
        languages: {
          en: en,
          es: es,
        },
        sources: [
          {
            src: connection,
            type: "application/x-mpegURL",
          },
          {
            src: connection,
            type: "video/mp4",
          },
        ],

        // poster: "/images/alocity-logo.png",
      };

      player.current = videojs(videoNode.current as any, videoJsOptions, function onPlayerReady() {
        // player.current.src({
        //   src: connection,
        //   type: "application/x-mpegURL",
        //   // withCredentials: true
        // });

        //////////////////////////////////////////////
        player.current.nuevo({
          // logo: isPublic ? "/images/designed/alocity-logo.svg" : undefined,
          // logoposition: isPublic ? "RT" : undefined,
          // logocontrolbar: "/images/alocity-logo.png",
          // logotitle: "alocity",
          snapshot: isPublic ? false : true,
          snapshotType: "png",
          shareMenu: isPublic ? false : true,
          url: connection,
          rewindButton: false,
          videoInfo: true,
          title: deviceName + " - " + moment(time).format("L HH:mm"),
          zoomMenu: false,
          zoomInfo: false,
          downloadButton: isPublic ? false : true,
          pipButton: false,
          contextMenu: false,
          //   embed:
          // '<iframe width="640" height="360" src="//nuevodevel.com/embed/ski" frameborder="0" allowfullscreen"></iframe>',
        });
        //////////////////////////////////////////////

        player.current.on("dispose", () => {
          //
        });
        player.current.on("play", () => {
          //
        });
        player.current.on("downloadVideo", function (event: any, data: any) {
          downloadTheVideo();
        });
        player.current.on("share", () => {
          setOpenShareDialog(true);
        });

        player.current.on("error", (e: any) => {
          const code = player.current.error().code;
          //https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code
          if (code === 4) {
            //MEDIA_ERR_SRC_NOT_SUPPORTED
            setErrorMessage(t("MEDIA_ERR_SRC_NOT_SUPPORTED"));
          } else if (code === 2) {
            //MEDIA_ERR_NETWORK
            setErrorMessage(t("MEDIA_ERR_NETWORK"));
          } else {
            setErrorMessage(t("ERROR"));
          }

          const errorDisplay = document.createElement("div");
          errorDisplay.textContent = errorMessage;
          errorDisplay.style.color = "white";
          errorDisplay.style.padding = "50px";
          errorDisplay.style.fontSize = "20px";

          // Clear the existing error message (if any) and append the custom error message
          const errorContainer = (player as any)
            .el()
            .getElementsByClassName("vjs-error-display")[0];
          errorContainer.innerHTML = "";
          errorContainer.appendChild(errorDisplay);
        });

        //////////////////////////////
      });
    }
    ///////////////////////////////
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, [connection]);

  return (
    <Card variant="outlined" className={classes.root}>
      <div data-vjs-player>
        <video
          ref={videoNode as any}
          className="video-js vjs-fluid"
          controls
          playsInline={true}
          preload="auto"
          onError={(event) => {
            setErrorMessage("Error_MEDIA_ERR_SRC_NOT_SUPPORTED");
          }}
        >
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          supports HTML5 video
        </video>
      </div>
      {connection && !errorMessage.length ? (
        <div style={{ position: "absolute" }}>{""}</div>
      ) : errorMessage.length ? (
        <Typography variant="body2" color="error" className={classes.errorText}>
          {t(errorMessage)}
        </Typography>
      ) : loading ? null : (
        <Typography className={classes.errorText} variant="body2" color={"error"}>
          {t("ThereIsnoEventVideo")}
        </Typography>
      )}

      {loading ? (
        <>
          <Grid container justify="center" style={{ position: "absolute", top: 0 }}>
            <AlocityLoading />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          </Grid>
        </>
      ) : null}
      <DialogWrapper
        open={openShareDialog}
        onClose={() => {
          setOpenShareDialog(false);
          player.current.play();
        }}
        maxWidth="xs"
        fullWidth
      >
        <ShareEventVideoComponent
          time={time}
          serialNumber={serialNumber}
          deviceName={deviceName}
          onClose={() => {
            setOpenShareDialog(false);
            player.current.play();
          }}
        />
      </DialogWrapper>
    </Card>
  );
}
