import { switchBaseUnCheckColor } from "../palette";

export const MuiButton = {
  MuiButton: {
    root: {
      textTransform: "none",
      // fontWeight: "bold"
    },
  },
  MuiSwitch: {
    switchBase: {
      color: switchBaseUnCheckColor,
    },
  },
};
