import { DeviceType } from "./devices";

interface AccessLogsVM {
  id: string;
  acountId: number;
  time: string;
  userId?: string;
  firstName: string;
  lastName: string;
  email: string;
  serialNumber: string;
  deviceName: string;
  workFlow: string;
  accessResult: AccessLogResultType;
  workflow: "Card" | "Face" | "Mobile" | "Web"; //this is access workflow separated by ','
  timeZoneOffset: string;
  siteId: number;
  siteName: string;
  picture?: string;
  guestUserId?: string;
  hasVideo: boolean;
  isVideoExpired?: boolean;
  zoneId: number;
  deviceType: DeviceType;
}
export interface AccessLogsModel extends AccessLogsVM {
  label?: string;
  value?: string;
}
export enum AccessLogResultType {
  AccessGranted = 0,
  NotScheduled = 1,
  UnknownUser = 2,
  DeviceInLockdown = 3,
  NonLiveUser = 4,
  CardFormatError = 6,
  LockdownStart = 7,
  LockdownEnd = 8,
  Doorbell = 9,
  Suspended = 10,
}
export interface AccessType {
  name: string;
  data: number[];
}

interface AccessLogsTypeModel {
  deniedLockdown: number;
  deniedNotScheduled: number;
  deniedSpoof: number;
  deniedUnknown: number;
  granted: number;
}

export interface AccessLogsDeviceModel extends AccessLogsTypeModel {
  name: string;
  id: string;
  total?: number;
}

export interface ActivityPerDayModel {
  [date: string]: AccessLogsDeviceModel[];
}

export interface ActivityPerDeviceModel {
  total: number;
  name: any;
  id: any;
}

export interface RankingDevicesModel {
  [device: string]: ActivityPerDeviceModel;
}

export interface ActiveUserModel extends AccessLogsTypeModel {
  name: string;
  id: string;
}

export interface AccessLogsStatsModel {
  activityPerDate?: ActivityPerDayModel;
  mostActiveUsers?: ActiveUserModel[];
}

export interface EventVideoInfo {
  hasVideo: boolean; // this inform if contain the video
  videoAvailable: boolean; // this inform when the device finished to upload the video
  deviceWithVideo: boolean; // this inform if the setting of video is ON
}
