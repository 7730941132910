import React from "react";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DashboardOutlinedIcon from "@material-ui/icons/Apps";
import SaveOutlineIcon from "@material-ui/icons/SaveOutlined";
import WorkIcon from "@material-ui/icons/Work";

import PersonIcon from "@material-ui/icons/PersonOutline";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ListAltIcon from "@material-ui/icons/ListAltOutlined";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { GeneralRoute, PortalRoutes } from "../Models/routesModel";
import {
  LazyDashboard,
  LazySettings,
  LazyProfilePage,
  LazyCameras,
  LazySubscriptionsPage,
  LazyCameraDetails,
  LazyUsers,
  LazyGroups,
  LazyDoors,
  LazyAccessLogsReport,
  LazyDetailGroup,
  LazyDetailUser,
  LazySchedules,
  LazyDetailSchedule,
  LazyZones,
  LazyDetailZone,
  LazyDetailDoor,
  LazyRoles,
  LazyDetailRole,
  LazySites,
  LazyDetailSite,
  SupportArea,
  LazyHolidays,
  LazyAccount,
  LazyReports,
  LazyDoorReport,
  LazyScheduleReport,
  LazySiteReport,
  LazyIntegrations,
  LazyDetailWebhook,
  LazyVideoIntercom,
  LazyVisitors,
  LazyDetailVisitor,
  LazyVisitorsSchedules,
  LazyVisitorsDetailSchedule,
  LazyWorkflows,
  LazyDetailWorkflow,
  LazyEventHistoryReport,
  LazyCatalog,
  LazySensors,
  LazyDetailSensor,
  LazyDashboardCameras,
  LazyDashboardGateway,
  LazyAppliancesGateway,
  LazyAppliancesDetails,
  LazySetupMessages,
  LazySetupPanelElevator,
  LazySetupFloors,
  LazySetupPanelRelaysOfElevator,
  LazyApprovalRequests,
  LazyApprovalRequestsDetails,
} from "./lazyComponents";
import DomCameraIcon from "../../../assets/icons/DomCameraIcon";
import AccessControlIcon from "../../../assets/icons/AccessControlIcon";
import HomeIcon from "../../../assets/icons/HomeIcon";
import { PortalIds } from "../Models/account";
import ViewArrayIcon from "@material-ui/icons/ViewArrayOutlined";
import ManageAccountsIcon from "@material-ui/icons/ContactMail"; //ManageAccounts AddMAddModerator
import { EScopesType } from "../Models/auth";
import SensorIcon from "../../../assets/icons/SensorIcon";
import RouterIcon from "@material-ui/icons/Router";

//////////////////////////////////////
export const pathRoute = {
  Dashboard: "/",
  Settings: {
    Index: "/settings",
    Account: "/settings/account",
    Catalog: "/setting/catalog",
    SetupMessages: "/setting/setupmessages",
    Floors: "/elevator-settings/floors",
    ApprovalRequests: "/setting/approvalrequests",
    ApprovalRequestsDetail: "/setting/approvalrequests/detail",
  },
  Profile: {
    Index: "/profile",
  },
  SignIn: {
    Index: "/signin",
    ForgotPassword: "/signin/forgotpassword",
    MobileCredential: "/signin/mobilecredential",
  },
  SignUp: {
    Index: "/9947E38C-E419-4118-9EAD-EF4ADD2B49FE",
  },
  Action: "/action",
  Cameras: {
    Index: "/cameras",
    Details: "/cameras/details",
  },
  Appliances: {
    Index: "/appliancesGateway",
    Details: "/appliancesGateway/appliancesDetails",
  },
  Sites: {
    Index: "/sites",
    Details: "/sites/details",
  },
  Subscriptions: {
    Index: "/subscriptions",
  },
  Users: {
    Index: "/users",
    Details: "/users/details",
  },
  Groups: {
    Index: "/groups",
    Details: "/groups/details",
  },
  Doors: {
    Index: "/doors",
    Details: "/doors/details",
    // Video: "/doors/video",
  },
  Intercom: {
    Index: "/intercoms",
    Details: "/intercoms/details",
    // Video: "/doors/video",
  },

  Reports: {
    Index: "/reports",
    Accesslog: "/reports/accesslog",
    Site: "/reports/site",
    Door: "/reports/door",
    Schedule: "/reports/schedule",
    EventHistory: "/reports/eventhistory",
  },
  Schedules: {
    Index: "/schedules",
    Details: "/schedules/details",
  },
  Zones: {
    Index: "/zones",
    Details: "/zones/details",
  },
  Roles: {
    Index: "/roles",
    Details: "/roles/details",
  },
  SupportArea: {
    Index: "/supportarea",
  },
  Holidays: {
    Index: "/holidays",
  },
  Integrations: {
    Index: "/integrations",
    WebhookDetails: "/integrations/webhooks/details",
  },
  VideoIntercom: {
    Index: "/videointercom",
  },
  Visitors: {
    Index: "/visitors",
    Details: "/visitors/details",
    Schedules: "/visitors/schedules",
    SchedulesDetails: "/visitors/schedules/details",
  },
  Workflows: {
    Index: "/workflows",
    Details: "/workflows/details",
  },
  Sensor: {
    Index: "/sensors",
    Details: "/sensors/details",
  },
  Elevator: {
    Index: "/elevator",
    Relays: "/elevator/relays",
  },
};
//NOTE: the "name" have to be equal to the text in the i18next file
export const breadcrumbNameMap: { [key: string]: string } = {
  //"path" : "name"
  // "/": "Dashboard",
  // "/settings": "Settings",
  // "/profile": "Profile",
};
/////////////////////////////////////

const commonRoutes: GeneralRoute[] = [
  {
    id: "Route_Settings",
    path: pathRoute.Settings.Index,
    exact: true,
    skipSideBarMenu: true,
    sideBarNavIcon: SettingsIcon,
    textLangSidebarNav: "Settings",
    addToSideBarMenuInMobile: true,
    mainContentComponent: <LazySettings />,
    authorization: [
      EScopesType.site_r,
      EScopesType.zone_r,
      EScopesType.group_r,
      EScopesType.role_r,
      EScopesType.account_w,
      EScopesType.account_r,
    ],
  },
  {
    id: "Route_Profile",
    path: pathRoute.Profile.Index,
    exact: true,
    skipSideBarMenu: true,
    sideBarNavIcon: AccountCircleIcon,
    textLangSidebarNav: "Profile",
    addToSideBarMenuInMobile: true,
    mainContentComponent: <LazyProfilePage />,
    authorization: [],
  },
  // {
  //   id: "Route_Subscriptions",
  //   path: pathRoute.Subscriptions.Index,
  //   exact: true,
  //   skipSideBarMenu: true,
  //   sideBarNavIcon: AccountBalanceIcon,
  //   textLangSidebarNav: "Subscriptions",
  //   addToSideBarMenuInMobile: true,
  //   mainContentComponent: <LazySubscriptionsPage />,
  //   authorization: [EScopesType.accountsubscription_w],
  // },
  {
    id: "Route_Groups",
    path: pathRoute.Groups.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyGroups />,
    authorization: [EScopesType.group_r],
  },
  {
    id: "Route_DetailGroup",
    path: pathRoute.Groups.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailGroup />,
    authorization: [EScopesType.group_r],
  },
  {
    id: "Route_Zones",
    path: pathRoute.Zones.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyZones />,
    authorization: [EScopesType.zone_r],
  },
  {
    id: "Route_DetailZone",
    path: pathRoute.Zones.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailZone />,
    authorization: [EScopesType.zone_r],
  },
  {
    id: "Route_Sites",
    path: pathRoute.Sites.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazySites />,
    authorization: [EScopesType.site_r],
  },
  {
    id: "Route_DetailSite",
    path: pathRoute.Sites.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailSite />,
    authorization: [EScopesType.site_r],
  },
  {
    id: "Route_Roles",
    path: pathRoute.Roles.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyRoles />,
    authorization: [EScopesType.role_r],
  },
  {
    id: "Route_DetailRole",
    path: pathRoute.Roles.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailRole />,
    authorization: [EScopesType.role_r],
  },
  {
    id: "Route_SupportArea",
    path: pathRoute.SupportArea.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <SupportArea />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_Holidays",
    path: pathRoute.Holidays.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyHolidays />,
    authorization: [EScopesType.schedule_r],
  },
  {
    id: "Route_Account",
    path: pathRoute.Settings.Account,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyAccount />,
    authorization: [EScopesType.account_r],
  },
  {
    id: "Route_Integrations",
    path: pathRoute.Integrations.Index,
    exact: true,
    mainContentComponent: <LazyIntegrations />,
    authorization: [EScopesType.account_w],
    skipSideBarMenu: true,
  },
  {
    id: "Route_Integrations_Webhook_Details",
    path: pathRoute.Integrations.WebhookDetails,
    exact: true,
    mainContentComponent: <LazyDetailWebhook />,
    authorization: [EScopesType.account_w],
    skipSideBarMenu: true,
  },
  {
    id: "Route_Visitors_Schedules",
    path: pathRoute.Visitors.Schedules,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyVisitorsSchedules />,
    authorization: [EScopesType.schedule_r],
  },
  {
    id: "Route_Visitors_DetailSchedule",
    path: pathRoute.Visitors.SchedulesDetails,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyVisitorsDetailSchedule />,
    authorization: [EScopesType.schedule_r],
  },
  {
    id: "Route_Workflows",
    path: pathRoute.Workflows.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyWorkflows />,
    authorization: [EScopesType.account_r],
  },
  {
    id: "Route_DetailWorkflow",
    path: pathRoute.Workflows.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailWorkflow />,
    authorization: [EScopesType.account_r],
  },
  {
    id: "Route_Catalog",
    path: pathRoute.Settings.Catalog,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyCatalog />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_SetupMessages",
    path: pathRoute.Settings.SetupMessages,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazySetupMessages />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_ApprovalRequests",
    path: pathRoute.Settings.ApprovalRequests,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyApprovalRequests />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_ApprovalRequestsDetail",
    path: pathRoute.Settings.ApprovalRequestsDetail,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyApprovalRequestsDetails />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_Elevator",
    path: pathRoute.Elevator.Index,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazySetupPanelElevator />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_Floors",
    path: pathRoute.Settings.Floors,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazySetupFloors />,
    authorization: [EScopesType.account_w],
  },
  {
    id: "Route_Relays",
    path: pathRoute.Elevator.Relays,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazySetupPanelRelaysOfElevator />,
    authorization: [EScopesType.account_w],
  },
];
///////////////
//the GeneralRoute are private routes, you must have a token
export const accessControlPortalRoutes: GeneralRoute[] = [
  ...commonRoutes,
  {
    id: "Route_Dashboard",
    path: pathRoute.Dashboard,
    exact: true,
    sideBarNavIcon: HomeIcon,
    textLangSidebarNav: "Dashboard",
    mainContentComponent: <LazyDashboard />,
    authorization: [],
    skipSideBarMenu: false,
  },
  {
    id: "Route_Users",
    path: pathRoute.Users.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Users",
    sideBarNavIcon: PersonIcon,
    mainContentComponent: <LazyUsers />,
    authorization: [EScopesType.user_r], //
  },
  {
    id: "Route_DetailUser",
    path: pathRoute.Users.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailUser />,
    authorization: [EScopesType.user_r],
  },
  {
    id: "Route_Doors",
    path: pathRoute.Doors.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Devices",
    sideBarNavIcon: AccessControlIcon,
    mainContentComponent: <LazyDoors />,
    authorization: [EScopesType.device_r],
  },
  {
    id: "Route_DetailDoor",
    path: pathRoute.Doors.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailDoor />,
    authorization: [EScopesType.device_r],
  },

  // {
  //   id: "Route_DoorVideo",
  //   path: pathRoute.Doors.Video,
  //   exact: true,
  //   skipSideBarMenu: true,
  //   mainContentComponent: <LazyDoorVideo />,
  //   authorization: [EScopesType.device_r],
  // },
  {
    id: "Route_Schedules",
    path: pathRoute.Schedules.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Schedules",
    sideBarNavIcon: ScheduleIcon,
    mainContentComponent: <LazySchedules />,
    authorization: [EScopesType.schedule_r],
  },
  {
    id: "Route_DetailSchedule",
    path: pathRoute.Schedules.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailSchedule />,
    authorization: [EScopesType.schedule_r],
  },
  {
    id: "Route_Report_AccessLogs",
    path: pathRoute.Reports.Accesslog,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyAccessLogsReport />,
    authorization: [EScopesType.accesslogs_r],
  },
  {
    id: "Route_Report_EventHistory",
    path: pathRoute.Reports.EventHistory,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyEventHistoryReport />,
    authorization: [EScopesType.accesslogs_r],
  },
  {
    id: "Route_Reports",
    path: pathRoute.Reports.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Reports",
    sideBarNavIcon: ListAltIcon,
    mainContentComponent: <LazyReports />,
    authorization: [EScopesType.accesslogs_r],
  },
  {
    id: "Route_DoorsReport",
    path: pathRoute.Reports.Door,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDoorReport />,
    authorization: [EScopesType.device_r],
  },
  {
    id: "Route_SitesReport",
    path: pathRoute.Reports.Site,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazySiteReport />,
    authorization: [EScopesType.site_r],
  },
  {
    id: "Route_SchedulesReport",
    path: pathRoute.Reports.Schedule,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyScheduleReport />,
    authorization: [EScopesType.schedule_r],
  },
  {
    id: "Route_VideoIntercom",
    path: pathRoute.VideoIntercom.Index,
    exact: true,
    sideBarNavIcon: DashboardOutlinedIcon,
    textLangSidebarNav: "VideoWall",
    mainContentComponent: <LazyVideoIntercom />,
    authorization: [EScopesType.devicesettings_w],
  },
  {
    id: "Route_Visitors",
    path: pathRoute.Visitors.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Visitors",
    sideBarNavIcon: TransferWithinAStationIcon,
    mainContentComponent: <LazyVisitors />,
    authorization: [EScopesType.user_r],
  },
  {
    id: "Route_DetailVisitor",
    path: pathRoute.Visitors.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailVisitor />,
    authorization: [EScopesType.user_r],
  },
  {
    id: "Route_Sensors",
    path: pathRoute.Sensor.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Sensors",
    sideBarNavIcon: SensorIcon,
    mainContentComponent: <LazySensors />,
    authorization: ["EScopesType.device_r"], //EScopesType.device_r
  },
  {
    id: "Route_DetailSensor",
    path: pathRoute.Sensor.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyDetailSensor />,
    authorization: ["EScopesType.device_r"],
  },
];
///////////////
export const camerasPortalRoutes: GeneralRoute[] = [
  ...commonRoutes,
  {
    id: "Route_Dashboard",
    path: pathRoute.Dashboard,
    exact: true,
    sideBarNavIcon: HomeIcon,
    textLangSidebarNav: "Dashboard",
    mainContentComponent: <LazyDashboardCameras />,
    authorization: [],
    skipSideBarMenu: false,
  },
  {
    id: "Route_Cameras",
    path: pathRoute.Cameras.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Cameras",
    sideBarNavIcon: DomCameraIcon,
    mainContentComponent: <LazyCameras />,
    keywordIcon: <LazyCameras />,
    authorization: [EScopesType.device_r],
  },
  {
    id: "Route_DetailedCamera",
    path: pathRoute.Cameras.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyCameraDetails />,
    authorization: [EScopesType.device_r],
  },
];

export const GatewayPortalRoutes: GeneralRoute[] = [
  ...commonRoutes,
  {
    id: "Route_Dashboard",
    path: pathRoute.Dashboard,
    exact: true,
    sideBarNavIcon: HomeIcon,
    textLangSidebarNav: "Dashboard",
    mainContentComponent: <LazyDashboardGateway />,
    authorization: [],
    skipSideBarMenu: false,
  },
  {
    id: "Route_AppliancesGateway",
    path: pathRoute.Appliances.Index,
    exact: true,
    skipSideBarMenu: false,
    textLangSidebarNav: "Appliances",
    sideBarNavIcon: RouterIcon,
    mainContentComponent: <LazyAppliancesGateway />,
    keywordIcon: <LazyAppliancesGateway />,
    authorization: [EScopesType.device_r],
  },
  {
    id: "Route_DetailedAppliances",
    path: pathRoute.Appliances.Details,
    exact: true,
    skipSideBarMenu: true,
    mainContentComponent: <LazyAppliancesDetails />,
    authorization: [EScopesType.device_r],
  },
];

export const generalPortalRoutes: PortalRoutes = {
  [PortalIds.AccessControl]: accessControlPortalRoutes,
  [PortalIds.Cameras]: camerasPortalRoutes,
  // [PortalIds.Sensors]: camerasPortalRoutes,
  [PortalIds.Gateway]: GatewayPortalRoutes,
};
