import { Moment } from "moment";
import { StatusType } from "./account";
import { SignalingClient } from "amazon-kinesis-video-streams-webrtc";

interface DeviceVM {
  serialNumber: string;
  accountId?: number;
  createdAt: string;
  status: StatusType;
  modifiedAt?: string;
  subscriptionGracePeriodEnd: string;
  databaseActive: boolean;
  name: string;
  deviceType: DeviceType;
  primaryZoneId?: number;
  secondaryZoneId?: number;
  //the endpoint will send this if the "includeSiteInfo" param is true
  siteId?: number;
  siteName?: string;
  primaryZoneName?: string;
  secondaryZoneName?: string;
  //////////////////
  isConnected?: boolean;
  doorState?: DoorStateType;
  lockState?: LockStateType;
  firmwareVersion?: string;
  identificationWorkflow?: number[][];
  model: string;
}

export interface DeviceModel extends DeviceVM {
  label?: string;
  value?: string;
  deviceSettings?: DoorSetting; //cloudkey is using this
  lastImage?: string;
  timeZoneDisplayName?: string;
  nextResyncTime?: string;
}

export enum DoorStateType {
  Closed = 0,
  OpenVerified = 1,
  OpenRequestToExit = 2,
  OpenForcedEntry = 3,
  OpenTimedOut = 4,
  NoSensor = 5,
}

export enum LockStateType {
  Locked = 0,
  Unlocked = 1,
  Lockdown = 2,
  KeepOpen = 3,
  UnlockedWithSchedule = 4,
  UnlockedRequestToExit = 5,
}
export enum DeviceType {
  Camera = 0,
  AccessControl = 1,
  TVCamera = 2,
  Gateway = 3,
  Intercoms = 4,
  ExternalLock = 5,
}
export enum DeviceModels {
  AlocityReader = "F3D100",
  TVS5L5M = "TVS5L5M",
  TVS5L8M = "TVS5L8M",
}
export interface AccessControlStateUpdateModel {
  source: SourceType;
  lockStateType: LockStateType;
  serialNumbers: string[];
}
export enum SourceType {
  web = 0,
  mobile = 1,
}

export interface DeviceNetworkSettingsModel {
  ipAddress?: string;
  netmask?: string;
  defaultGateway?: string;
  ipMode: NetworkIpModeType;
  dnsServer: string;
  wifi_ssid: string;
  wifi_key: string;
  activeSSID: string;
}
export enum NetworkIpModeType {
  Static = 0,
  DHCP = 1,
}

export interface AWSCredentialResponseModel {
  expiration: number; //unix time
  credential: AWSCredentialModel;
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  webRTCSettings: WebRTCIceServerSettingModel;
}

export interface WebRTCIceServerSettingModel {
  credential: string;
  urls: string[];
  username: string;
}

export interface AWSCredentialModel {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: number;
  region: string;
  bucketName?: string;
}

export enum CredentialType {
  Web = 0,
  Mobile = 1,
  CloudKey = 2,
  Face = 3,
  Card = 4,
  ExternalCard = 5,
  FaceOnCard = 6,
  PIN = 7,
  QR_CODE = 8,
}

interface AccessControlSettingVM {
  serialNumber: string;
  internalRelayEnabled: boolean;
  ioRelay1Enabled: boolean;
  ioRelay2Enabled: boolean;

  lockReleaseTime: number;
  doorOpenTimeout: number;
  doorContactPosition: EContactPositionType;
  doorContactType: EContactType;

  // exitButtonContactPosition1: EContactPositionType;
  // exitButtonContactType1: EContactType;

  wiegandFormat: EWiegandFormatType;
  wiegandInputEnabled: boolean;
  wiegandOutputEnabled: boolean;

  enableNFCTagRead: boolean;

  handsFreeEnabled: boolean; //enable open the door automatically using BLE

  doorContactSensorId: string;
  // doorExitButtonSensorId: string;
  doorRelaySensorIds: string[];

  exitButtons: ExitButtonModel[];
  outdoorDevice: boolean;
  deviceOsdpSettings: OsdpSettingsModel | null;
  deliveryEntry: boolean;
}

interface OsdpSettingsModel {
  current_key: string;
  default_key: string;
  baudrate: number;
  address: number;
  osdp_format: number;
  osdp_status: number;
}

export interface ExitButtonModel {
  exitButtonContactPosition: EContactPositionType;
  exitButtonContactType: EContactType;
  doorExitButtonSensorId: string;
}
export enum LivenessType {
  NA = 0,
  High = 3,
  Medium = 2,
  Low = 1,
}

interface FaceSettings {
  publishNonLiveUsers: boolean;
  publishUnknownUsers: boolean;
  publishAuthenticatedUsers: boolean;
  enrollFaceViaCard: boolean;
  enableLivenessCheck: boolean;
  livenessMode: LivenessType;
}

export enum EVideoResolutionType {
  Video1080 = 0,
  Video640 = 1,
}

export interface VideoSettings {
  videoDrawFocusArea: boolean;
  videoDrawFaceStats: boolean;
  videoResolution: EVideoResolutionType;
  publishVideoAuthenticatedUsers: boolean;
  publishVideoUnknownUsers: boolean;
  publishVideoNonLiveUsers: boolean;
  webRTCEnabled: boolean;
  webRTCAudioEnabled: boolean;
  zoomPercent: number;
  playbackMode: number;
  playbackAudioEnabled: boolean;
}

interface DeviceCombinedSettingsVM {
  faceSettings: FaceSettings;
  videoSettings: VideoSettings;
  sendPushNotifications: boolean;
}

export interface DoorSetting extends AccessControlSettingVM {}
export interface DeviceCombinedSettings extends DeviceCombinedSettingsVM {}

export enum EContactPositionType {
  None = 0,
  Internal = 1,
  External = 2,
  Zigbee = 3,
}

export enum EContactType {
  NormallyOpen = 0,
  NormallyClosed = 1,
}
export enum EWiegandFormatType {
  Wiegand26Bits = 0,
  Wiegand34Bits = 1,
  Wiegand37Bits = 2,
}

export enum BaudratesOSDP {
  B9600 = 9600,
  B19200 = 19200,
  B38400 = 38400,
  B115200 = 115200,
}

export enum OsdpStatus {
  Secure = 0,
  Online = 1,
  Offline = 2,
}

interface AudioSettingsVM {
  language: number;
  microphoneEnabled: boolean;
  speakerEnabled: boolean;
  volume: number;
  alarmEnabled: boolean;
  callVolume: number;
  doorBellEnabled: boolean;
}

export interface AudioSettingsModel extends AudioSettingsVM {}

interface DeviceOnWallVM {
  serialNumber: string;
  name: string;
  isConnected: boolean;
  doorState: number;
  lockState: number;
  lastImage: string;
}

export interface DeviceOnWallModel extends DeviceOnWallVM {}

export interface RTSPDeviceModel {
  isRtspEnabled: boolean;
  rtspUsername?: string;
  rtspPassword?: string;
}

///////////////////////////////////////////////////////////////
//Camera
export enum ObjectDetectionType {
  Person = 0,
  Vehicle = 1,
  Motion = 2,
  Face = 3,
  Object = 4,
  Animals = 5,
  Sound = 6,
}

// interface DeviceEventLogArtifactVM {
//   artifactId: string;
//   deviceEventLogId: string;
//   accountId: number;
//   serialNumber: string;
//   hasImage: boolean;
//   picture: string;
// }
// export interface DeviceEventLogArtifactModel extends DeviceEventLogArtifactVM {
//   //
// }
interface DeviceEventLogVM {
  picture: string; //base64
  detectionType: ObjectDetectionType;
  artifactId: string;
  artifactRegistrationTime: string; //note in ISO but without the "Z"
  name: string;
  serialNumber: string;
}
export interface DeviceEventLogModel extends DeviceEventLogVM {
  image?: string;
  crops?: string[];
  timeZoneOffset: string;
}

interface DeviceEventSettingVM {
  person_detection: boolean;
  vehicle_detection: boolean;
  motion_detection: boolean;
  pet_detection: boolean;
  face_detection: boolean;
}
export interface DeviceEventSettingModel extends DeviceEventSettingVM {
  //
}

export enum VideoQualityType {
  High = "high",
  Low = "low",
}
export interface SendDataChannelWebRTC {
  operation: OperationDataChannelType;
  selectedTime?: string;
  startTime?: string;
  endTime?: string;
  requestAudio?: boolean;
  seeking?: boolean; //to indicate seeking a value and the device does not send Acknowledge with the start time and end time
  quality?: VideoQualityType; // it is high in playback and low in live
  timestamp?: string; //to inform what is my actual time and make each message different

  segmentList?: string[]; // operation 1, 8
}
export interface ReceiveDataChannelWebRTC {
  operation: OperationDataChannelType;

  speaker: string;

  timestamp: string; //iso format  // in Playback to know what time is reproducing // in operation thumbnail timestamp is the picture time

  thumbnail: string;

  errorCode: string;

  startTime: string; // operation 7
  endTime: string;

  segmentList?: string[]; // operation 1, 9
}
export enum OperationDataChannelType {
  Live = 0, //audio // receive: audio => get the name // speaker = user
  Playback = 1, //selectedTime, starTime, endTime , segmentList
  Play = 2,
  Pause = 3,
  Stop = 4, // send in connection close
  Thumbnail = 5,
  Error = 6,
  Acknowledge = 7,

  StartUploadToCloud = 8, //segmentList
  UploadedToCloud = 9, //segmentList
}

export const ObjectDetectionTypePropertyName = ["Person", "Vehicle", "Pet", "Motion"];

interface AccountAIEventSettingsVM {
  person_detection: boolean;
  vehicle_detection: boolean;
  motion_detection: boolean;
  pet_detection: boolean;
}
export interface AccountAIEventSettingsModel extends AccountAIEventSettingsVM {
  //
}

export enum VideoPlayBackMode {
  NA = 0,
  SDLow = 2,
  CloudHigh = 3,
  CloudLow = 4,
}

export enum CameraModelWithAutoFocus {
  Model_TVS5L5M = "TVS5L5M",
  Model_TVS5L8M = "TVS5L8M",
}

export interface InfoToDownloadFromCloud {
  localPlaylist: string;
  signedSegments: string[];
  serialNumber: string;
  typeExtension: string;
  isReader: boolean;
}

export interface RTCPeerViewer {
  peerConnection: RTCPeerConnection | null;
  signalingClient: SignalingClient | null;
  serialNumber: string;
  dataChannel: RTCDataChannel | null;
}

interface VideoSourceVM {
  accountId: number;
  createdAt: string;
  isConnected: boolean;
  modified: string;
  name: string;
  serialNumber: string;
  status: StatusType;
  timeZoneDisplayName: string;
  stream_url: string;
  video_source_id: string;
}

export interface VideoSourceModel extends VideoSourceVM {}

export enum CameraModelWhithAudio {
  Model_TVS5L5MINI = "TVS5L5MINI",
  Model_TVCV22CORNER = "TVCV22CORNER",
  Model_TVCV22FISHEYE = "TVCV22FISHEYE",
}

interface RelaysPanelVM {
  id: string;
  serialNumber?: string;
  numberOfRelays?: number;
  relayReleaseTime?: number;
  address: number;
  isConnected?: boolean;
}

export interface RelaysPanelModel extends RelaysPanelVM {
  floorName?: string;
  floorId?: string | null;
  panelAddress?: number;
}
