import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  copyRightText: {
    fontSize: ".76rem",
    paddingBottom: "1em",
  },
}));

export default function Copyright() {
  const classes = useStyles();

  const version = process.env.REACT_APP_VERSION;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        display={"inline"}
        className={classes.copyRightText}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://alocity.com/">
          Alocity
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        display={"inline"}
        className={classes.copyRightText}
      >
        {" ver "}
        <Link
          href={process.env.REACT_APP_URL_REALEASE_NOTES_VERSION}
          target="_blank"
          rel="noopener noreferrer"
        >
          {version}
        </Link>
      </Typography>
    </div>
  );
}
