import React from "react";

// Material components
import { SvgIcon } from "@material-ui/core";

export default function AccessControlIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        d="M13.5 12.3982C13.5 7.45443 17.9145 3.6875 23 3.6875H37C42.0855 3.6875 46.5 7.45443 46.5 12.3982V41.1875V46.0625V49.2802C46.5 53.9766 41.9404 57.3125 37.0049 57.3125H22.9951C18.0596 57.3125 13.5 53.9766 13.5 49.2802V46.0625V41.1875V12.3982ZM41.8333 12.3982V41.1875H18.1667V12.3982C18.1667 10.2314 20.1966 8.18973 23 8.18973H37C39.8034 8.18973 41.8333 10.2314 41.8333 12.3982ZM41.8159 46.0625H18.1656V49.2802C18.1656 51.0254 20.0466 52.9516 22.9951 52.9516H36.9864C39.9349 52.9516 41.8159 51.0254 41.8159 49.2802V46.0625ZM30.2647 12.3125C25.9649 12.3125 22.5 15.8427 22.5 20.15C22.5 24.4573 25.9649 27.9875 30.2647 27.9875C34.5645 27.9875 38.0294 24.4573 38.0294 20.15C38.0294 15.8427 34.5645 12.3125 30.2647 12.3125ZM27.2086 20.15C27.2086 18.4078 28.5846 17.0409 30.2647 17.0409C31.9449 17.0409 33.3209 18.4078 33.3209 20.15C33.3209 21.8922 31.9449 23.2591 30.2647 23.2591C28.5846 23.2591 27.2086 21.8922 27.2086 20.15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
