import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import { passwordValidation } from "../../../Utils/Custom/validations";
import { useFormik } from "formik";
import LinearProgress from "@material-ui/core/LinearProgress";
import ConfirmationPasswordReseted from "./ConfirmationPasswordReseted";
import Copyright from "../../../Utils/Custom/Copyright";
import Card from "@material-ui/core/Card";
import { CardContent, IconButton, InputAdornment } from "@material-ui/core";
import { confirmResetPasswordAPI } from "../../../Utils/API/apiAuth";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CustomHelpIcon from "../../../Utils/Custom/CustomHelpIcon";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    margin: theme.spacing(1),
    objectFit: "contain",
    height: "100px",
    width: "200px",
  },
}));
interface Props {
  oobCode: string;
  emailPasswordToReset: string;
}
function ManageResetPassword({ oobCode, emailPasswordToReset }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openModalPasswordReseted, setOpenModalPasswordReseted] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirmNewPass = async (values: any) => {
    setLoading(true);
    await confirmResetPasswordAPI(oobCode, values.password)
      .then((response) => {
        if (!response.data.hasErrors) {
          setOpenModalPasswordReseted(true);
        }
      })
      .catch((error) => {
        //manage in the interceptor
      })
      .finally(() => {
        setLoading(false);
      });
    formik.resetForm();
  };

  const validate = (values: { password: string; repeatPassword: string }) => {
    const errors: any = {};
    if (passwordValidation(values.password)) {
      errors.password = passwordValidation(values.password);
    }
    if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "PasswordNotMatch";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validate,
    onSubmit: async (values: any) => {
      await confirmNewPass(values);
    },
    onReset: (values: any) => {
      //
    },
  });
  let emptyForm = true;
  if (formik.values.password && formik.values.repeatPassword) {
    emptyForm = false;
  }

  const [showPassword, setShowPassword] = useState(false);
  const [has8Characters, setHas8Characters] = useState(false);
  const [has1Number, setHas1Number] = useState(false);
  const [has1UpperCase, setHas1UpperCase] = useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        {openModalPasswordReseted ? (
          <ConfirmationPasswordReseted />
        ) : (
          <>
            <Card>
              <CardContent>
                <Grid container justify="center">
                  <img
                    src={"/images/alocity-logo.png"}
                    alt="Alocity logo"
                    className={classes.logo}
                  />

                  <Grid container alignItems="center">
                    <Typography component="h1" variant="h5">
                      {t("ResetPasswordText")}
                    </Typography>
                    <CustomHelpIcon title={t("InvalidPassword")} />
                  </Grid>

                  <Typography component="h1" variant="body1">
                    {emailPasswordToReset}
                  </Typography>

                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      color={has8Characters ? "primary" : "error"}
                      display="block"
                    >
                      {t("Contain8Characters")}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={has1UpperCase ? "primary" : "error"}
                      display="block"
                    >
                      {t("Contain1Uppercase")}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={has1Number ? "primary" : "error"}
                      display="block"
                    >
                      {t("Contain1Number")}
                    </Typography>
                  </Grid>

                  <form
                    className={classes.form}
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          // name="password"
                          label={t("Password")}
                          type={showPassword ? "text" : "password"}
                          id={"new-password"}
                          autoComplete="new-password"
                          name={"new-password"}
                          // {...formik.getFieldProps("password")}
                          value={formik.values.password}
                          onChange={(event) => {
                            const value = event.target.value;
                            formik.setFieldValue("password", value);
                            if (value.length >= 8) {
                              setHas8Characters(true);
                            } else {
                              setHas8Characters(false);
                            }
                            if (/^(?=.*\d).*$/m.test(value)) {
                              setHas1Number(true);
                            } else {
                              setHas1Number(false);
                            }
                            if (/^(?=.*[A-Z]).*$/m.test(value)) {
                              setHas1UpperCase(true);
                            } else {
                              setHas1UpperCase(false);
                            }
                          }}
                          onBlur={() => {
                            formik.setFieldTouched("password", true);
                          }}
                          disabled={formik.isSubmitting || loading}
                          error={formik.touched.password && formik.errors.password ? true : false}
                          helperText={
                            formik.touched.password && formik.errors.password
                              ? t(formik.errors.password as any)
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          autoComplete="new-password"
                          label={t("ConfirmPassword")}
                          type="password"
                          id="repeatPassword"
                          {...formik.getFieldProps("repeatPassword")}
                          disabled={formik.isSubmitting || loading}
                          error={
                            formik.touched.repeatPassword && formik.errors.repeatPassword
                              ? true
                              : false
                          }
                          helperText={
                            formik.touched.repeatPassword && formik.errors.repeatPassword
                              ? t(formik.errors.repeatPassword as any)
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={formik.isSubmitting || emptyForm || loading}
                    >
                      {t("Continue")}
                    </Button>
                  </form>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}
      </div>
      {loading ? <LinearProgress style={{ marginTop: "5px" }} /> : null}
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default ManageResetPassword;
