export const isActionsAllowed = (
  userScope: string[],
  rules: string[],
  callbackMissingRules?: (missingRules: string[]) => void
): boolean => {
  let counter: number = 0;
  const missingRules: string[] = [];

  if (!rules.length) {
    return true;
  }
  //return true if have all the rules inside the userScope
  for (let i = 0; i < rules.length; i++) {
    if (userScope.includes(rules[i])) {
      counter++;
    } else {
      missingRules.push(rules[i]);
    }
  }
  if (counter === rules.length) {
    return true;
  }
  callbackMissingRules && callbackMissingRules(missingRules);
  return false;
};

export const hasSomeAuthorization = (routeAutorization: string[], scope: string[]): boolean => {
  let equal = false;
  if (routeAutorization.length === 0) {
    //this allow any route that have empty autorization
    return true;
  } else {
    //it is authorized to enter to the route if contain at least 1 scope
    for (let i = 0; i < routeAutorization.length; i++) {
      if (scope.findIndex((rule: string) => rule === routeAutorization[i]) > -1) {
        equal = true;
        break;
      }
    }
  }
  return equal;
};
