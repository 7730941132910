import { InfoToDownloadFromCloud } from "../../components/Utils/Models/devices";
import StoreStateModel from "../../components/Utils/Models/store";
import * as actionTypes from "./actionTypes";

export const showLoadingComponent = (activate: boolean) => {
  return {
    type: actionTypes.SHOW_LOADING_COMPONENT,
    activate,
  };
};

export const showErrorInterceptor = (errorCode: string) => {
  return {
    type: actionTypes.SHOW_ERROR_TEXT_INTERCEPTOR,
    errorCode,
  };
};

export const changePortal = (portalId: string) => {
  return {
    type: actionTypes.CHANGE_PORTAL,
    portalId,
  };
};

export const resetGeneralStoreValues = () => {
  return {
    type: actionTypes.RESET_GENERALSTORE_VALUES,
  };
};

export const addDeviceActiveWebRTCConnection = (serialNumber: string) => {
  return {
    type: actionTypes.ADD_DEVICE_WEBRTC_ACTIVE_CONNECTION,
    serialNumber,
  };
};

export const deleteDeviceActiveWebRTCConnection = (serialNumber: string) => {
  return {
    type: actionTypes.DELETE_DEVICE_WEBRTC_ACTIVE_CONNECTION,
    serialNumber,
  };
};

export const updateStatusGeneralLockdown = (
  isActive: boolean,
  startTime: string,
  durationInMinutes: number,
  isLockdownPlanSetUp: boolean
) => {
  //duration = 0 =>is infinite
  return {
    type: actionTypes.IS_ACTIVE_GENERAL_LOCKDOWN,
    isGeneralLockdownActivated: isActive,
    generalLockdownStartTime: startTime,
    generalLockdownDuration: durationInMinutes,
    isLockdownPlanSetUp: isLockdownPlanSetUp,
  };
};

export const isLockdownPlanSetUp = (value: boolean) => {
  return {
    type: actionTypes.IS_LOCKDOWN_PLAN_SETUP,
    value,
  };
};

export const updateDidCallAPILockdownInfo = (value: boolean) => {
  return {
    type: actionTypes.DID_CALL_API_LOCKDOWN_INFO,
    value,
  };
};

export const updateLiveStringFromDevice = (value: MediaStream) => {
  return {
    type: actionTypes.LIVE_STRING_FROM_DEVICE,
    value,
  };
};

export const downloadVideoFromCloud = (value?: InfoToDownloadFromCloud) => {
  return {
    type: actionTypes.DOWNLOAD_VIDEO_FROM_CLOUD,
    value,
  };
};

export const updateDownloadVideoPercent = (value: number) => {
  return {
    type: actionTypes.DOWNLOAD_VIDEO_FROM_CLOUD_PERCENT,
    value,
  };
};
