import { isArray, isObject, isString } from "util";
import { EmailValidation, PhoneNumberValidation } from "../Constants/validationValues";
// import parsePhoneNumber from "libphonenumber-js";
import { parsePhoneNumber } from "react-phone-number-input";
const hasLength = (value: string) => {
  if (isString(value)) {
    if (value.trim().length > 0) {
      return true;
    }
  }
  if (isArray(value)) {
    if (value.length > 0) {
      return true;
    }
  } else if (isObject(value)) {
    const temp = Object.values(value);
    if (temp.length > 0) {
      return true;
    }
  }
  return false;
};
const hasMaxLength = (value: string, maxLength: number) => {
  if (value.length <= maxLength) {
    return true;
  }
  return false;
};
const hasMinLength = (value: string, minLength: number) => {
  if (value.length >= minLength) {
    return true;
  }
  return false;
};
const isValidEmail = (value: string) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return true;
  }
  return false;
};
const isValidURL = (value: string) => {
  if (
    // eslint-disable-next-line no-useless-escape
    /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/m.test(
      value
    )
  ) {
    return true;
  }
  return false;
};
const isStrongPassword = (value: string) => {
  if (/^(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/m.test(value)) {
    return true;
  }
  return false;
};
// Phone number check
const isValidPhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumber(value);

  if (phoneNumber?.isValid()) {
    return true;
  }
  return false;
};

const isPhoneNumberCodeValid = (value: string, code: string) => {
  const phoneNumber = parsePhoneNumber(value);

  if (phoneNumber?.country === code) {
    return true;
  }
  return false;
};

/////////////////////////////////////////////
export const emailValidation = (value: string) => {
  if (!hasLength(value)) {
    return "Required";
  }
  // else if (!hasMinLength(value, EmailValidation.minChar)) {
  //   return "ShortText";
  // } else if (!hasMaxLength(value, EmailValidation.maxChar)) {
  //   return "LongText";
  // } else if (!isValidEmail(value)) {
  //   return "InvalidEmail";
  // } else {
  return null;
  // }
};
export const textValidation = (
  value: string,
  minLength: number,
  maxLength: number,
  isRequired: boolean = true
) => {
  if (isRequired && !hasLength(value)) {
    return "Required";
  } else if (!hasMinLength(value, minLength)) {
    return "ShortText";
  } else if (!hasMaxLength(value, maxLength)) {
    return "LongText";
  } else {
    return null;
  }
};
export const reactSelectValidation = (value: any) => {
  if (!hasLength(value)) {
    return "Required";
  } else {
    return null;
  }
};
export const urlValidation = (value: string, isRequired: boolean = true) => {
  if (isRequired && !hasLength(value)) {
    return "Required";
  } else if (isRequired && !hasMinLength(value, 2)) {
    return "ShortText";
  } else if (!hasMaxLength(value, 200)) {
    return "LongText";
  } else if (value.length && !isValidURL(value)) {
    return "InvalidURL";
  } else {
    return null;
  }
};

export const passwordValidation = (value: string) => {
  if (!hasLength(value)) {
    return "Required";
  } else if (!hasMinLength(value, 8)) {
    return "ShortPassword";
  } else if (!hasMaxLength(value, 50)) {
    return "LongText";
  } else if (value.length && !isStrongPassword(value)) {
    return "InvalidPassword";
  } else {
    return null;
  }
};

export const languageValidation = (value: string) => {
  if (!hasLength(value)) {
    return "Required";
  } else {
    return null;
  }
};

export const ipAddressValidation = (
  value: string,
  validateNotEndInZero: boolean = false,
  isRequired: boolean = true,
  firstValuegreaterThan?: number
) => {
  if (!value) {
    return "Required";
  }
  if (isRequired && value && !value.length) {
    return "Required";
  }
  const subips: string[] = value.split(".");
  if (
    isRequired &&
    subips[0].length === 0 &&
    subips[1].length === 0 &&
    subips[2].length === 0 &&
    subips[3].length === 0
  ) {
    return "Required";
  } else if (
    !(subips[0].length > 0 && subips[1].length > 0 && subips[2].length > 0 && subips[3].length > 0)
  ) {
    if (!isRequired && (value === "..." || value === "")) {
      return null;
    }
    return "Incomplete";
  } else if (
    !(
      parseInt(subips[0]) > 0 &&
      parseInt(subips[0]) < 256 &&
      parseInt(subips[1]) >= 0 &&
      parseInt(subips[1]) < 256 &&
      parseInt(subips[2]) >= 0 &&
      parseInt(subips[2]) < 256 &&
      parseInt(subips[3]) >= 0 &&
      parseInt(subips[3]) < 256
    )
  ) {
    if (!isRequired && (value === "..." || value === "")) {
      return null;
    }
    return "Error";
  } else if (validateNotEndInZero && parseInt(subips[3]) === 0) {
    return "TheLastNumberCannotByZero";
  } else if (firstValuegreaterThan && parseInt(subips[0]) < firstValuegreaterThan) {
    return "TheFirstNumberGreaterThan128";
  } else {
    return null;
  }
};
// Phone Number Validation
export const phoneNumberValidation = (value: string) => {
  if (!isValidPhoneNumber(value)) {
    return "InvalidPhoneNumber";
  } else {
    let counterError = false;
    for (let i = 0; i < PhoneNumberValidation.supportedCountries.length; i++) {
      if (
        PhoneNumberValidation.supportedCountries.length &&
        isPhoneNumberCodeValid(value, PhoneNumberValidation.supportedCountries[i].toUpperCase())
      ) {
        counterError = false;
        break;
      } else {
        counterError = true;
      }
    }
    if (counterError) {
      return "UnsupportedCountryCode";
    } else {
      return null;
    }
  }
};

// Card Id Validation

export const cardNumberValidation = (value: number) => {
  if (!value) {
    return "Required";
  } else {
    return null;
  }
};

// Facility Code Validation

export const facilityCodeValidation = (value: number) => {
  if (!value) {
    return "Required";
  } else {
    return null;
  }
};
