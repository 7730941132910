import React from "react";
import { useState, useRef, useEffect } from "react";
//
interface Props {
  localPlaylist: string;
  signedSegments: string[]; //the first one is the init.mp4
  onSuccessCallback: (payload: any, isFinal: boolean, downloadTitle: string) => void;
  typeExtension: string;
  isReader: boolean;
  serialNumber: string;
}

const DownloadVideoFromCloud = ({
  signedSegments,
  localPlaylist,
  onSuccessCallback,
  typeExtension,
  isReader,
  serialNumber,
}: Props) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const prefix = "INT";
  const isIntercom = serialNumber.split("-")[1].startsWith(prefix);

  const iframeRef = useRef<any>(null);
  useEffect(() => {
    if (isIframeLoaded && iframeRef.current) {
      const data = {
        signedSegments: signedSegments,
        localPlaylist: localPlaylist,
        typeExtension: typeExtension,
        env: {
          REACT_APP_CloudStorange_BlackScreen20S_1:
            typeExtension === "m4s"
              ? isReader
                ? process.env.REACT_APP_CloudStorange_BlackScreenReader8S_1_M4S?.split("/")[5]
                : process.env.REACT_APP_CloudStorange_BlackScreenCamera20S_1_M4S?.split("/")[5]
              : isReader
              ? isIntercom
                ? process.env.REACT_APP_CloudStorange_BlackScreenIntercom8S_1_TS?.split("/")[5]
                : process.env.REACT_APP_CloudStorange_BlackScreenReader8S_1_TS?.split("/")[5]
              : process.env.REACT_APP_CloudStorange_BlackScreenCamera20S_1_TS?.split("/")[5],
          REACT_APP_CloudStorange_BlackScreen20S_2:
            typeExtension === "m4s"
              ? isReader
                ? process.env.REACT_APP_CloudStorange_BlackScreenReader8S_2_M4S?.split("/")[5]
                : process.env.REACT_APP_CloudStorange_BlackScreenCamera20S_2_M4S?.split("/")[5]
              : "",
          REACT_APP_CloudStorange_BlackScreen1S_1:
            typeExtension === "m4s"
              ? isReader
                ? process.env.REACT_APP_CloudStorange_BlackScreenReader1S_1_M4S?.split("/")[5]
                : process.env.REACT_APP_CloudStorange_BlackScreenCamera1S_1_M4S?.split("/")[5]
              : isReader
              ? isIntercom
                ? process.env.REACT_APP_CloudStorange_BlackScreenIntercom1S_1_TS?.split("/")[5]
                : process.env.REACT_APP_CloudStorange_BlackScreenReader1S_1_TS?.split("/")[5]
              : process.env.REACT_APP_CloudStorange_BlackScreenCamera1S_1_TS?.split("/")[5],
          REACT_APP_CloudStorange_BlackScreen1S_2:
            typeExtension === "m4s"
              ? isReader
                ? process.env.REACT_APP_CloudStorange_BlackScreenReader1S_2_M4S?.split("/")[5]
                : process.env.REACT_APP_CloudStorange_BlackScreenCamera1S_2_M4S?.split("/")[5]
              : "",
          REACT_APP_PortalWebsite: process.env.REACT_APP_PortalWebsite,
        },
      };
      iframeRef.current?.contentWindow.postMessage(data, window.location.origin);
    }
  }, [isIframeLoaded]);

  const messageFromIFrame = (event: any) => {
    const data: any = event.data;
    if (data.source === "myiFrame" && process.env.REACT_APP_PortalWebsite === event.origin) {
      onSuccessCallback(data.payload, data.isFinal, data.title);
    }
  };
  useEffect(() => {
    window.addEventListener("message", messageFromIFrame);

    return () => {
      window.removeEventListener("message", messageFromIFrame);
    };
  }, []);

  const content = `<html>
  <head>
    <script src="/assets/ffmpeg/package/dist/umd/ffmpeg.js"></script>
    <script src="/assets/util/package/dist/umd/index.js"></script>
    
  </head>
  <body>
 
    <script>

    function sendMessageToParent(messageData, isFinal,title) {
      const data = {
        payload: messageData,
        source: "myiFrame",
        isFinal: isFinal,
        title: title
      }
      window.parent.postMessage(data, "*");
    }
      const { fetchFile } = FFmpegUtil;
      const { FFmpeg } = FFmpegWASM;
      let ffmpeg = null;
      let totalPercent = 0
      
      let firstTime = "";
      const transcode = async (data) => {

        if (ffmpeg === null) {
          ffmpeg = new FFmpeg();
          // ffmpeg.on("log", ({ message }) => {
          //   console.log(message);
          // })
          ffmpeg.on("progress", ({ progress, time }) => {
            if(progress>totalPercent){
             
              sendMessageToParent(progress * 100, false,"")
            }
          });
          await ffmpeg.load({
            coreURL: "/assets/core/package/dist/umd/ffmpeg-core.js",
          });
        }
      
       const playlistBlob = new Blob([data.localPlaylist], { type: 'text/plain' });
       const localPlaylistUrl = URL.createObjectURL(playlistBlob);
       await ffmpeg.writeFile("playlist.m3u8", await fetchFile(localPlaylistUrl));

       if(data.typeExtension == "m4s"){
         await ffmpeg.writeFile("init.mp4", await fetchFile(data.signedSegments[0]));
       }
       let blackScreen20S_1 = null
       let blackScreen20S_2 = null
       let blackScreen1S_1 = null
       let blackScreen1S_2 = null

       for (let i=0;i<data.signedSegments.length;i++) {
          totalPercent = ((i)/data.signedSegments.length);
          
          sendMessageToParent(Math.floor(totalPercent*100),false,"")


          const temp = data.signedSegments[i].split("/")
          const nameWithQueries = temp[temp.length-1]
          const name = nameWithQueries.split("?")[0]

          if(name ==data.env.REACT_APP_CloudStorange_BlackScreen20S_1){
            if(!blackScreen20S_1){
              await ffmpeg.writeFile(name, await fetchFile(data.signedSegments[i]));
              blackScreen20S_1 = true;//await ffmpeg.readFile(name);
            }
          }
          else if(name ==data.env.REACT_APP_CloudStorange_BlackScreen20S_2){
            if(!blackScreen20S_2){
              await ffmpeg.writeFile(name, await fetchFile(data.signedSegments[i]));
              blackScreen20S_2 = true;//await ffmpeg.readFile(name);
            }
          }
          else if(name ==data.env.REACT_APP_CloudStorange_BlackScreen1S_1){
            if(!blackScreen1S_1){
              await ffmpeg.writeFile(name, await fetchFile(data.signedSegments[i]));
              blackScreen1S_1 =true;//await ffmpeg.readFile(name);
            }
          }
          else if(name ==data.env.REACT_APP_CloudStorange_BlackScreen1S_2){
            if(!blackScreen1S_2){
              await ffmpeg.writeFile(name, await fetchFile(data.signedSegments[i]));
              blackScreen1S_2 = true;//await ffmpeg.readFile(name);
            }
          }else{
            const decodedName = decodeURIComponent(name);
            if(i== (data.typeExtension == "m4s" ? 1 : 0)){
              firstTime=decodedName.split(".")[0].replaceAll("-", "").replaceAll(":","");
            }
            const temp = decodedName.split(".")[0].replaceAll("-", "").replaceAll(":","")+ (data.typeExtension == "m4s" ? ".m4s" : ".ts");
          
            await ffmpeg.writeFile(temp, await fetchFile(data.signedSegments[i]));
          }
        }
        
        await ffmpeg.exec(["-i", "playlist.m3u8", "-c", "copy", "output.mp4"]);
        const fileData = await ffmpeg.readFile('output.mp4');
        const context = new Uint8Array(fileData);
        const url = URL.createObjectURL(new Blob([context.buffer], { type: "video/mp4" }));
        sendMessageToParent(url, true, "video_"+firstTime+".mp4");
        
      }
      function handleMessage(event) {
        if (event.data) {
          if (event.origin == event.data.env.REACT_APP_PortalWebsite){
            const data = event.data;
            transcode(data);
          }
        }
      }
      // Attach the message event listener
      window.addEventListener('message', handleMessage);
     
    </script>
  
    
  </body>
</html>`;

  return (
    <div>
      <iframe
        ref={iframeRef}
        srcDoc={content}
        style={{ display: "none" }}
        title="Download page"
        onLoad={() => {
          // The iframe has loaded, you can now send messages
          setIsIframeLoaded(true);
        }}
      ></iframe>
    </div>
  );
};
export default DownloadVideoFromCloud;
