import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successColor: {
      color: theme.palette.success.dark,
    },
    spaceTop: { marginTop: theme.spacing(2) },
    spacingRight: { marginRight: theme.spacing(1) },
  })
);

enum ProvisioningKeyStatus {
  Inactive = 0,
  Active = 1,
  Success = 2,
  Error = 3,
  MaxPinAttempts = 4,
}
interface Props {
  status: ProvisioningKeyStatus;
  keyType: "provisioningFace";
}

const ProvisioningFaceStatusCard = ({ status, keyType }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const messagesType = {
    provisioningFace: {
      success: t("ProvisioningKeySuccess"),
      inactive: t("TheLinkIsInvalid"),
      error: t("ProvisioningKeyFail"),
      maxPinAttempts: t("error_provisioning_max_pin_attempts"),
    },
  };

  const postIconMessageType = {
    provisioningFace: {
      success: t("SelfieEnrollReady"),
      inactive: "",
      error: "",
      maxPinAttempts: "",
    },
  };

  let message = "";
  let postIconMessage = "";

  switch (status) {
    case ProvisioningKeyStatus.Success:
      message = messagesType[keyType].success;
      postIconMessage = postIconMessageType[keyType].success;
      break;
    case ProvisioningKeyStatus.Inactive:
      message = messagesType[keyType].inactive;
      break;
    case ProvisioningKeyStatus.MaxPinAttempts:
      message = messagesType[keyType].maxPinAttempts;
      break;
    default:
      /* Error */
      message = messagesType[keyType].error;
      break;
  }

  return (
    <>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item className={classes.spaceTop}>
          <Typography style={{ textAlign: "center" }}>{message}</Typography>
        </Grid>
        <Grid item className={classes.spaceTop}>
          {status === ProvisioningKeyStatus.Success ? (
            <CheckCircleOutlineIcon style={{ fontSize: 100 }} className={classes.successColor} />
          ) : (
            <NotInterestedIcon style={{ fontSize: 100 }} color="error" />
          )}
        </Grid>
        <Grid item className={classes.spaceTop}>
          <Typography style={{ textAlign: "center" }}>{postIconMessage}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ProvisioningFaceStatusCard;
