import { axiosCall, axiosInstance } from "./axiosConfig";
import { QueryFilter, axiosMethods, endPoints } from "../Models/api";
import { WorkflowModel } from "../Models/workflow";
import { CatalogEntryModel } from "../Models/catalog";

export const getCatalogs = (filter: QueryFilter) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/catalog/paged",
    method: axiosMethods.get,
    params: filter,
  });
};
export const deleteCatalog = (ids: string[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/catalog",
    method: axiosMethods.delete,
    data: ids,
  });
};
export const addNewCatalog = (data: CatalogEntryModel[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/catalog",
    method: axiosMethods.post,
    data: data,
  });
};

export const editCatalog = (data: CatalogEntryModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/catalog",
    method: axiosMethods.put,
    data: data,
  });
};

export const getCatalogFromDevice = (serialNumber: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/device-catalog",
    method: axiosMethods.get,
    params: {
      serialNumber,
    },
  });
};
export const deviceCallRequest = (serialNumber: string, userId: string | undefined) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/device-call-request",
    method: axiosMethods.post,
    data: {
      serialNumber,
      userId,
    },
  });
};
