import React from "react";

// Material components
import { SvgIcon } from "@material-ui/core";

export default function SensorIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 27.4998C28.6193 27.4998 27.5 28.619 27.5 29.9998C27.5 31.3805 28.6193 32.4998 30 32.4998C31.3807 32.4998 32.5 31.3805 32.5 29.9998C32.5 28.619 31.3807 27.4998 30 27.4998ZM22.5 29.9998C22.5 25.8576 25.8579 22.4998 30 22.4998C34.1421 22.4998 37.5 25.8576 37.5 29.9998C37.5 34.1419 34.1421 37.4998 30 37.4998C25.8579 37.4998 22.5 34.1419 22.5 29.9998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.093 10.232C15.0695 11.2081 15.0697 12.791 14.0936 13.7675C9.87545 17.9869 7.50586 23.7088 7.50586 29.675C7.50586 35.6412 9.87545 41.3631 14.0936 45.5825C15.0697 46.559 15.0695 48.1419 14.093 49.118C13.1166 50.0942 11.5336 50.094 10.5575 49.1175C5.40203 43.9605 2.50586 36.967 2.50586 29.675C2.50586 22.383 5.40203 15.3895 10.5575 10.2325C11.5336 9.25604 13.1166 9.2558 14.093 10.232ZM45.908 10.232C46.8845 9.2558 48.4674 9.25604 49.4435 10.2325C54.599 15.3895 57.4952 22.383 57.4952 29.675C57.4952 36.967 54.599 43.9605 49.4435 49.1175C48.4674 50.094 46.8845 50.0942 45.908 49.118C44.9316 48.1419 44.9313 46.559 45.9075 45.5825C50.1256 41.3631 52.4952 35.6412 52.4952 29.675C52.4952 23.7088 50.1256 17.9869 45.9075 13.7675C44.9313 12.791 44.9316 11.2081 45.908 10.232ZM21.1693 17.2832C22.145 18.2601 22.1442 19.843 21.1673 20.8188C20.0051 21.9797 19.0831 23.3583 18.4541 24.8757C17.825 26.3932 17.5012 28.0198 17.5012 29.6625C17.5012 31.3052 17.825 32.9318 18.4541 34.4493C19.0831 35.9667 20.0051 37.3453 21.1673 38.5063C22.1442 39.482 22.145 41.0649 21.1693 42.0418C20.1935 43.0186 18.6106 43.0195 17.6337 42.0438C16.0066 40.4185 14.7159 38.4884 13.8352 36.364C12.9545 34.2395 12.5012 31.9623 12.5012 29.6625C12.5012 27.3627 12.9545 25.0855 13.8352 22.961C14.7159 20.8366 16.0066 18.9065 17.6337 17.2813C18.6106 16.3055 20.1935 16.3064 21.1693 17.2832ZM38.8318 17.3082C39.8075 16.3314 41.3904 16.3305 42.3673 17.3063C43.9944 18.9315 45.2852 20.8616 46.1658 22.986C47.0465 25.1105 47.4998 27.3877 47.4998 29.6875C47.4998 31.9873 47.0465 34.2645 46.1658 36.389C45.2852 38.5134 43.9944 40.4435 42.3673 42.0688C41.3904 43.0445 39.8075 43.0436 38.8318 42.0668C37.856 41.0899 37.8569 39.507 38.8337 38.5313C39.9959 37.3703 40.9179 35.9917 41.547 34.4743C42.176 32.9568 42.4998 31.3302 42.4998 29.6875C42.4998 28.0448 42.176 26.4182 41.547 24.9007C40.9179 23.3833 39.9959 22.0047 38.8337 20.8438C37.8569 19.868 37.856 18.2851 38.8318 17.3082Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
