import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import { CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    card: {
      maxWidth: 545,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
    cover: {},
    title: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

export default function Page403() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid container className={classes.root}>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Grid item className={classes.title}>
              <Typography gutterBottom variant="h5" component="h2" display="initial">
                {t("ForbiddenAccess")}
              </Typography>
            </Grid>
            <Grid item className={classes.content}>
              <Typography variant="body2" color="textSecondary">
                {t("ForbiddenAccessSubtitle")}
              </Typography>
            </Grid>

            <Grid item className={classes.content}>
              <Typography variant="body2" color="textSecondary">
                {t("ForbiddenAccessSubtitle2")}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
