import { CircularProgress, Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import DatePicker from "react-datepicker";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import es from "date-fns/locale/es";
import { AWSCredentialResponseModel } from "../../../Models/devices";
import axios from "axios";
import { getCloudVideoCredential } from "../../../API/apiAuth";
import moment, { Moment } from "moment";
import { ClientConfiguration, GetObjectOutput } from "aws-sdk/clients/s3";
import AWS from "aws-sdk";
import { shallowEqual, useSelector } from "react-redux";
import StoreStateModel from "../../../Models/store";
import styles from "./CalendarFromPlaybackCloud.module.css";
import { getCloudStorangeImage } from "../../../API/apiDevices";
import Images from "../../../Constants/images";
import { ArrowForward } from "@material-ui/icons";

interface Props {
  startDate: string;
  serialNumber: string;
  onSelected: (date: string) => void;
}
interface TimePathImageRelation {
  time: string;
  pathname: string;
}

interface ObjectDictionary {
  [key: string]: string[];
}

const CalendarFromPlaybackCloud = ({ startDate, serialNumber, onSelected }: Props) => {
  const { t, i18n } = useTranslation();
  const positionOfVerticalLine = useRef(0);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const date = new Date(startDate);
  const dateWithZeroTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0, // hours
    0, // minutes
    0, // seconds
    0 // milliseconds
  );
  const [dateCalendar, setDateCalendar] = useState<Date>(dateWithZeroTime);
  const [credentialCloudStorange, setCredentialCloudStorange] = useState<
    AWSCredentialResponseModel | undefined
  >();
  const [dateCalendarMoment, setDateCalendarMoment] = useState<Moment>(
    moment(startDate).startOf("days")
  );
  const refDateTimesInCloud = useRef<TimePathImageRelation[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEndedReadFromCloud, setIsEndedReadFromCloud] = useState(false);
  const accountId = useSelector((state: StoreStateModel) => state.auth.accountId, shallowEqual);
  const refAllDateInCloud = useRef<ObjectDictionary | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();
  const refBarVerticalLine = useRef<HTMLDivElement>(null);
  const refBarTimeline = useRef<HTMLDivElement>(null);
  const [aspectRatio, setAspectRatio] = useState<number | null>(null);
  const refEquidistantDates = useRef<string[]>([]);
  const refFirstImage = useRef<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment(startDate).startOf("day"));

  const [rangeHoursOfTheDay, setRangeHoursOfTheDay] = useState([
    moment(dateCalendar).format("MM D"),
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    moment(dateCalendar).add(1, "days").format("MM D"),
  ]);

  //modify the hours change
  useEffect(() => {
    setRangeHoursOfTheDay((prev) => {
      const temp = [...prev];
      temp[0] = moment(dateCalendar).format("MMM D");
      temp[temp.length - 1] = moment(dateCalendar).add(1, "days").format("MMM D");
      return temp;
    });
    //TODO: avoid show hours in the future
  }, [dateCalendar]);
  //get the credentials
  useEffect(() => {
    const source = axios.CancelToken.source();
    const loadCredentialsStorange = async () => {
      try {
        setLoading(true);
        const response = await getCloudVideoCredential(source.token);
        if (!response.data.hasErrors) {
          setCredentialCloudStorange(response.data.payload);
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    };
    loadCredentialsStorange();
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const now = moment();
      const startDate = dateCalendarMoment?.clone().startOf("day").toISOString();
      let endDate = dateCalendarMoment?.clone().endOf("day").milliseconds(0).toISOString();

      if (dateCalendarMoment?.isSame(now, "day")) {
        endDate = now.milliseconds(0).toISOString();
      }
      refDateTimesInCloud.current = [];
      setErrorMessage("");
      setIsEndedReadFromCloud(false);

      if (startDate && endDate) {
        if (
          refAllDateInCloud.current &&
          refAllDateInCloud.current[dateCalendarMoment.toISOString().split("T")[0]]
        ) {
          setIsEndedReadFromCloud(true);
        } else {
          listImages("", startDate, endDate);
        }
      }
    };

    if (credentialCloudStorange && dateCalendarMoment) {
      load();
    }
  }, [credentialCloudStorange, dateCalendarMoment]);

  const listImages = (marker: string, startDate: string, endDate: string) => {
    const awsConfig: ClientConfiguration = {
      region: credentialCloudStorange?.credential.region,
      credentials: {
        accessKeyId: credentialCloudStorange?.credential.accessKeyId as string,
        secretAccessKey: credentialCloudStorange?.credential.secretAccessKey as string,
        sessionToken: credentialCloudStorange?.credential.sessionToken,
      },
      endpoint: process.env.REACT_APP_CloudStorange_URL,
    };
    const s3 = new AWS.S3(awsConfig);
    let timePrefix = "";
    const temp = startDate.split("T");
    //split T => //['2023-09-01', '18:00:20.000Z']
    //split : => ['2023-09-01T18', '00','20.000Z']
    timePrefix = temp[0];
    const folderPath = accountId + "/" + serialNumber + "/images/";

    const params = {
      Bucket:
        credentialCloudStorange?.credential.bucketName ||
        (process.env.REACT_APP_CloudStorange_BucketName as string),
      Prefix: folderPath + timePrefix,
      Marker: marker,
    };

    s3.listObjects(params, (err, data) => {
      if (err) {
        setErrorMessage(t("Error"));
        setLoading(false);
      } else {
        // 4/000558734115-11101985/images/2023-09-12T18:00:00:000Z.jpg
        if (data?.Contents && data?.Contents?.length) {
          const paths: TimePathImageRelation[] = [];
          data.Contents.forEach((value) => {
            const path: string = value.Key as string;
            const time = path.split("/")[path.split("/").length - 1].split(".")[0] + ".000Z";

            if (moment(time).isBetween(moment(startDate), moment(endDate), undefined, "[]")) {
              paths.push({
                time: time,
                pathname: path,
              });
            }
          });
          refDateTimesInCloud.current = refDateTimesInCloud.current.concat(paths);
        }

        // If there are more objects to paginate, call listObjects again with a new marker
        const contents: any = data?.Contents;
        const path: any = contents.length && contents[contents.length - 1]?.Key;
        const time = path && path?.split("/")[path.split("/").length - 1].split(".")[0] + ".000Z";
        if (contents.length && moment(time).isBefore(moment(endDate))) {
          if (data.IsTruncated) {
            const contents: any = data?.Contents;
            const temp: any = contents[contents.length - 1];
            listImages(temp?.Key || "", startDate, endDate);
          } else {
            const tempStart =
              moment(startDate)
                .add(1, "day")
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .format("yyyy-MM-DDTHH:mm:ss") + "Z";
            const contents: any = data?.Contents;
            const tempMarker: any = contents[contents.length - 1];
            listImages(tempMarker?.Key || "", tempStart, endDate);
          }
        } else {
          if (refDateTimesInCloud.current.length) {
            const tempListOfTime = [];
            for (let index = 0; index < refDateTimesInCloud.current.length; index++) {
              tempListOfTime.push(refDateTimesInCloud.current[index].time);
            }
            if (!refAllDateInCloud.current) {
              refAllDateInCloud.current = {};
            }
            refAllDateInCloud.current[tempListOfTime[0].split("T")[0]] = tempListOfTime;
          } else {
            const date = moment(dateCalendar).toISOString().split("T")[0];
            if (!refAllDateInCloud.current) {
              refAllDateInCloud.current = {};
            }
            refAllDateInCloud.current[date] = [];
          }
          setIsEndedReadFromCloud(true);
        }
      }
    });
  };
  //show the images in the container
  useEffect(() => {
    const load = async () => {
      if (
        refAllDateInCloud.current &&
        refAllDateInCloud.current[dateCalendarMoment.toISOString().split("T")[0]] &&
        refAllDateInCloud.current[dateCalendarMoment.toISOString().split("T")[0]].length
      ) {
        const listTimesOfTheDay =
          refAllDateInCloud.current[dateCalendarMoment.toISOString().split("T")[0]];
        const firstTime = listTimesOfTheDay[0];
        try {
          const tempImageBase64 = await getTheBase64Image(
            firstTime,
            credentialCloudStorange as AWSCredentialResponseModel
          );
          if (tempImageBase64?.length) {
            const img = new Image();
            img.src = tempImageBase64;

            img.onload = () => {
              const width = img.width;
              const height = img.height;
              const newAspectRatio = width / height;

              setAspectRatio(newAspectRatio);
            };
            setImageUrl(tempImageBase64);
            refFirstImage.current = tempImageBase64;
          }
        } catch (error) {
          setImageUrl(undefined);
          refFirstImage.current = Images.ALOCITY_IMAGE_CAMERA;
        }

        if (listTimesOfTheDay.length > 1) {
          const count = Math.floor(listTimesOfTheDay.length / 2);
          const timeRange =
            (moment(listTimesOfTheDay[listTimesOfTheDay.length - 1]).valueOf() -
              moment(listTimesOfTheDay[0]).valueOf()) /
            1000;
          const interval = timeRange / count;

          for (let i = 0; i < count; i += 1) {
            const temp = moment(listTimesOfTheDay[0]).valueOf() / 1000 + i * interval;
            const newDate = moment(temp * 1000).milliseconds(0);
            // Round the seconds portion to the nearest 20 seconds
            const seconds = newDate.seconds();
            const roundedSeconds = seconds + (20 - (seconds % 20));
            newDate.seconds(roundedSeconds);

            refEquidistantDates.current.push(newDate.toISOString());
          }
        } else {
          refEquidistantDates.current.push(listTimesOfTheDay[0]);
        }
      } else {
        setImageUrl(undefined);
        refFirstImage.current = Images.ALOCITY_IMAGE_CAMERA;
      }

      setIsEndedReadFromCloud(false);
      setLoading(false);
    };

    if (isEndedReadFromCloud) {
      load();
    }
  }, [isEndedReadFromCloud]);

  const variablesToCss: React.CSSProperties = {
    "--barLineColor": theme.palette.primary.dark,
    "--barLineContainerColor": theme.palette.primary.main,
  } as React.CSSProperties;

  const handleLeave = () => {
    //
  };
  const handleMouseMove = async (event: any, timeStart: string, timeEnd: string) => {
    const duration = moment(timeEnd).diff(moment(timeStart), "seconds");
    if (refBarVerticalLine.current) {
      const barVerticalLine: HTMLDivElement = refBarVerticalLine.current;
      const { left, width } = barVerticalLine?.getBoundingClientRect();
      const valueX = event.clientX;

      const positionX = valueX - left;
      const hoverPosition = positionX / width;
      positionOfVerticalLine.current = positionOfVerticalLine.current + hoverPosition;
      barVerticalLine.style.left = positionOfVerticalLine.current + "px";
      // Calculate the position as a percentage of the timeline width
      const hoverTime = (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * duration;

      const theTime = moment(timeStart).add(hoverTime, "seconds");
      const nearestDate = findNearestDate(
        theTime,
        refEquidistantDates.current.map((value) => moment(value))
      );
      let imageUrl = undefined;

      try {
        if (nearestDate) {
          imageUrl = await getTheBase64Image(
            nearestDate.toISOString(),
            credentialCloudStorange as AWSCredentialResponseModel
          );
        }
      } catch (error) {
        //
      }
      const actualTime = moment(timeStart).add(hoverTime, "seconds");
      setSelectedDate(actualTime);
      setImageUrl(imageUrl);
    }
  };

  function findNearestDate(selectedDate: Moment, listDates: Moment[]) {
    const selectedTimestamp = selectedDate.valueOf();
    if (!listDates.length) {
      return null;
    }
    let nearestDate = listDates[0];
    let nearestDifference = Math.abs(selectedTimestamp - nearestDate.valueOf());
    for (const date of listDates) {
      const difference = Math.abs(selectedTimestamp - date.valueOf());
      if (difference < nearestDifference) {
        nearestDifference = difference;
        nearestDate = date;
      }
    }
    return nearestDate;
  }

  const getTheBase64Image = async (time: string, credentials: AWSCredentialResponseModel) => {
    try {
      const response: GetObjectOutput = await getCloudStorangeImage(
        accountId as unknown as string,
        serialNumber,
        time,
        credentials
      );
      const imageBase64 = Buffer.from(response.Body as any).toString("base64");
      const imageUrl = `data:image/jpeg;base64,${imageBase64}`;
      return imageUrl;
    } catch (err: any) {
      return undefined;
      //
    } finally {
      //
    }
  };

  return (
    <Grid container className={styles.root}>
      <Grid container className={styles.sectionDatePicker}>
        <Grid item xs={12} md={6} className={styles.datePicker_container}>
          <DatePicker
            inline
            selected={dateCalendar}
            selectsDisabledDaysInRange
            locale={i18n.language === "es" ? es : undefined}
            selectsRange={false}
            onChange={(date: Date) => {
              refFirstImage.current = "";
              refEquidistantDates.current = [];
              setLoading(true);
              setDateCalendar(date);
              setDateCalendarMoment(moment(date));
              setSelectedDate(moment(date).startOf("day"));
            }}
            showPopperArrow={true}
            monthsShown={1}
            maxDate={new Date()}
            disabled={false}
            fixedHeight
          />
        </Grid>
        <Grid item xs={12} md={6} className={styles.previewImage_container}>
          {loading ? (
            <Grid container justify="center" style={{ position: "absolute", zIndex: 5 }}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : null}
          <div
            className={
              imageUrl ? styles.previewImage_containerCard_2 : styles.previewImage_containerCard
            }
          >
            <div
              className={styles.imageContainer}
              style={{
                paddingTop: `${(1 / (aspectRatio ?? 1)) * 100}%`,
              }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt={t("Image")} className={styles.image} />
              ) : (
                <img src={Images.ALOCITY_IMAGE_CAMERA} alt={t("Image")} className={styles.image} />
              )}
            </div>
            {selectedDate ? (
              <div className={styles.previewImage_containerCard_dateContainer}>
                <Typography variant="body2" display="inline">
                  {moment(selectedDate).format("hh:mm:ss a")}
                </Typography>
              </div>
            ) : null}
          </div>
        </Grid>
      </Grid>

      {/* Bar */}
      <div
        style={variablesToCss}
        className={styles.bar_section}
        ref={refBarTimeline}
        onMouseMove={(event) => {
          if (!loading) {
            handleMouseMove(
              event,
              dateCalendarMoment.startOf("days").toISOString(),
              dateCalendarMoment.endOf("days").toISOString()
            );
          }
        }}
        onMouseLeave={() => {
          handleLeave();
        }}
        onClick={(event) => {
          const timeStart = moment(dateCalendarMoment.startOf("days").toISOString());
          const duration = moment(dateCalendarMoment.endOf("days").toISOString()).diff(
            moment(timeStart),
            "seconds"
          );
          const hoverTime =
            (event.nativeEvent.offsetX / event.currentTarget.offsetWidth) * duration;
          const actualTime = moment(timeStart).add(hoverTime, "seconds");
          onSelected(actualTime.toISOString());
        }}
      >
        <div className={styles.barContainer}></div>
        <div ref={refBarVerticalLine} className={styles.verticalLine}></div>

        <Typography className={styles.timelineStartDate} variant="caption">
          {rangeHoursOfTheDay[0]}
        </Typography>
        <div className={styles.bar_numbersContainer}>
          {rangeHoursOfTheDay.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  left:
                    ((refBarTimeline.current
                      ? refBarTimeline.current.getBoundingClientRect().width
                      : 24) /
                      24) *
                      index +
                    "px",
                }}
              >
                <>
                  <Typography variant="caption" style={{ marginLeft: "-4px" }}>
                    {index == 0 || index == rangeHoursOfTheDay.length - 1 ? "" : item}
                  </Typography>
                  {index == 0 || index == rangeHoursOfTheDay.length - 1 ? null : (
                    <div className={styles.miniVerticalLine}></div>
                  )}
                </>
              </div>
            );
          })}
        </div>
        <Typography className={styles.timelineEndDate} variant="caption">
          {rangeHoursOfTheDay[rangeHoursOfTheDay.length - 1]}
        </Typography>
      </div>
      <Grid container justify="center" alignItems="center" style={{ marginTop: "24px" }}>
        <Grid item>
          <Typography display="inline" variant={"body2"}>
            {moment(selectedDate).format("LL hh:mm:ss a")}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onSelected(selectedDate.toISOString());
            }}
          >
            <ArrowForward fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CalendarFromPlaybackCloud;
