import { backgroundDefaultColor } from "../palette";

export const MuiTab = {
  MuiTabs: {},
  MuiTab: {
    root: {
      textTransform: "none",
      backgroundColor: backgroundDefaultColor,
    },
  },
};
