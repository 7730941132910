import React, { ReactElement, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Link,
  Typography,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Bowser from "bowser";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "./requestMediaPermissions";
import DialogWrapper from "../../DialogWrapper";
import useTypedTranslation from "../../Hooks/useTypedTranslation";
import { useLocalMedia } from "./useLocalMedia";
const browser = Bowser.getParser(window.navigator.userAgent);

enum DialogType {
  explanation = "explanation",

  systemDenied = "systemDenied",
  userDenied = "userDenied",
  trackError = "trackError",
}
interface Props {
  onDenied: (value: boolean) => void;
  onLocalMedia: (media: MediaStream | undefined) => void;
  disabled?: boolean;
}
const MediaOnboardingDialog: any = ({ onDenied, onLocalMedia, disabled }: Props) => {
  const { t } = useTypedTranslation();
  const [showDialog, setShowDialog] = React.useState<DialogType | null>(null);

  const [audioAllowed, setAudioAllowed] = React.useState<boolean>(true);

  const [errorDetails, setErrorDetails] = React.useState<MediaPermissionsError | undefined>();

  // Create wrapper refs to access values even during setTimeout
  // https://github.com/facebook/react/issues/14010
  const showDialogRef = useRef(showDialog);
  showDialogRef.current = showDialog;
  const audioAllowedRef = useRef(audioAllowed);
  audioAllowedRef.current = audioAllowed;

  const { error: streamError, localMediaStream } = useLocalMedia({
    audio: !disabled,
    video: false,
  });
  React.useEffect(() => {
    if (disabled) {
      onDenied(false);
    }
  }, [disabled]);
  React.useEffect(() => {
    if (localMediaStream) {
      localStorage.setItem("micPermissionMessage", "no");
      setAudioAllowed(true);
      setShowDialog(null);

      onDenied(false);
      onLocalMedia(localMediaStream);
    }
  }, [localMediaStream]);

  React.useEffect(() => {
    if (streamError) {
      if (streamError.type === MediaPermissionsErrorType.SystemPermissionDenied) {
        // user denied permission
        let temp = null;
        temp = document.getElementById("Div_Showing_Micro_Without_Permission");
        if (!temp) {
          setShowDialog(DialogType.systemDenied);
        }
      } else if (streamError.type === MediaPermissionsErrorType.UserPermissionDenied) {
        // browser doesn't have access to devices
        let temp = null;
        temp = document.getElementById("Div_Showing_Micro_Without_Permission");
        if (!temp) {
          setShowDialog(DialogType.userDenied);
        }
      } else if (streamError.type === MediaPermissionsErrorType.CouldNotStartVideoSource) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        let temp = null;
        temp = document.getElementById("Div_Showing_Micro_Without_Permission");
        if (!temp) {
          setShowDialog(DialogType.trackError);
        }
      } else {
        //
      }
      setErrorDetails(streamError);
      onDenied(true);
      setTimeout(() => {
        checkForExplanationDialog();
      }, 500);
    }
  }, [streamError]);

  const checkForExplanationDialog = () => {
    if (!audioAllowedRef.current && showDialogRef.current === null) {
      let temp = null;
      temp = document.getElementById("Div_Showing_Micro_Without_Permission");
      if (!temp) {
        setShowDialog(DialogType.explanation);
      }
    }
  };

  const _renderTryAgain = (text?: string) => {
    return (
      <div style={{ width: "100%", marginTop: 20 }}>
        <Button
          onClick={() => {
            window.location.reload();

            // if (browser.getBrowserName() === "Safari") {
            //   // If on Safari, rechecking permissions results in glitches so just refresh the page
            //   window.location.reload();
            // } else {
            //   checkMediaPermissions();
            // }
          }}
          color="primary"
          style={{ float: "right" }}
        >
          {text ? text : t("Retry")}
        </Button>
      </div>
    );
  };

  const _renderErrorMessage = () => {
    if (!errorDetails) return null;
    return (
      <div style={{ marginTop: 10 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="caption" style={{ color: "red" }}>
              {t("ErrorDetails")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              {errorDetails.name}: {errorDetails.message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const _renderExplanationDialog = () => {
    return (
      <div>
        <Typography variant="h5">{t("AllowMicrophone")}</Typography>
        <Typography variant="subtitle1">{t("AlocityNeedAccessToYourMic")}</Typography>
      </div>
    );
  };

  const _renderUserDeniedDialog = () => {
    return (
      <div>
        <Typography variant="h5">{t("MicrophoneBlocked")}</Typography>
        <Typography>{t("AlocityNeedAccessToYourMic")}</Typography>
        {browser.getBrowserName() !== "Safari" && (
          <div>
            <Typography>
              {t("ClickTheCameraIcon") + " "}
              <img
                alt="icon"
                src={"/images/ic_blocked_camera.svg"}
                style={{ display: "inline" }}
              />{" "}
              {t("inYourBrowserAddressBar")}.
            </Typography>
          </div>
        )}

        {_renderErrorMessage()}
        {_renderTryAgain()}
      </div>
    );
  };

  const _renderSystemDeniedDialog = () => {
    const settingsDataByOS = {
      macOS: {
        name: "System Preferences",
        link: "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera",
      },
    } as any;

    return (
      <div>
        <Typography variant="h5">{t("CanNotUseMicro")}</Typography>
        <Typography>
          {t("YourBrowserMightNotHaveAccess")}{" "}
          {(settingsDataByOS[browser.getOSName()] as boolean) ? (
            <Link
              onClick={() => {
                window.open((settingsDataByOS[browser.getOSName()] as any).link, "_blank");
              }}
            >
              {(settingsDataByOS[browser.getOSName()] as any).name}
            </Link>
          ) : (
            t("Settings")
          )}
          .
        </Typography>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </div>
    );
  };

  const _renderTrackErrorDialog = () => {
    return (
      <div>
        <Typography variant="h5">{t("CanNotUseMicro")}</Typography>
        <Typography>{t("AnotherApplicationUsingYourMic")}</Typography>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </div>
    );
  };

  const _renderDialogContent = () => {
    switch (showDialog) {
      case DialogType.explanation:
        return _renderExplanationDialog();
      case DialogType.systemDenied:
        return _renderSystemDeniedDialog();
      case DialogType.userDenied:
        return _renderUserDeniedDialog();
      case DialogType.trackError:
        return _renderTrackErrorDialog();
    }
  };

  let showed = false;
  if (localStorage.getItem("micPermissionMessage") === "yes") {
    showed = true;
  }
  return (
    <DialogWrapper
      open={!!showDialog && !showed}
      onClose={() => {
        localStorage.setItem("micPermissionMessage", "yes");
        setShowDialog(null);
      }}
    >
      {showDialog && (
        <div id={"Div_Showing_Micro_Without_Permission"}>{_renderDialogContent()}</div>
      )}
    </DialogWrapper>
  );
};

export default MediaOnboardingDialog;
