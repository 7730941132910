import React, { useCallback, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
  })
);

//https://gitlab.com/catamphetamine/react-phone-number-input/-/blob/master/source/CountrySelect.js
export default function PhoneCodeSelect({
  value,
  onChange,
  options,
  iconComponent: Icon,
  getIconAspectRatio,
  arrowComponent: Arrow,
  ...rest
}: any): JSX.Element {
  const classes = useStyles();

  const onChange_ = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value;
      onChange(value === "ZZ" ? undefined : value);
    },
    [onChange]
  );

  return (
    <Select
      id="select-native"
      value={value}
      onChange={onChange_}
      variant="outlined"
      margin="dense"
      renderValue={(selected) => {
        return (
          <Icon
            key={selected}
            aria-hidden
            country={selected}
            aspectRatio={undefined}
            countryname={""} //to avoid errors in the console
            label={""} //to avoid errors in the console
          />
        );
      }}
    >
      {options.map(({ value, label, divider }: any) => (
        <MenuItem
          key={divider ? "|" : value || "ZZ"}
          value={divider ? "|" : value || "ZZ"}
          disabled={divider ? true : false}
        >
          <Grid container alignItems="center" alignContent="center">
            <Icon
              aria-hidden
              country={value}
              label={label}
              aspectRatio={undefined}
              countryname={""} //to avoid errors in the console
            />
            <Typography variant="body2" style={{ marginLeft: "8px" }}>
              {label}
            </Typography>
          </Grid>
        </MenuItem>
      ))}
    </Select>
  );
}
