import { primaryColor, secondaryColor } from "../palette";

export const Stepper = {
  MuiStepIcon: {
    root: {
      color: secondaryColor,
    },
  },
  MuiStepConnector: {
    lineHorizontal: {
      borderColor: secondaryColor,
      borderTopWidth: 2,
      borderRadius: 2,
    },
    completed: {
      "& $line": {
        borderColor: primaryColor,
      },
    },
    active: {
      "& $line": {
        borderColor: primaryColor,
      },
    },
  },
};
