import React, { useState } from "react";
import { find } from "lodash";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button, Grid, LinearProgress, Link, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  emailValidation,
  textValidation,
  phoneNumberValidation,
} from "../../../Utils/Custom/validations";

import { createAccount } from "../../../Utils/API/apiAuth";
import { AccountProvisioningVM } from "../../../Utils/Models/account";
import { AccountValidation } from "../../../Utils/Constants/validationValues";
import useCaptcha, { reRecaptchaActions } from "../../../Utils/Custom/Hooks/useCaptcha";
import CaptchaErrorDialog from "../../../Utils/Custom/CaptchaErrorDialog";
import CustomPhoneNumberInput from "../../../Utils/Custom/CustomPhoneNumberInput";
import Data from "../../../Utils/Constants/values";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  underline: {
    textDecoration: "underline",
  },
  submitContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  loading: {
    marginTop: theme.spacing(1),
  },
  space: {
    marginTop: theme.spacing(2),
  },
}));

interface Props {
  onSuccess: () => void;
}

function AccountCreation({ onSuccess }: Props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const { getToken } = useCaptcha(reRecaptchaActions.SIGNUP);

  const handleCreateAccount = async (values: any) => {
    const captchaToken = await getToken();

    const account: AccountProvisioningVM = {
      account: { name: values.accountName, licenseId: 0, id: 0 },
      user: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        localeISO2: i18n.language,
        id: "",
        roleId: 0,
        status: 1,
      },
      captchaToken: captchaToken,
    };

    try {
      setIsLoading(true);
      const response = await createAccount(account);

      if (!response.data.hasErrors) {
        toast.success(t("AccountCreated"));
        onSuccess();
      } else {
        const hasCaptchaError = find(
          response.data.applicationEvents,
          (event) => event.code === "captcha_verification_error"
        );

        if (hasCaptchaError) setShowCaptchaError(true);
      }
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const validate = (values: {
    accountName: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }) => {
    const errors: any = {};

    if (
      textValidation(
        values.accountName,
        AccountValidation.minAccountNameChar,
        AccountValidation.maxAccountNameChar
      )
    ) {
      errors.accountName = textValidation(
        values.accountName,
        AccountValidation.minAccountNameChar,
        AccountValidation.maxAccountNameChar
      );
    }
    if (
      textValidation(
        values.firstName,
        AccountValidation.minFirstNameChar,
        AccountValidation.maxFirstNameChar
      )
    ) {
      errors.firstName = textValidation(
        values.firstName,
        AccountValidation.minFirstNameChar,
        AccountValidation.maxFirstNameChar
      );
    }
    if (
      textValidation(
        values.lastName,
        AccountValidation.minLastNameChar,
        AccountValidation.maxLastNameChar
      )
    ) {
      errors.lastName = textValidation(
        values.lastName,
        AccountValidation.minLastNameChar,
        AccountValidation.maxLastNameChar
      );
    }
    if (emailValidation(values.email)) {
      errors.email = emailValidation(values.email);
    }
    if (phoneNumberValidation(values.phoneNumber)) {
      errors.phoneNumber = phoneNumberValidation(values.phoneNumber);
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      accountName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    validate,
    onSubmit: async (values: any) => {
      await handleCreateAccount(values);
    },
    onReset: (values: any) => {
      //
    },
  });

  let emptyForm = true;

  if (
    formik.values.accountName &&
    formik.values.firstName &&
    formik.values.lastName &&
    formik.values.email &&
    formik.values.phoneNumber
  ) {
    emptyForm = false;
  }

  const submitDisabled = formik.isSubmitting || emptyForm;

  const inputProps = {
    size: "small",
    margin: "dense",
    variant: "outlined",
    fullWidth: true,
    disabled: formik.isSubmitting,
  };

  return (
    <div>
      <CaptchaErrorDialog open={showCaptchaError} />

      <form className={classes.form} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              id="accountName"
              label={t("AccountName")}
              {...formik.getFieldProps("accountName")}
              error={formik.touched.accountName && formik.errors.accountName ? true : false}
              helperText={
                formik.touched.accountName && formik.errors.accountName
                  ? t(formik.errors.accountName)
                  : ""
              }
              {...(inputProps as any)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="firstName"
              label={t("FirstName")}
              {...formik.getFieldProps("firstName")}
              error={formik.touched.firstName && formik.errors.firstName ? true : false}
              helperText={
                formik.touched.firstName && formik.errors.firstName
                  ? t(formik.errors.firstName)
                  : ""
              }
              {...(inputProps as any)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="lastName"
              label={t("LastName")}
              {...formik.getFieldProps("lastName")}
              error={formik.touched.lastName && formik.errors.lastName ? true : false}
              helperText={
                formik.touched.lastName && formik.errors.lastName ? t(formik.errors.lastName) : ""
              }
              {...(inputProps as any)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="email"
              label={t("Email")}
              {...formik.getFieldProps("email")}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={formik.touched.email && formik.errors.email ? t(formik.errors.email) : ""}
              {...(inputProps as any)}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomPhoneNumberInput
              value={formik.values.phoneNumber || ""}
              onChange={(phoneNumber: string) => formik.setFieldValue("phoneNumber", phoneNumber)}
              onBlur={() => formik.setFieldTouched("phoneNumber", true)}
              disabled={formik.isSubmitting}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? t(formik.errors.phoneNumber)
                  : ""
              }
              label={t("PhoneNumber")}
            />
          </Grid>
        </Grid>

        <Grid item className={classes.space}>
          <Typography variant="body2" display="inline">
            {t("AgreeTerms") + " "}
          </Typography>
          <Link
            href={Data.PRIVACY_MAIN}
            variant="body2"
            className={classes.underline}
            target={"_blank"}
            rel="noopener"
          >
            {t("termsofservice")}
          </Link>
        </Grid>

        <Grid container justify="space-between" alignItems="center">
          <Grid item className={classes.submitContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={submitDisabled}
            >
              {t("CreateAccount")}
            </Button>
          </Grid>
        </Grid>
      </form>

      {isLoading && <LinearProgress className={classes.loading} />}
    </div>
  );
}

export default AccountCreation;
