import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    titleContainer: {
      padding: "10px",
      backgroundColor: theme.palette.background.default,
    },
    title: {},
    subTitle: {},
    contentContainer: {
      padding: "10px",
      display: "flex",
      justifyContent: "start",
    },
    buttonsContainer: {},
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px",
    },
  })
);
interface Props {
  title: string;
  subTitle?: string;
  labelOkButton?: string;
  labelCancelButton?: string;
  onOK?: () => void;
  onCancel?: () => void;
  disableButtons?: boolean;
  hideOkButton?: boolean;
  hideCancelButton?: boolean;
  children?: any;
  contentText?: string;
  disableOkButton?: boolean;
  colorButtonOK?: "primary" | "secondary" | "default" | "inherit";
  textToDelete?: string;
  heldDownEnter?: (pressEnter: boolean) => void;
  onCommentsChanges?: (text: string) => void;
  labelComments?: string;
  maxLengthCommentsText?: number;
}
const DescisionMessage = ({
  title,
  subTitle,
  labelOkButton = "Yes",
  labelCancelButton = "No",
  onOK,
  onCancel,
  disableButtons,
  hideOkButton,
  hideCancelButton,
  children,
  contentText,
  disableOkButton,
  colorButtonOK,
  textToDelete,
  heldDownEnter,
  onCommentsChanges,
  labelComments,
  maxLengthCommentsText = 150,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCorrectTheTextOfTheTextField, setIsCorrectTheTextOfTheTextField] =
    useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: any) => {
    const temp = event.target.value;
    const inputValueLowerCase = temp.toLowerCase();
    setInputValue(inputValueLowerCase);
    if (inputValueLowerCase === t("Delete").toLowerCase()) {
      setIsCorrectTheTextOfTheTextField(true);
    } else {
      setIsCorrectTheTextOfTheTextField(false);
    }
  };

  const keyPress = (event: any) => {
    if (event.key === "Enter" && heldDownEnter) {
      heldDownEnter(true);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keyPress);
    return () => {
      window.removeEventListener("keydown", keyPress);
    };
  }, []);

  return (
    <Card className={classes.root} variant="outlined">
      <Grid container className={classes.titleContainer}>
        <Grid item xs={12} className={classes.title}>
          <Typography variant={"h6"} component="div">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.subTitle}>
          <Typography variant="body1">{subTitle}</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.contentContainer}>
        {children ? (
          children
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="textSecondary">
                {contentText}
              </Typography>
            </Grid>
            {onCommentsChanges && (
              <Grid container justify="center" style={{ paddingTop: "16px" }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={labelComments}
                    rowsMax={4}
                    multiline
                    inputProps={{ maxLength: maxLengthCommentsText }}
                    variant="outlined"
                    size="small"
                    margin="none"
                    onChange={(event) => {
                      const text = event.target.value;
                      console.log("text", text);
                      onCommentsChanges(text);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {textToDelete?.length ? (
              <>
                <Grid container style={{ marginTop: "16px" }}>
                  <Typography variant="subtitle2">
                    {t("InformationToDelete") + ": "}
                    <span style={{ fontWeight: "bold" }}>{textToDelete}</span>
                  </Typography>
                </Grid>

                <Grid container justify="flex-start" style={{ marginTop: "16px" }}>
                  <Typography display="inline" variant="subtitle2" color="textSecondary">
                    {t("ToConfirmText") + ": "}
                    <span style={{ fontWeight: "bold" }}>{t("Delete").toLowerCase()}</span>
                  </Typography>
                </Grid>
                <Grid container>
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="none"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Grid>
              </>
            ) : null}
          </>
        )}
      </Grid>
      <Grid container className={classes.buttonsContainer}>
        <Grid item xs={12} className={classes.buttons}>
          {!hideCancelButton ? (
            <Button
              size={"small"}
              disabled={disableButtons}
              type="reset"
              onClick={onCancel}
              variant="outlined"
              style={{ marginLeft: "8px" }}
            >
              {labelCancelButton}
            </Button>
          ) : null}
          {!hideOkButton ? (
            <Button
              size={"small"}
              disabled={
                disableButtons ||
                disableOkButton ||
                (textToDelete?.length ? !isCorrectTheTextOfTheTextField : false)
              }
              type="submit"
              onClick={onOK}
              variant="contained"
              color={colorButtonOK || "primary" || ""}
              style={{ marginLeft: "8px" }}
            >
              {labelOkButton}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Card>
  );
};
export default DescisionMessage;
