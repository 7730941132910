import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints } from "../Models/api";
import { CancelToken } from "axios";

export const createAccount = (data: any) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/onboarding-and-subscription",
    method: axiosMethods.post,
    data: data,
  });
};

export const sendEmailVerificationAPI = (accessToken?: string) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/verification",
    method: axiosMethods.post,
  }).finally(() => {
    if (accessToken) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${""}`;
    }
  });
};

export const checkEmailVerificationAPI = (code: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/email-verification",
    method: axiosMethods.post,
    data: {
      oobCode: code,
    },
  });
};

export const sendResetPasswordAPI = (email: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/password",
    method: axiosMethods.post,
    params: {
      email: email,
    },
  });
};

export const confirmResetPasswordAPI = (code: string, newPassword: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/password-reset",
    method: axiosMethods.post,
    data: {
      oobCode: code,
      newPassword: newPassword,
    },
  });
};

export const getLicenses = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.licenseManagement + "/licenses",
    method: axiosMethods.get,
  });
};

export const saveTheSignInError = (data: any) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/sign-in-error",
    method: axiosMethods.post,
    params: {
      error: JSON.stringify(data),
    },
  });
};

export const accountSwitch = (accountId: number, token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/account-switch",
    method: axiosMethods.post,
    params: {
      accountId,
    },
    cancelToken: token,
  });
};

export const getAccounts = (accessToken?: string, token?: CancelToken) => {
  if (accessToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/accounts",
    method: axiosMethods.get,
    cancelToken: token,
  });
};

export const updatePassword = (oldPassword: string, newPassword: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/password",
    method: axiosMethods.post,
    data: {
      oldPassword,
      newPassword,
    },
  });
};

export const getAddressAccount = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/address",
    method: axiosMethods.get,
  });
};

export const getCloudVideoCredential = (token?: CancelToken) => {
  return axiosCall(axiosInstance, {
    url: endPoints.securableManagement + "/cloud-video-credentials",
    method: axiosMethods.get,
    cancelToken: token,
  });
};
