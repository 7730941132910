import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Response } from "../Models/api";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosInstanceAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosCall = async (axiosInstance: AxiosInstance, { ...axiosRequest }: AxiosRequestConfig) =>
  await axiosInstance(axiosRequest).then((response: Response) => response);

export { axiosInstance, axiosInstanceAuth, axiosCall };
