import React, { useCallback, useMemo } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput, { FeatureProps } from "react-phone-number-input";
import TextFieldForwardRef from "./TextFieldForwardRef";
import PhoneCodeSelect from "./PhoneCodeSelect";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";

const useStyles = makeStyles((theme) => ({
  input: {
    //
  },
}));
interface CustomPhoneNumberInputProps {
  value: string;
  onChange: (value: string) => void;
  helperText: string;
  onBlur: () => void;
  label: string;
  disabled: boolean;
  error: boolean;
  isFilterByPhonenumber?: boolean;
}
const CustomPhoneNumberInput = (props: CustomPhoneNumberInputProps) => {
  const classes = useStyles();
  const onChange_ = useCallback(
    (value: string) => {
      props.onChange(value);
    },
    [props.onChange]
  );
  const onBlur_ = useCallback(() => {
    props.onBlur();
  }, [props.onBlur]);

  return (
    <>
      <PhoneInput
        // {...props}
        international={true}
        // initialValueFormat={"national"}
        countryOptionsOrder={["US"]}
        defaultCountry="US"
        countryCallingCodeEditable={false}
        // useNationalFormatForDefaultCountryValue={true}
        value={props.value || ""}
        withCountryCallingCode={true}
        addInternationalOption={false}
        onChange={(phoneNumber: string) => {
          onChange_(phoneNumber);
        }}
        inputComponent={TextFieldForwardRef}
        variant="outlined"
        size="small"
        label={props.label}
        flags={flags}
        smartCaret={false}
        disabled={props.disabled}
        error={props.error}
        onBlur={onBlur_}
        limitMaxLength={true}
        {...(!props.isFilterByPhonenumber && {
          countrySelectComponent: PhoneCodeSelect,
        })}
      />
      <FormHelperText
        id={"helperText"}
        error={props.error}
        style={{
          //this is the style that got helperText in the textfield when is variant = outlined and size = small
          marginLeft: "14px",
          marginRight: "14px",
          marginTop: "4px",
        }}
      >
        {props.helperText}
      </FormHelperText>
    </>
  );
};
export default CustomPhoneNumberInput;
