export interface TenantModel {
  id?: number;
  tenantId?: string;
  fullName?: string;
}

export enum EScopesType {
  accesslogs_r = "accesslogs_r",
  user_r = "user_r", //
  user_w = "user_w", //
  role_r = "role_r",
  group_r = "group_r",
  group_w = "group_w",
  credential_r = "credential_r",
  credential_w = "credential_w",
  device_r = "device_r",
  device_w = "device_w",
  schedule_w = "schedule_w",
  deviceremoteaccess_w = "deviceremoteaccess_w",
  devicesettings_w = "devicesettings_w",
  accountpaymethod_r = "accountpaymethod_r",
  accountpaymethod_w = "accountpaymethod_w",
  site_r = "site_r",
  site_w = "site_w",
  timezone_r = "timezone_r",
  zone_w = "zone_w",
  zone_r = "zone_r",
  support_r = "support_r",
  schedule_r = "schedule_r",

  accountsubscription_w = "accountsubscription_w",
  account_r = "account_r",
  account_w = "account_w",
  ///////
  accountall_r = "payment.w",
  accountsettings_r = "accountsettings_r",
  accountsettings_w = "accountsettings_w",

  deviceweb_w = "deviceweb_w",
  deviceall_r = "deviceall_r",
  role_w = "role_w",

  lockdown_w = "lockdown_w",

  mobile_r = "mobile_r",
  mobile_w = "mobile_w",
}
