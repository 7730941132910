import React, { ReactElement, useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ProfileMenu from "./ProfileMenu";
import { pathRoute } from "../../Utils/Routes/routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import SettingIcon from "@material-ui/icons/SettingsOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { AccountModel, AccountSettingsModel } from "../../Utils/Models/account";
import { accountSwitch, getAccounts } from "../../Utils/API/apiAuth";
import {
  FirestorePathRoute,
  NotificationApprovalRequest,
  NotificationModel,
} from "../../Utils/Models/notifications";
import MenuNotification from "../../Utils/Custom/MenuNotification";
import StoreStateModel from "../../Utils/Models/store";
import { toast } from "react-toastify";
import axios, { CancelTokenSource } from "axios";
import useFireStoreSubscription from "../../Utils/Custom/Hooks/useFireStoreSubscription";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import DialogWrapper from "../../Utils/Custom/DialogWrapper";
import LockdownPlans from "../../Utils/Custom/LockdownPlans";
import { Badge, CircularProgress, Grid } from "@material-ui/core";
import DownloadVideoFromCloud from "../../Utils/Custom/VideoDoorWebRTC/PlaybackCloud/DownloadVideoFromCloud";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Images from "../../Utils/Constants/images";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { getAccountSettings } from "../../Utils/API/apiSettings";
import { PathStateObject } from "../../Utils/Models/routesModel";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      border: "0px",
    },
    appBarWithNavigationBar: {
      top: theme.spacing(3),
      bottom: "auto",
    },
    navigationBar: {
      display: "flex",
      zIndex: 1250,
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    grow: {
      // flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(0),
      },
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    right_section_desktop: {
      display: "none",
      // display: "flex",

      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    right_section_mobile: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      // marginTop: 52,
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    logo: {
      objectFit: "contain",
      height: "auto",
      width: "150px",
      [theme.breakpoints.down("xs")]: {
        // display: 'none',
        width: "100px",
      },
    },
    middle_section: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        display: "none",
        // width: "120px",
      },
    },
    left_section: {
      display: "flex",
      alignItems: "center",
      // justifyContent: "flex-start",
    },
    logo__container: {
      display: "flex",
      alignItems: "center",
    },
    left_section_portalSelection: {
      width: "200px",
    },
    right_section: {
      display: "flex",
      alignItems: "center",
    },
    listAccounts: {
      width: "100%",
      maxWidth: 300,
      textOverflow: "ellipsis",
    },
  })
);
interface Props {
  handleOpenSideBarNavClicked: () => void;
  changeTheme: () => void;
  prefersDarkMode: boolean;
  notificationsNumber?: number;
  restricted: boolean;
  showNavigationBar?: boolean;
  componentNavigationBar?: ReactElement;
}

const Header = React.memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const hasPermissionSubscriptionWrite: boolean = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionSubscriptionWrite,
    shallowEqual
  );
  const userName = useSelector((state: StoreStateModel) => state.auth.userName, shallowEqual);
  const roleName = useSelector((state: StoreStateModel) => state.auth.roleName, shallowEqual);

  const accountId: number = useSelector(
    (state: StoreStateModel) => state.auth.accountId,
    shallowEqual
  );
  const accountName: string = useSelector(
    (state: StoreStateModel) => state.auth.accountName,
    shallowEqual
  );

  const pushNotifications: NotificationModel[] = useSelector(
    (state: StoreStateModel) => state.notification.pushNotifications,
    shallowEqual
  );
  const hasPermissionLockdownActivation = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionLockdownActivation,
    shallowEqual
  );

  const showApprovalRequestButton = useSelector(
    (state: StoreStateModel) => state.auth.hasPassRequiresApprovalSettingsFeature,
    shallowEqual
  );

  ////////

  const [counterApproval, setCounterApproval] = useState(0);
  useFireStoreSubscription(
    FirestorePathRoute.ApprovalRequests.Index,
    FirestorePathRoute.ApprovalRequests.Requests.Index,
    (data: NotificationModel[] | NotificationModel) =>
      newApprovalRequestNotification(data as NotificationModel),
    FirestorePathRoute.ApprovalRequests.Requests.approval_requests
  );

  const newApprovalRequestNotification = (data: NotificationModel) => {
    if (data.Code === NotificationApprovalRequest.NotificationUserApprovalRequest) {
      setCounterApproval(data.Data.pendingApprovalsCount);
    }
  };
  /////////////////////////////////////

  const [openNotificationOptionMenu, setOpenNotificationOptionMenu] = useState<boolean>(false);
  const refOptionMenuNotification = React.useRef(null);

  const userId = useSelector((state: StoreStateModel) => state.auth.uid, shallowEqual);
  const hasPermissionSettings = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionSettings,
    shallowEqual
  );

  const isLockdownPlanSetUp = useSelector(
    (state: StoreStateModel) => state.general.isLockdownPlanSetUp,
    shallowEqual
  );
  const showLockdownButton = hasPermissionLockdownActivation && isLockdownPlanSetUp;
  const [showLockdownOptionModal, setShowLockdownOptionModal] = useState(false);
  //////////////Face Notifications///////////////////
  const totalFaceEnrollmentSubscriptions = useRef<string[]>([]);
  totalFaceEnrollmentSubscriptions.current = useSelector(
    (state: StoreStateModel) => state.notification.totalFaceEnrollmentSubscriptions,
    shallowEqual
  );

  useFireStoreSubscription(
    FirestorePathRoute.Users.Index,
    FirestorePathRoute.Users.FaceEnrollmentResult,
    (data: NotificationModel[] | NotificationModel) =>
      newFaceEnrollmentNotification(data as NotificationModel),
    userId
  );

  const newFaceEnrollmentNotification = (data: NotificationModel) => {
    if (totalFaceEnrollmentSubscriptions.current.find((id) => id === data.Data.userId)) {
      dispatch(actions.deleteForFaceEnrollment(data.Data.userId));
      if (data.Data.success) {
        toast.info(t("ImageAddedForTheUser") + ": " + data.Data.fullName, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          t("FailAddImageForTheUser") +
            ": " +
            data.Data.fullName +
            " " +
            t("becauseOf") +
            ": " +
            t(data.Data.failureReason),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };
  ////////////////////////////////
  //////////////Card Notifications///////////////////
  const totalCardEnrollmentSubscriptions = useRef<string[]>([]);
  totalCardEnrollmentSubscriptions.current = useSelector(
    (state: StoreStateModel) => state.notification.totalCardEnrollmentSubscriptions,
    shallowEqual
  );

  useFireStoreSubscription(
    FirestorePathRoute.Users.Index,
    FirestorePathRoute.Users.CardEnrollmentResult,
    (data: NotificationModel[] | NotificationModel) =>
      newCardEnrollmentNotification(data as NotificationModel),
    userId
  );

  const newCardEnrollmentNotification = (data: NotificationModel) => {
    if (totalCardEnrollmentSubscriptions.current.find((id) => id === data.Data.userId)) {
      dispatch(actions.deleteForCardEnrollment(data.Data.userId));
      if (data.Data.success) {
        toast.info(t("CardAddedForTheUser") + ": " + data.Data.fullName, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          t("FailAddCardForTheUser") +
            ": " +
            data.Data.fullName +
            " " +
            t("becauseOf") +
            ": " +
            t(data.Data.failureReason),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };
  ////////////////////////////////
  const [totalOfAccounts, setTotalOfAccounts] = useState<AccountModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [openProfileMenu, setOpenProfileMenu] = React.useState(false); //<null | HTMLElement> event: React.MouseEvent<HTMLElement>

  const refProfile = React.useRef(null);
  const isTouchProfileIconButton = React.useRef(false);

  const handleMenuCloseProfile = () => {
    setOpenProfileMenu(false);
    isTouchProfileIconButton.current = false;
  };

  const signOutProcess = async () => {
    dispatch(actions.signOutUser());
  };

  const [selectedAccount, setSelectedAccount] = useState<AccountModel>({
    name: accountName,
    id: accountId,
    licenseId: 0,
  });

  const getAllAccounts = async () => {
    const source = axios.CancelToken.source();
    if (openProfileMenu === false) {
      setLoading(true);
      await getAccounts(undefined, source.token)
        .then((response) => {
          if (!response.data.hasErrors) {
            setTotalOfAccounts(response.data.payload);
          } else {
            setTotalOfAccounts([]);
          }
        })
        .catch((error) => {
          //interceptor manage the error
        })
        .finally(() => {
          setLoading(false);
          setOpenProfileMenu(true);
        });
    } else {
      setOpenProfileMenu(false);
    }
  };
  let noReadNotificationNumber = 0;
  pushNotifications.forEach((notify) => {
    if (notify.isNoRead) {
      noReadNotificationNumber++;
    }
  });

  let cancelToken: CancelTokenSource;

  const handleAccountSwitch = async (accountValue: AccountModel) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("CancelAPIRequest");
    }
    cancelToken = axios.CancelToken.source();

    await accountSwitch(accountValue.id, cancelToken.token)
      .then((response) => {
        if (!response.data.hasErrors) {
          setSelectedAccount(accountValue);
          dispatch(actions.refreshToken());
          dispatch(actions.removeALLPushNotification());
          toast.dismiss();
          history.push(pathRoute.Profile.Index);
        }
      })
      .catch((error) => {
        //manage in the interceptor
      })
      .finally(() => {
        handleMenuCloseProfile();
      });
  };
  const pendingDeviceInfoDownloadFromCloud = useSelector(
    (state: StoreStateModel) => state.general.pendingDeviceInfoDownloadFromCloud
  );
  const [downloadingPercent, setDownloadingPercent] = useState(0);
  return (
    <div className={classes.grow}>
      {props.showNavigationBar && (
        <AppBar elevation={0} position="fixed" className={classes.navigationBar}>
          {props.componentNavigationBar}
        </AppBar>
      )}
      <AppBar
        style={{ display: "flex" }}
        position={"fixed"}
        elevation={1}
        className={clsx(classes.appBar, {
          [classes.appBarWithNavigationBar]: props.showNavigationBar,
        })}
        color={"default"}
        variant="outlined"
      >
        <Toolbar className={classes.toolbar} variant={"dense"}>
          {/* ///////////////////////////////////// */}
          <div className={classes.left_section}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                props.handleOpenSideBarNavClicked();
              }}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logo__container}>
              <img src={Images.ALOCITY_LOGO} alt="Alocity logo" className={classes.logo} />
            </div>
            <Hidden xsDown implementation="js">
              <div style={{ display: "flex", height: "50px" }}>
                <Divider
                  flexItem
                  orientation="vertical"
                  variant="middle"
                  style={{ marginLeft: 35 }}
                />
              </div>
            </Hidden>
          </div>
          {/* ///////////////////////////////////// */}
          <div className={classes.middle_section}></div>
          {/* ////////////////////////////////////////////// */}
          <div className={classes.right_section}>
            {pendingDeviceInfoDownloadFromCloud ? (
              <Tooltip title={t("ThereIsADownloadInProccess")}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    paddingRight: 2,
                  }}
                >
                  <CircularProgress
                    color="primary"
                    size={30}
                    variant="static"
                    value={downloadingPercent}
                  />
                  <CloudDownloadIcon
                    color="secondary"
                    fontSize="small"
                    style={{ position: "absolute", left: 5, top: 5 }}
                  />
                  <DownloadVideoFromCloud
                    localPlaylist={pendingDeviceInfoDownloadFromCloud.localPlaylist}
                    signedSegments={pendingDeviceInfoDownloadFromCloud.signedSegments}
                    typeExtension={pendingDeviceInfoDownloadFromCloud.typeExtension}
                    isReader={pendingDeviceInfoDownloadFromCloud.isReader}
                    serialNumber={pendingDeviceInfoDownloadFromCloud.serialNumber}
                    onSuccessCallback={(payload: any, isFinal: boolean, downloadTitle: string) => {
                      if (isFinal) {
                        const a = document.createElement("a");
                        a.href = payload;
                        a.download = downloadTitle;
                        a.click();
                        dispatch(actions.downloadVideoFromCloud(undefined));
                      } else {
                        setDownloadingPercent(payload);
                        dispatch(actions.updateDownloadVideoPercent(payload));
                      }
                    }}
                  />
                </div>
              </Tooltip>
            ) : null}
            {showLockdownButton && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setShowLockdownOptionModal(true)}
                  >
                    {t("LockdownPlans2")}
                  </Button>
                </div>
              </div>
            )}
            {showApprovalRequestButton && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {showLockdownButton && (
                  <div style={{ display: "flex", height: "50px" }}>
                    <Divider flexItem orientation="vertical" variant="middle" />
                  </div>
                )}
                <div>
                  <IconButton
                    disabled={props.restricted}
                    ref={refOptionMenuNotification}
                    aria-label="new notifications"
                    color="inherit"
                    onClick={(event: any) => {
                      // if (pushNotifications.length) {
                      setOpenNotificationOptionMenu((prev) => !prev);
                      // }
                    }}
                  >
                    <Badge badgeContent={counterApproval} color="error" max={99} invisible={false}>
                      <AssignmentIcon />
                    </Badge>
                  </IconButton>
                </div>
              </div>
            )}

            <div className={classes.right_section_desktop}>
              {showLockdownButton || showApprovalRequestButton ? (
                <div style={{ display: "flex", height: "50px" }}>
                  <Divider flexItem orientation="vertical" variant="middle" />
                </div>
              ) : null}

              {/* <Tooltip title={"Toggle light/dark theme"}>
                  <IconButton aria-label="Toggle light/dark theme" color="inherit" onClick={() => props.changeTheme()}>
                    {props.prefersDarkMode ? <BrightnessDarkIcon /> : < BrightnessLightIcon />}
                  </IconButton>
                </Tooltip> */}
              {hasPermissionSettings ? (
                <>
                  <div>
                    <Tooltip title={t("Settings") as string}>
                      <span>
                        <IconButton
                          disabled={props.restricted}
                          aria-label="open setting page"
                          color="inherit"
                          onClick={() => {
                            history.push(pathRoute.Settings.Index);
                          }}
                        >
                          <SettingIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>

                  <div style={{ display: "flex", height: "50px" }}>
                    <Divider flexItem orientation="vertical" variant="middle" />
                  </div>
                </>
              ) : null}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <List
                  dense
                  component="div"
                  disablePadding
                  aria-label="account settings"
                  className={classes.listAccounts}
                >
                  <ListItem
                    dense
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="account name"
                  >
                    <ListItemText
                      primary={userName || "Unknow"}
                      secondary={selectedAccount.name}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                      secondaryTypographyProps={{ noWrap: true }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        ref={refProfile}
                        edge="end"
                        aria-label="accounts of current user"
                        aria-controls={"account-menu"}
                        aria-haspopup="true"
                        // onClick={() => getAllAccounts()}
                        color="inherit"
                        onClick={(event: any) => {
                          event.preventDefault();
                          if (!isTouchProfileIconButton.current) {
                            isTouchProfileIconButton.current = false;
                            getAllAccounts();
                          }
                        }}
                        onTouchStart={(event: any) => {
                          isTouchProfileIconButton.current = true;
                        }}
                        onTouchEnd={(event: any) => {
                          getAllAccounts();
                          event.preventDefault();
                          if (openProfileMenu) {
                            isTouchProfileIconButton.current = false;
                          }
                        }}
                      >
                        {openProfileMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden xsDown implementation="js">
        <ProfileMenu
          hasPermissionSubscriptionRead={hasPermissionSubscriptionWrite && !props.restricted}
          showProfileOption={!props.restricted}
          selectedAccount={selectedAccount}
          totalOfAccounts={totalOfAccounts || []}
          loadingAccounts={loading}
          onChangeAccount={handleAccountSwitch}
          userName={userName}
          roleName={roleName}
          open={openProfileMenu}
          reference={refProfile.current}
          onClose={handleMenuCloseProfile}
          onClickProfile={() => {
            handleMenuCloseProfile();
            history.push(pathRoute.Profile.Index);
          }}
          onClickSignOut={() => {
            handleMenuCloseProfile();
            signOutProcess();
          }}
          onClickSubscriptions={() => {
            handleMenuCloseProfile();
            history.push(pathRoute.Subscriptions.Index);
          }}
        />
      </Hidden>
      <MenuNotification
        open={openNotificationOptionMenu}
        reference={refOptionMenuNotification.current}
        onClose={(event: React.MouseEvent<Document, MouseEvent>) => {
          setOpenNotificationOptionMenu(false);
          // dispatch(actions.readALLPushNotification());
        }}
        onClickItem={(id: string) => {
          setOpenNotificationOptionMenu(false);
          history.push(pathRoute.Settings.ApprovalRequestsDetail, {
            from: pathRoute.Settings.ApprovalRequests,
            id: id,
            message: "load the detail page",
          } as PathStateObject);
        }}
      />
      <DialogWrapper
        open={showLockdownOptionModal}
        onClose={() => setShowLockdownOptionModal(false)}
        title={t("LockdownPlans2")}
        maxWidth={"md"}
      >
        <LockdownPlans />
      </DialogWrapper>
    </div>
  );
});
Header.displayName = "Header";
export default Header;
