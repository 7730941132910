import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { deviceCallRequest, getCatalogFromDevice } from "../../Utils/API/apiCatalog";
import AlocityLoading from "../../Utils/Custom/CustomLoading/AlocityLoading";
import { useTranslation } from "react-i18next";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { CatalogEntryModel, CatalogUnitType } from "../../Utils/Models/catalog";
import { toast } from "react-toastify";
import HeaderSearch from "../../Utils/Custom/HeaderSearch";
import styles from "./ReadCatalogNFCTag.module.css";
interface Props {
  serialNumber: string | null;
  isConnected: boolean;
}

export default function ReadCatalogNFCTag({ serialNumber, isConnected }: Props): JSX.Element {
  const { t } = useTranslation();
  const [catalogEntries, setCatalogEntries] = useState<CatalogEntryModel[]>([]);
  const [loading, setLoading] = useState(false);
  const refCatalogEntries = useRef<CatalogEntryModel[]>([]);
  const refSearch = useRef<HTMLInputElement>();

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const response = await getCatalogFromDevice(serialNumber as string);
        if (!response.data.hasErrors) {
          let tempCatalog = response.data.payload;

          const foundCatalogLobby = tempCatalog.find(
            (catalog: CatalogEntryModel) => catalog.unitType === CatalogUnitType.Lobby
          );

          if (foundCatalogLobby) {
            const theOtherCatalog = tempCatalog.filter(
              (catalog: CatalogEntryModel) => catalog.unitType !== CatalogUnitType.Lobby
            );
            tempCatalog = [foundCatalogLobby, ...theOtherCatalog];
          }

          setCatalogEntries(tempCatalog);
          refCatalogEntries.current = tempCatalog;
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    };
    if (serialNumber && isConnected) {
      load();
    }
  }, []);

  const clikedDoorbell = async (catalog: CatalogEntryModel) => {
    try {
      setLoading(true);
      let userId = undefined;
      if (catalog.unitType !== CatalogUnitType.Lobby) {
        userId = catalog.userId;
      }
      const response = await deviceCallRequest(serialNumber as string, userId);
      if (!response.data.hasErrors) {
        toast.success(t("Done"));
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center", marginBottom: 16 }}
          >
            <img
              src={"/images/alocity-logo-color.png"}
              alt="Alocity logo"
              className={styles.media}
            />
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ position: "absolute", top: "10%" }}>
            <AlocityLoading />
          </div>
        ) : null}
        {!isConnected ? (
          <Grid container justify="center">
            <Typography variant="body1">{t("DeviceDisconnectedCanNotNotify")}</Typography>
          </Grid>
        ) : null}
      </Grid>

      {isConnected && (
        <Grid container>
          <Grid item xs={12}>
            <HeaderSearch
              searchInputForwardedRef={refSearch}
              textSearchInput={t("Search")}
              onSearchHandler={() => {
                if (refSearch.current) {
                  const search: string = refSearch.current?.value;
                  let tempCatalogList = refCatalogEntries.current;
                  let isFirstListLobby = false;

                  if (search.length) {
                    if (catalogEntries[0].unitType === CatalogUnitType.Lobby) {
                      isFirstListLobby = true;
                    }
                    const foundFullName = catalogEntries.filter(
                      (catalog: CatalogEntryModel) =>
                        catalog.fullName.toLowerCase().includes(search.toLowerCase()) &&
                        catalog.unitType !== CatalogUnitType.Lobby
                    );
                    if (foundFullName.length) {
                      if (isFirstListLobby) {
                        tempCatalogList = [catalogEntries[0], ...foundFullName];
                      } else {
                        tempCatalogList = foundFullName;
                      }
                    } else {
                      if (isFirstListLobby) {
                        tempCatalogList = [catalogEntries[0]];
                      } else {
                        tempCatalogList = [];
                      }
                    }
                  }
                  setCatalogEntries(tempCatalogList);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {!catalogEntries.length && !loading ? (
              <Grid container style={{ marginTop: "16px" }}>
                <Typography variant="body1">{t("ThereAreNotCatalog")}</Typography>
              </Grid>
            ) : null}

            {catalogEntries.map((catalog: CatalogEntryModel, index) => (
              <Card key={index} variant="outlined" className={styles.card}>
                <CardContent>
                  <Typography variant="body1">
                    {catalog.unitType === CatalogUnitType.Lobby ? t("Lobby") : catalog.displayName}
                  </Typography>
                  {catalog.unitType === CatalogUnitType.Lobby ? null : (
                    <Typography variant="subtitle2">{catalog.fullName}</Typography>
                  )}
                  <Button
                    fullWidth={true}
                    size={"small"}
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "8px" }}
                    onClick={() => clikedDoorbell(catalog)}
                  >
                    {catalog.unitType === CatalogUnitType.Lobby ? t("Lobby") : t("Doorbell")}
                  </Button>
                </CardContent>
              </Card>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}
