import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import CardActions from "@material-ui/core/CardActions/CardActions";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Response } from "../../Utils/Models/api";
import { openTheDoorUsingCloudKey, readDoorsOfCloudKey } from "../../Utils/API/apiCloudKey";
import { toast } from "react-toastify";
import { Swipe } from "../../Utils/Custom/Swipe";
import ArrowLoading from "../../Utils/Custom/CustomLoading/ArrowLoading";
import { DeviceModel } from "../../Utils/Models/devices";
import { ScreenType } from "../../Utils/Models/store";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    media: {
      objectFit: "cover",
      width: "70%",
      display: "block",
      margin: "0px auto",
    },
    title: {
      color: theme.palette.grey[700],
    },
    listItem: {
      background: theme.palette.primary.light,
      marginBottom: "5px",
      borderRadius: "5px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        color: "#fff",
      },
      "&:active": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    link: {
      display: "flex",
      justifyContent: "center",
    },
    form: {},
    spaceTop: {
      marginTop: theme.spacing(2),
    },
    list: {
      maxHeight: 600,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.1)",
        borderRadius: 10,
      },
    },

    listItemTextPrimary: {
      //
    },
    listItemTextSecondary: {
      color: theme.palette.grey[100],
    },
  })
);
interface Props {
  oobCode: string;
  deviceType: string;
}

function CloudKeyOpenDoor({ oobCode, deviceType }: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [doors, setDoors] = useState<DeviceModel[]>([]);
  const [pin, setPin] = useState<number>();
  const [loadingIconLock, setLoadingIconLock] = useState("");
  const readDoors = async () => {
    setLoading(true);
    return await readDoorsOfCloudKey(oobCode)
      .then((response: Response) => {
        if (!response.data.hasErrors) {
          if (response.data.payload.length) {
            setDoors(response.data.payload);
          } else {
            toast.error(t("ThisUserIsNotScheduledToUseAnyDoors"), {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      })
      .catch((error: any) => {
        //
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const openDoor = async (serial: string) => {
    if (!pin) {
      toast.error(t("error_invalid_pin"));
      return;
    }
    setLoading(true);
    return await openTheDoorUsingCloudKey(serial, oobCode, pin ? pin : 0)
      .then((response: Response) => {
        if (!response.data.hasErrors) {
          toast.info(t("OperationSent"), {
            position: toast.POSITION.TOP_CENTER,
          });
          const time: number =
            doors.find((door) => door.serialNumber === serial)?.deviceSettings?.lockReleaseTime ||
            (2 as number);
          setLoadingIconLock(serial);
          setTimeout(() => {
            setLoadingIconLock("");
          }, time * 1000);
        }
      })
      .catch((error: any) => {
        //
      })
      .finally(() => {
        setLoading(false);
        //
      });
  };

  useEffect(() => {
    readDoors();
  }, []);
  const [iconTouchedId, setIconTouchedId] = useState("");
  function onSwiping(position: { x: number; y: number }) {
    if (iconTouchedId) {
      if (document.getElementById(iconTouchedId + "-div")) {
        const positionXDivEnd: number =
          document.getElementById(iconTouchedId + "-end")?.getBoundingClientRect().x ?? 240;
        const positionXList = document.getElementById("list")?.getBoundingClientRect().x ?? 0;

        const maxX = positionXDivEnd - positionXList - 20;

        const element: HTMLDivElement = document.getElementById(
          iconTouchedId + "-div"
        ) as HTMLDivElement;
        if (position.x > 10 && position.x < maxX) {
          element.style.left = position.x.toString() + "px";
          const divOpening: HTMLDivElement = document.getElementById(
            iconTouchedId + "-divOpening"
          ) as HTMLDivElement;
          divOpening.style.display = "flex";
          divOpening.style.height =
            document.getElementById(iconTouchedId + "-listItem")?.getBoundingClientRect().height +
            "px";
          divOpening.style.width = position.x.toString() + "px";
          divOpening.style.justifyContent = "center";
          divOpening.style.alignItems = "center";
          const textOpening: HTMLSpanElement = document.getElementById(
            iconTouchedId + "-textOpening"
          ) as HTMLSpanElement;
          if (position.x > 50) {
            textOpening.style.display = "block";
          } else {
            textOpening.style.display = "none";
          }
        }
        if (position.x > maxX) {
          openDoor(iconTouchedId);
          onSwipEnded();
        }
      }
    }
  }
  function onSwipEnded() {
    if (iconTouchedId) {
      if (document.getElementById(iconTouchedId + "-div")) {
        const element: HTMLDivElement = document.getElementById(
          iconTouchedId + "-div"
        ) as HTMLDivElement;
        element.style.left = "";
        const divOpening: HTMLDivElement = document.getElementById(
          iconTouchedId + "-divOpening"
        ) as HTMLDivElement;
        divOpening.style.width = "0px";
        divOpening.style.display = "none";
      }
      setIconTouchedId("");
    }
  }
  return (
    <>
      <CardContent>
        <Grid container justify="center">
          <img
            src={"/images/alocity-logo-color.png"}
            alt="Alocity logo"
            className={classes.media}
          />
        </Grid>

        <CardContent>
          <Grid container justify="center">
            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
              {t("CloudKey")}
            </Typography>
          </Grid>

          <Grid container justify="center">
            <Grid item xs={12} className={classes.spaceTop}>
              <TextField
                size="small"
                margin="none"
                variant="outlined"
                fullWidth
                id="pin"
                label={t("EnterPinSentToYou")}
                value={pin}
                onChange={(event: any) => setPin(event.target.value)}
                onKeyPress={(event: any) => {
                  if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
                type="number"
              />
            </Grid>
            <Grid item xs={12} className={classes.spaceTop}>
              <List id="list">
                {doors
                  .sort((device1, device2) =>
                    device1.isConnected === device2.isConnected ? 0 : device1.isConnected ? -1 : 1
                  )
                  .map((doorInfo) => {
                    const isTheDeviceOpened = doorInfo.serialNumber === loadingIconLock;
                    const disabled = isTheDeviceOpened || !doorInfo.isConnected;
                    const disabledBecauseLoading = loadingIconLock.length ? true : false;
                    return (
                      <div key={doorInfo.serialNumber}>
                        <Swipe
                          preventDefault={true}
                          stopPropagation={true}
                          detectTouch={!disabled && !disabledBecauseLoading}
                          detectMouse={!disabled && !disabledBecauseLoading}
                          nodeName="div"
                          onSwipeEnd={() => {
                            onSwipEnded();
                          }}
                          onSwipe={(p: any) => {
                            if (deviceType === ScreenType.Mobile) {
                              onSwiping(p);
                            }
                          }}
                        >
                          <ListItem
                            id={doorInfo.serialNumber + "-listItem"}
                            disabled={disabled || disabledBecauseLoading}
                            alignItems="flex-start"
                            key={doorInfo.serialNumber}
                            className={classes.listItem}
                            style={{
                              position: "relative",
                              backgroundColor: isTheDeviceOpened ? theme.palette.success.main : "",
                            }}
                            onClick={() => {
                              if (!disabled && deviceType === ScreenType.Desktop) {
                                openDoor(doorInfo.serialNumber);
                              }
                            }}
                          >
                            <div
                              id={doorInfo.serialNumber + "-divOpening"}
                              style={{
                                position: "absolute",
                                top: 0,
                                backgroundColor: theme.palette.primary.light,
                                display: "none",
                              }}
                            >
                              <Typography
                                component="span"
                                id={doorInfo.serialNumber + "-textOpening"}
                                variant="caption"
                              >
                                {t("Unlocking")}
                              </Typography>
                            </div>

                            <ListItemAvatar>
                              <div
                                id={doorInfo.serialNumber + "-div"}
                                onClick={() => {
                                  if (!disabled && !disabledBecauseLoading) {
                                    setIconTouchedId(doorInfo.serialNumber);
                                  }
                                }}
                                onTouchStart={() => {
                                  if (!disabled && !disabledBecauseLoading) {
                                    setIconTouchedId(doorInfo.serialNumber);
                                  }
                                }}
                                onMouseDown={() => {
                                  if (!disabled && !disabledBecauseLoading) {
                                    setIconTouchedId(doorInfo.serialNumber);
                                  }
                                }}
                                onMouseUp={() => onSwipEnded()}
                                style={{
                                  position: "absolute",
                                }}
                              >
                                <Avatar
                                  src={
                                    isTheDeviceOpened
                                      ? "/images/icon-unlock-24.png"
                                      : "/images/icon-lock-24.png"
                                  }
                                  style={{ background: "#fff" }}
                                  imgProps={{
                                    style: { width: 24, height: 24 },
                                  }}
                                />

                                {isTheDeviceOpened ? (
                                  <CircularProgress
                                    size={32}
                                    style={{ position: "absolute", top: "5px", left: "5px" }}
                                  />
                                ) : doorInfo.isConnected && deviceType === ScreenType.Mobile ? (
                                  <ArrowLoading
                                    style={{ position: "absolute", left: "27px", top: "10px" }}
                                    color={theme.palette.secondary.light}
                                  />
                                ) : null}
                              </div>
                            </ListItemAvatar>

                            <ListItemText
                              primary={
                                <>
                                  <Grid container>{doorInfo.name}</Grid>
                                  <Grid container>{t("Site") + ": " + doorInfo.siteName}</Grid>
                                </>
                              }
                              secondary={
                                doorInfo.isConnected
                                  ? isTheDeviceOpened
                                    ? t("Unlocked")
                                    : disabledBecauseLoading
                                    ? t("Waiting")
                                    : deviceType === ScreenType.Mobile
                                    ? t("Slidetounlockdoor")
                                    : t("ClickToUnlock")
                                  : t("Disconnected")
                              }
                              primaryTypographyProps={{
                                className: classes.listItemTextPrimary,
                              }}
                              secondaryTypographyProps={{
                                className: classes.listItemTextSecondary,
                                variant: doorInfo.isConnected ? "caption" : "body2",
                                style: {
                                  color: doorInfo.isConnected
                                    ? isTheDeviceOpened
                                      ? theme.palette.secondary.main
                                      : ""
                                    : theme.palette.error.dark,
                                },
                              }}
                            />
                            <div
                              id={doorInfo.serialNumber + "-end"}
                              style={{ visibility: "hidden", position: "absolute", right: "0px" }}
                            >
                              End
                            </div>
                          </ListItem>
                        </Swipe>
                      </div>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.link}></CardActions>
      </CardContent>
      {loading ? (
        <Grid container>
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
export default CloudKeyOpenDoor;
