import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import { Button, CardContent, Typography } from "@material-ui/core";
import { pathRoute } from "../../../Utils/Routes/routes";
import CardActions from "@material-ui/core/CardActions/CardActions";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // minWidth: 345,
    },
    media: {
      // height: 140,
    },
    title: {
      display: "flex",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
export default function ConfirmationPasswordReseted() {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const time = setTimeout(() => {
      window.open(pathRoute.SignIn.Index, "_self");
    }, 5000);
    return () => clearTimeout(time);
  }, []);
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
          {t("SuccessChangePassword")}
        </Typography>
      </CardContent>
      <CardActions className={classes.content}>
        <Button
          size="small"
          color="primary"
          onClick={() => {
            window.open(pathRoute.SignIn.Index, "_self"); //history.push(pathRoute.Dashboard); => this not work because dont refresh the page to obtain the scopes
          }}
          variant="contained"
        >
          {t("Continue")}
        </Button>
      </CardActions>
    </Card>
  );
}
