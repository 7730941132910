import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints, QueryFilter } from "../Models/api";
import { UserModel, VisitorModel } from "../Models/users";

export const addPushNotificationId = (idConnection: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/external-system-id",
    method: axiosMethods.post,
    data: {
      externalSystemId: idConnection,
      externalSystemType: 0,
    },
  });
};

export const getCheckedId = (idConnection: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/external-system-id",
    method: axiosMethods.get,
    params: {
      externalId: idConnection,
    },
  });
};

export const deletePushNotificationId = (idConnection: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/external-system-id",
    method: axiosMethods.delete,
    data: {
      externalSystemId: idConnection,
      externalSystemType: 0,
    },
  });
};

export const getUsers = (filter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/page",
    method: axiosMethods.get,
    params: filter,
    signal,
  });
};

export const getUser = (userId: string, includeCards?: boolean, anyStatus?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + userId,
    method: axiosMethods.get,
    params: {
      includeCards,
      anyStatus,
    },
  });
};

export const updateUserInfo = (data: UserModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + data.id,
    method: axiosMethods.put,
    data: data,
  });
};

export const addNewUser = (data: UserModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user",
    method: axiosMethods.post,
    data: data,
  });
};

export const deleteAUser = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + id,
    method: axiosMethods.delete,
  });
};

export const updateLanguage = (language: string) => {
  const data = {
    localeISO2: language,
  };
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + "language",
    method: axiosMethods.put,
    data,
  });
};

export const getUserAccess = (token: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user-access",
    method: axiosMethods.get,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
////////VISITOR////////////
export const addNewVisitor = (data: VisitorModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest",
    method: axiosMethods.post,
    data: data,
  });
};

export const addNewVisitorWithSchedules = (data: VisitorModel) => {
  delete data.visitingUserFullName;
  const idsSch: number[] | null = data.schedules ? data.schedules?.map((value) => value.id) : null;
  delete data.schedules;
  return axiosCall(axiosInstance, {
    url: endPoints.guestScheduleManagment + "/schedules/guest",
    method: axiosMethods.post,
    data: {
      ...data,
      scheduleIds: idsSch,
      visitingUserId: data.visitingUserId ?? null,
    },
  });
};

export const getVisitors = (filter: QueryFilter, signal?: AbortSignal) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/page",
    method: axiosMethods.get,
    params: filter,
    signal,
  });
};

export const deleteAVisitor = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/" + id,
    method: axiosMethods.delete,
  });
};

export const updateVisitorInfo = (data: VisitorModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/" + data.id,
    method: axiosMethods.put,
    data: data,
  });
};

export const getVisitor = (id: string, includeCards?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.guestManagement + "/guest/" + id,
    method: axiosMethods.get,
    params: {
      includeCards,
    },
  });
};

export const getTheListOfUsers = (userIds: string[]) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/by-ids",
    method: axiosMethods.post,
    data: userIds,
  });
};

export const addMobileCredentialToUser = (userId: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/mobile-credential/" + userId,
    method: axiosMethods.post,
  });
};

export const deleteMobileCredentialToUser = (userId: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/mobile-credential/" + userId,
    method: axiosMethods.delete,
  });
};

export const suspendUser = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + id + "/suspend",
    method: axiosMethods.put,
  });
};

export const reactivateUser = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.userManagement + "/user/" + id + "/reactivate",
    method: axiosMethods.put,
  });
};
