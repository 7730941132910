import React, { useState } from "react";
import { toast } from "react-toastify";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import { useFormik } from "formik";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { emailValidation, phoneNumberValidation } from "../validations";
import { shareEventVideo, shareVideoPlaylist } from "../../API/apiDevices";
import axios, { CancelTokenSource } from "axios";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CustomPhoneNumberInput from "../CustomPhoneNumberInput";
import LoadingButton from "../LoadingButton";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  root: { paddingTop: theme.spacing(1.5) },
  form: {
    width: "100%",
    // marginTop: theme.spacing(3),
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
}));
interface Props {
  time: string;
  serialNumber: string;
  deviceName: string;
  onClose: () => void;
  to?: string;
}
export default function ShareEventVideoComponent({
  time,
  serialNumber,
  onClose,
  deviceName,
  to,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const validate = (values: {
    email: string;
    phoneNumber: string;
    linkExpirationInMinutes: number;
  }) => {
    const errors: any = {};
    if (values.email) {
      if (emailValidation(values.email)) {
        errors.email = emailValidation(values.email);
      }
    }
    if (values.phoneNumber) {
      if (phoneNumberValidation(values.phoneNumber)) {
        errors.phoneNumber = phoneNumberValidation(values.phoneNumber);
      }
    }

    if (!(values.email || values.phoneNumber)) {
      errors.email = "Required";
    }

    if (values.linkExpirationInMinutes) {
      if (values.linkExpirationInMinutes < 5) {
        errors.linkExpirationInMinutes = "GreaterThan5";
      }
      if (values.linkExpirationInMinutes >= 721) {
        errors.linkExpirationInMinutes = "LessThan720";
      }
    } else {
      errors.linkExpirationInMinutes = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      phoneNumber: "",
      linkExpirationInMinutes: 5,
    },
    validate,
    onSubmit: async (values: {
      email: string;
      phoneNumber: string;
      linkExpirationInMinutes: number;
    }) => {
      try {
        setLoading(true);
        const cancelToken: CancelTokenSource = axios.CancelToken.source();
        let response;
        if (to) {
          response = await shareVideoPlaylist(
            serialNumber,
            time,
            to,
            values.linkExpirationInMinutes * 60,
            values.email,
            values.phoneNumber,
            deviceName,
            cancelToken.token
          );
        } else {
          response = await shareEventVideo(
            serialNumber,
            time,
            values.linkExpirationInMinutes * 60,
            values.email,
            values.phoneNumber,
            deviceName,
            cancelToken.token
          );
        }

        if (!response.data.hasErrors) {
          toast.success(t("Done"));
          onClose();
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    onReset: (values: any) => {
      //
    },
  });
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="body1">{`${t("Contact")}:`}</Typography>
      </Grid>
      <form className={classes.form} onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <Grid container justify="center">
          <Grid item xs={12} className={classes.spaceTop}>
            <TextField
              size="small"
              variant="outlined"
              margin="none"
              fullWidth
              id="email"
              label={t("Email")}
              {...formik.getFieldProps("email")}
              disabled={formik.isSubmitting || loading}
              error={formik.touched.email && formik.errors.email ? true : false}
              helperText={
                formik.touched.email && formik.errors.email ? t(formik.errors.email as string) : ""
              }
            />
          </Grid>
          <Grid item className={classes.spaceTop}>
            <Typography variant="body1">{`${t("OR")}`}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spaceTop}>
            <CustomPhoneNumberInput
              value={formik.values.phoneNumber || ""}
              onChange={(phoneNumber: string) => formik.setFieldValue("phoneNumber", phoneNumber)}
              onBlur={() => formik.setFieldTouched("phoneNumber", true)}
              disabled={formik.isSubmitting}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber ? true : false}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? t(formik.errors.phoneNumber as string)
                  : ""
              }
              label={t("PhoneNumber")}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.spaceTop}>
            <Typography variant="body1">{t("Valid") + ":"}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.spaceTop}>
            <TextField
              size="small"
              variant="outlined"
              margin="none"
              fullWidth
              type="number"
              id="linkExpirationInMinutes"
              label={t("ExpirationInMinutes")}
              {...formik.getFieldProps("linkExpirationInMinutes")}
              disabled={formik.isSubmitting || loading}
              onKeyPress={(event: any) => {
                if (event.which < 48 || event.which > 57) {
                  event.preventDefault();
                }
              }}
              error={
                formik.touched.linkExpirationInMinutes && formik.errors.linkExpirationInMinutes
                  ? true
                  : false
              }
              helperText={
                formik.touched.linkExpirationInMinutes && formik.errors.linkExpirationInMinutes
                  ? t(formik.errors.linkExpirationInMinutes as string)
                  : ""
              }
            />
          </Grid>
        </Grid>

        <Grid container justify="center" className={classes.spaceTop}>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting || !formik.dirty}
              pending={formik.isSubmitting || loading}
            >
              {t("Share")}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
