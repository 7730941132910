import React, { useState } from "react";
import DialogWrapper from "../../Utils/Custom/DialogWrapper";
import { UpdateFirmwareModel } from "../../Utils/Models/account";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { upgradeFirmware } from "../../Utils/API/apiSettings";
import StoreStateModel from "../../Utils/Models/store";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Link from "@material-ui/core/Link";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      backgroundColor: theme.palette.primary.light,
      display: "flex",
      justifyContent: "center",
      padding: "2px",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    marginButton: {
      marginLeft: "30px",
      borderRadius: "10%",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "25px",
      },

      "&:hover": {
        color: theme.palette.common.white,
      },
      "&:active": {
        color: theme.palette.common.white,
      },
    },
    textInfo: {
      marginLeft: "3px",
    },
    iconClose: {
      right: 9,
      position: "absolute",
      padding: "3px",
      borderRadius: "50%",
      height: "20px",
      width: "20px",
      [theme.breakpoints.down("xs")]: {
        right: 3,
      },
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
        cursor: "pointer",
      },
      "&:active": {
        backgroundColor: theme.palette.grey[400],
      },
    },
  })
);
interface Props {
  onClickCloseIcon: () => void;
  textOfCounter?: string;
}

const UpdateCampaign = ({ onClickCloseIcon, textOfCounter }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const hasPermissionAccountWrite: boolean = useSelector(
    (state: StoreStateModel) => state.auth.hasPermissionAccountWrite,
    shallowEqual
  );
  const accountId: number = useSelector(
    (state: StoreStateModel) => state.auth.accountId,
    shallowEqual
  );

  const dataUpdateFirmware: UpdateFirmwareModel = {
    accountId: accountId,
    scheduleDateTime: undefined,
  };
  const upgradeFirmwarePerAccount = async (data: UpdateFirmwareModel) => {
    try {
      const response = await upgradeFirmware(data);
      if (!response.data.hasErrors) {
        toast.info(t("OperationSent"));
        setOpenDialog(false);
        onClickCloseIcon();
      }
    } catch (error) {
      toast.error("Error");
    }
  };

  return (
    <div>
      <div className={classes.textContainer}>
        <Typography variant="caption" color="textPrimary" className={classes.textInfo}>
          {t("ScheduleUpdate") + " " + "" + textOfCounter + ""}
        </Typography>

        <Link
          underline="always"
          color="textPrimary"
          component="button"
          variant="caption"
          className={classes.marginButton}
          onClick={() => setOpenDialog(true)}
        >
          {t("Update")}
        </Link>
        <ClearIcon
          color="secondary"
          className={classes.iconClose}
          onClick={() => onClickCloseIcon()}
        />
      </div>

      <DialogWrapper
        title={t("DeviceUpdateCampaign")}
        open={openDialog && hasPermissionAccountWrite}
        onClose={() => setOpenDialog(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <Typography>{t("TextUpdateEnhancementsCampaign")}</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ float: "right", marginTop: 15 }}
          onClick={() => upgradeFirmwarePerAccount(dataUpdateFirmware)}
        >
          {t("Update")}
        </Button>
      </DialogWrapper>
    </div>
  );
};

export default UpdateCampaign;
