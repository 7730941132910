import { load } from "../Captcha";

export enum reRecaptchaActions {
  SIGNIN = "SIGNIN",
  SIGNUP = "SIGNUP",
  PASSWORD_RECOVERY = "PASSWORD_RECOVERY",
}

function useCaptcha(actionName: reRecaptchaActions) {
  const getToken = async () => {
    const recaptcha = await load(process.env.REACT_APP_CAPTCHA_SITE_KEY || "");
    const token = await recaptcha.execute(actionName);

    return token;
  };

  return { getToken };
}

export default useCaptcha;
