import React, { useEffect, useState } from "react";
import Bowser from "bowser";

export type MediaPermissionsError = {
  type?: MediaPermissionsErrorType;
  name: string;
  message?: string;
};

export enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = "SystemPermissionDenied",
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = "UserPermissionDenied",
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = "CouldNotStartVideoSource",
  /** all other errors */
  Generic = "Generic",
}

/**
 * Request camera and mic permissions from the browser.
 * @returns
 */
/**
 * @description Opens and returns local media stream. Closes stream on cleanup.
 **/
export function useLocalMedia({
  audio,
  video,
}: {
  audio?: boolean;
  video?: boolean | MediaTrackConstraints;
}): { error: MediaPermissionsError | undefined; localMediaStream: MediaStream | undefined } {
  const [media, setMedia] = useState<MediaStream>();
  const [error, setError] = useState<MediaPermissionsError>();

  useEffect(() => {
    if (!video && !audio) {
      return;
    }

    let _media: MediaStream;
    let isCancelled = false;

    navigator.mediaDevices
      .getUserMedia({ video, audio })
      .then((mediaStream) => {
        if (isCancelled) {
          return;
        }
        _media = mediaStream;
        setMedia(mediaStream);
      })
      .catch((err: Error) => {
        console.error(err);
        if (isCancelled) {
          return;
        }

        const browser = Bowser.getParser(window.navigator.userAgent);
        const browserName = browser.getBrowserName();

        const errName = err.name;
        const errMessage = err.message;
        let errorType: MediaPermissionsErrorType = MediaPermissionsErrorType.Generic;
        if (browserName === "Chrome") {
          if (errName === "NotAllowedError") {
            if (errMessage === "Permission denied by system") {
              errorType = MediaPermissionsErrorType.SystemPermissionDenied;
            } else if (errMessage === "Permission denied") {
              errorType = MediaPermissionsErrorType.UserPermissionDenied;
            }
          } else if (errName === "NotReadableError") {
            errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        } else if (browserName === "Safari") {
          if (errName === "NotAllowedError") {
            errorType = MediaPermissionsErrorType.UserPermissionDenied;
          }
        } else if (browserName === "Microsoft Edge") {
          if (errName === "NotAllowedError") {
            errorType = MediaPermissionsErrorType.UserPermissionDenied;
          } else if (errName === "NotReadableError") {
            errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        } else if (browserName === "Firefox") {
          // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
          if (errName === "NotFoundError") {
            errorType = MediaPermissionsErrorType.SystemPermissionDenied;
          } else if (errName === "NotReadableError") {
            errorType = MediaPermissionsErrorType.SystemPermissionDenied;
          } else if (errName === "NotAllowedError") {
            errorType = MediaPermissionsErrorType.UserPermissionDenied;
          } else if (errName === "AbortError") {
            errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
          }
        }

        // Brave cannot be detected by the bowser library because they removed Brave from the User Agent to reduce fingerprinting
        // https://github.com/brave/browser-laptop/blob/master/CHANGELOG.md#090

        setError({
          type: errorType,
          name: err.name,
          message: err.message,
        });
      });

    return function cleanup() {
      isCancelled = true;

      _media?.getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
      });
    };
  }, [video, audio]);

  return { error, localMediaStream: media };
}
