import { combineReducers } from "redux";
import layout_reducer from "./layout_reducers";
import general_reducer from "./general_reducers";
import auth_reducer from "./auth_reducer";
import notification_reducer from "./notification_reducer";
import StoreStateModel from "../../components/Utils/Models/store";

import { persistReducer } from "redux-persist";
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import storageSession from "redux-persist/lib/storage/session";
import localforage from "localforage";

const layout_persistConfig = {
  key: "alocity_layout_config",
  storage: localforage,
  whitelist: ["isOpenSideBar", "prefersDarkMode"], //here you will persist inside state of the reducer
};

const general_persistConfig = {
  key: "alocity_general_config",
  storage: localforage,
  whitelist: ["portalId"],
};

const auth_persistConfig = {
  key: "alocity_auth_config",
  storage: localforage,
  whitelist: [],
};

const notification_persistConfig = {
  key: "alocity_notifications_config",
  storage: localforage,
  whitelist: [],
};

const createReducer = () =>
  combineReducers({
    layout: persistReducer(layout_persistConfig, layout_reducer),
    general: persistReducer(general_persistConfig, general_reducer),
    auth: persistReducer(auth_persistConfig, auth_reducer),
    notification: persistReducer(notification_persistConfig, notification_reducer),
  } as StoreStateModel | any);
export default createReducer;
