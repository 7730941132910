import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { pathRoute } from "../../../Utils/Routes/routes";
import CardActions from "@material-ui/core/CardActions/CardActions";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 345,
    },
    media: {
      // height: 140,
    },
    title: {
      display: "flex",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
interface Props {
  accountName?: string;
}
export default function ConfirmationSuccessSentResetPassword({ accountName }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // const time = setTimeout(() => {
    //   history.push(pathRoute.SignIn.Index)
    // }, 3000)
    // return () => clearTimeout(time)
  }, []);
  return (
    <Card className={classes.root}>
      {/* <CardActionArea> */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
          {t("AccountRecoveryEmailSent")}
        </Typography>
        {/* <Typography gutterBottom variant="h5" component="h2">
          {accountName}
        </Typography> */}
        <Typography variant="body2" color="textSecondary" component="p" className={classes.content}>
          {t("TextContentSuccessForgotPassword")}
        </Typography>
      </CardContent>
      {/* </CardActionArea> */}
      <CardActions>
        {/* <Button size="small" color="primary">
          OK
        </Button> */}
      </CardActions>
    </Card>
  );
}
