import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints } from "../Models/api";
import { CancelToken } from "axios";

export const createProvisioningFaceInvitation = (
  validFrom: string,
  expiresOn: any,
  subjectUserId: string,
  sendByEmail: boolean,
  sendByText: boolean,
  isVisitor?: boolean
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/provisioning",
    method: axiosMethods.post,
    data: {
      validFrom,
      expiresOn,
      subjectUserId,
      sendByEmail,
      sendByText,
    },
    params: {
      isVisitor,
    },
  });
};

export const getAllProvisioningFaceInvitation = (userId: string, isVisitor?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/provisioning/all",
    method: axiosMethods.get,
    params: {
      subjectUserId: userId,
      isVisitor: isVisitor,
    },
  });
};

export const deleteAProvisioningFaceInvitation = (provisioningKey: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/provisioning/" + provisioningKey,
    method: axiosMethods.delete,
  });
};

export const validateProvisioningKey = (provisioningKey: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/provisioning/" + provisioningKey + "/valid",
    method: axiosMethods.post,
  });
};

export const validatePin = (provisioningKey: string, pin: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/provisioning/" + provisioningKey + "/pin/" + pin,
    method: axiosMethods.post,
  });
};

export const sendProvisioningFace = (
  image: string,
  provisioningKey: string,
  pin: string,
  cancelToken?: CancelToken
) => {
  return axiosCall(axiosInstance, {
    url: endPoints.credentialsManagement + "/face",
    method: axiosMethods.post,
    data: {
      image,
      provisioningKey,
      pin,
    },
    cancelToken: cancelToken,
  });
};
