import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { pathRoute } from "../../../Utils/Routes/routes";
import CardActions from "@material-ui/core/CardActions/CardActions";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import GeneralLoading from "../../../Utils/Custom/CustomLoading/GeneralLoading";
import Box from "@material-ui/core/Box/Box";
import Copyright from "../../../Utils/Custom/Copyright";
import { connect } from "react-redux";
import * as actions from "../../../../store/actions";
import { checkEmailVerificationAPI } from "../../../Utils/API/apiAuth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // maxWidth: 345,
    },
    media: {
      // height: 140,
    },
    title: {
      display: "flex",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      justifyContent: "center",
    },
  })
);
interface Props {
  oobCode: string;
}
function ConfirmationEmailVerified({ oobCode }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const check = async () => {
      return await checkEmailVerificationAPI(oobCode)
        .then((response) => {
          if (!response.data.hasErrors) {
            setSuccess(true);
          }
        })
        .catch((error) => {
          //manage in the interceptor
          // setFailMessage()
        })
        .finally(() => {
          setLoading(false);
        });
    };
    check();
  }, []);

  useEffect(() => {
    const time = setTimeout(() => {
      history.replace(pathRoute.SignIn.Index);
    }, 8000);
    return () => clearTimeout(time);
  }, []);

  // useEffect(() => {
  //   if (verifyEmailErrorCode && verifyEmailErrorCode.length) {
  //     switch (verifyEmailErrorCode) {
  //       case "auth/invalid-action-code":
  //         setFailMessage("auth_invalid_action_code")
  //         break
  //       case "auth/expired-action-code":
  //         setFailMessage("auth_expired_action_code")
  //         break
  //       case "auth/user-not-found":
  //         setFailMessage("auth_user_not_found")
  //         break
  //       case "auth/user-disabled":
  //         setFailMessage("auth_user_disabled")
  //         break

  //       //General Errors https://firebase.google.com/docs/reference/js/firebase.auth.Error
  //       case "auth/too-many-requests":
  //         setFailMessage("auth_too_many_requests")
  //         break
  //       case "auth/web-storage-unsupported":
  //         setFailMessage("auth_web_storage_unsupported")
  //         break
  //       case "auth/network-request-failed":
  //         setFailMessage("auth_network_request_failed")
  //         break

  //       default:
  //         setFailMessage("auth_expired_action_code")
  //         break
  //     }
  //   }
  // }, [verifyEmailErrorCode])

  return (
    <>
      {loading ? (
        <GeneralLoading />
      ) : (
        <>
          <Card className={classes.root}>
            {/* <CardActionArea> */}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                {success ? t("EmailVerified") : t("FailedEmailVerification")}
              </Typography>

              {success ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.content}
                >
                  {t("YouCanSignInNewAccount")}
                </Typography>
              ) : null}
            </CardContent>
            {/* </CardActionArea> */}
            <CardActions className={classes.content}>
              <Button
                color="primary"
                onClick={() => {
                  history.replace(pathRoute.SignIn.Index);
                }}
              >
                {t("GoToSignInPage")}
              </Button>
            </CardActions>
          </Card>
          <Box mt={2}>
            <Copyright />
          </Box>
        </>
      )}
    </>
  );
}
export default ConfirmationEmailVerified;
