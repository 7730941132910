import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Link,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";

import CloseIcon from "@material-ui/icons/Close";

import { Response } from "../../Utils/Models/api";

import {
  validateProvisioningKey,
  validatePin,
  sendProvisioningFace,
} from "../../Utils/API/apiProvisioningFaceInvitation";

import Copyright from "../../Utils/Custom/Copyright";
import { cleanBaseImage } from "../../Utils/Custom/HelperCode";
import { FaceCapture } from "../../Utils/Custom/FaceCapture";
import DialogWrapper from "../../Utils/Custom/DialogWrapper";
import useTranslation, { ValidI18nKey } from "../../Utils/Custom/Hooks/useTypedTranslation";
import Resizer from "../../Utils/Custom/ReactImageFileResizer";

import ProvisioningFaceStatusCard from "./components/ProvisioningFaceStatusCard";
import { Check, CheckBox, CheckBoxOutlineBlank, WarningRounded } from "@material-ui/icons";
import { ScreenType } from "../../Utils/Models/store";
import AlocityLoading from "../../Utils/Custom/CustomLoading/AlocityLoading";
import axios from "axios";
import Data from "../../Utils/Constants/values";

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    margin: theme.spacing(1),
    objectFit: "contain",
    height: "100px",
    width: "200px",
  },
  spaceTop: {
    marginTop: theme.spacing(2),
  },
  spaceTopExtend: { marginTop: theme.spacing(4) },
  errorColor: { color: theme.palette.error.main },
  card: { width: "100%", paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
  actionsContainer: {
    marginTop: theme.spacing(3),
  },
  faceEnrollmentTitle: { fontWeight: "bold" },
  justifyCenter: { display: "flex", flexDirection: "column", alignItems: "center" },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
    maxWidth: "100%",
  },
  listItem: {
    paddingLeft: theme.spacing(2),
  },
}));

interface Props {
  oobCode: string;
  deviceType: string;
}

enum PINStatus {
  Invalid = 0,
  Valid = 1,
  Peending = 2,
}

enum ProvisioningKeyStatus {
  Inactive = 0,
  Active = 1,
  Success = 2,
  Error = 3,
  MaxPinAttempts = 4,
}

const ProvisioningFaceInvitation = ({ oobCode, deviceType }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const provisioningSentRef = useRef("");

  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [pinStatus, setPinStatus] = useState<PINStatus>(2);
  const [provisioningKeyStatus, setProvisioningKeyStatus] = useState<ProvisioningKeyStatus | null>(
    null
  );
  const [showPhotoSection, setShowPhotoSection] = useState(false);
  const [facePicture, setFacePicture] = useState("");
  const [openTakePhoto, setOpenTakePhoto] = useState(false);
  const [faceError, setFaceError] = useState("");

  useEffect(() => {
    /** First Step: Validate oobCode */
    validateObbCode();
  }, []);

  const validateObbCode = async () => {
    try {
      setLoading(true);
      const response: Response = await validateProvisioningKey(oobCode);

      if (!response.data.hasErrors && response.data.payload) {
        setProvisioningKeyStatus(ProvisioningKeyStatus.Active);
      } else {
        setProvisioningKeyStatus(ProvisioningKeyStatus.Inactive);
      }
    } catch (error) {
      setProvisioningKeyStatus(ProvisioningKeyStatus.Inactive);
    } finally {
      setLoading(false);
    }
  };

  const handleValidatePin = async () => {
    try {
      setLoading(true);
      const response: Response = await validatePin(oobCode, pin);

      if (!response.data.hasErrors) {
        setPinStatus(PINStatus.Valid);
        setShowPhotoSection(true);
      } else if (
        response.data.applicationEvents.find(
          (event) => event.code === "error_invalid_provisioning_pin"
        )
      ) {
        setPinStatus(PINStatus.Invalid);
      } else if (
        response.data.applicationEvents.find(
          (event) => event.code === "error_provisioning_max_pin_attempts"
        )
      ) {
        setProvisioningKeyStatus(ProvisioningKeyStatus.MaxPinAttempts);
      } else {
        /* If someone from portal inactivated the invitation */
        setProvisioningKeyStatus(ProvisioningKeyStatus.Inactive);
      }
    } catch (error) {
      setProvisioningKeyStatus(ProvisioningKeyStatus.Inactive);
    } finally {
      setLoading(false);
    }
  };

  const handleSendFacePicture = async (image: string) => {
    const source = axios.CancelToken.source();
    try {
      setLoading(true);
      const imageCleaned = cleanBaseImage(image);

      const response: Response = await sendProvisioningFace(
        imageCleaned,
        oobCode,
        pin,
        source.token
      );

      if (!response.data.hasErrors) {
        toast.success(t("Done"));
        setProvisioningKeyStatus(ProvisioningKeyStatus.Success);
        provisioningSentRef.current = `${oobCode}-${pin}`;
      } else {
        if (
          response.data.applicationEvents.find(
            (event) => event.code === "error_provisioning_max_attempts"
          )
        ) {
          setProvisioningKeyStatus(ProvisioningKeyStatus.Error);
        } else if (
          response.data.applicationEvents.find((event) => event.code === "error_one_face_required")
        ) {
          setFaceError("error_one_face_required");
        } else if (
          response.data.applicationEvents.find((event) => event.code === "error_mask_detected")
        ) {
          setFaceError("error_mask_detected");
        } else if (
          response.data.applicationEvents.find((event) => event.code === "error_low_quality")
        ) {
          setFaceError("error_low_quality");
        } else if (
          response.data.applicationEvents.find(
            (event) => event.code === "error_provisioning_face_matched_different_user"
          )
        ) {
          setFaceError("error_provisioning_face_matched_different_user");
        } else if (
          response.data.applicationEvents.find(
            (event) => event.code === "error_provisioning_invitation_not_found"
          )
        ) {
          /* If someone from portal inactivated the invitation */
          setProvisioningKeyStatus(ProvisioningKeyStatus.Inactive);
        } else {
          setFaceError("Error");
        }
      }
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const onChangePictureMobile = async (file: any) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const base64Data = event.target.result;
        const b64Image = base64Data;
        setFacePicture(b64Image as any);
        setLoading(true);
        handleSendFacePicture(b64Image as any);
      };

      reader.readAsDataURL(file); // Read the file as data URL (Base64)
    }
  };

  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer({
        file,
        maxWidth: 1920,
        maxHeight: 1080,
        compressFormat: "JPEG",
        quality: 100,
        rotation: 0,
        responseUriFunc: (uri: any) => {
          resolve(uri);
        },
        outputType: "base64",
      });
    });

  let content = (
    <Grid container justify="center">
      <CircularProgress variant="indeterminate" />
    </Grid>
  );
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  if (provisioningKeyStatus === ProvisioningKeyStatus.Active) {
    content = (
      <Grid container>
        {/* PIN */}
        <Collapse in={!showPhotoSection && !facePicture} mountOnEnter>
          <Grid container justify="center">
            <Grid item xs={12} className={classes.spaceTop}>
              <Typography>{t("SelfieEnrollDescrption")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.spaceTopExtend}>
              <Typography>{t("SelfieEnrollmentProvidePin")}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.spaceTop}>
              <TextField
                size="small"
                margin="none"
                variant="outlined"
                fullWidth
                id="pin"
                label={t("Pin")}
                value={pin}
                onChange={(event: any) => setPin(event.target.value)}
                onKeyPress={(event: any) => {
                  if (event.which < 48 || event.which > 57) {
                    event.preventDefault();
                  }
                }}
                type="number"
              />
            </Grid>
            {pinStatus === PINStatus.Invalid && (
              <Grid container justify="center" alignItems="center" className={classes.spaceTop}>
                <CloseIcon className={classes.errorColor} />
                <Typography color="error">{t("error_invalid_provisioning_pin")}</Typography>
              </Grid>
            )}
            <Grid item className={classes.actionsContainer}>
              <Button
                variant="contained"
                color="primary"
                disabled={pin.length < 4}
                onClick={() => {
                  handleValidatePin();
                }}
              >
                {t("Enter")}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
        {/* Take Photo */}
        <Collapse in={showPhotoSection && !facePicture} mountOnEnter>
          <Grid container justify="center">
            <Grid item xs={12} className={classes.spaceTop}>
              <Typography>{t("SelfieEnrollStepInstruction")}:</Typography>
            </Grid>
            {/* Steps */}
            <Grid item xs={12} className={classes.spaceTopExtend} style={{ display: "flex" }}>
              <Typography>1.</Typography>
              <Typography className={classes.listItem}>{t("SelfieEnrollStep1")}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography>2.</Typography>
              <Typography className={classes.listItem}>{t("SelfieEnrollStep2")}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography>3.</Typography>
              <Typography className={classes.listItem}>{t("SelfieEnrollStep3")}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography>4.</Typography>
              <Typography className={classes.listItem}>{t("SelfieEnrollStep4")}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography>5.</Typography>
              <Typography className={classes.listItem}>{t("SelfieEnrollStep5")}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Typography>6.</Typography>
              <Typography className={classes.listItem}>{t("SelfieEnrollStep6")}</Typography>
            </Grid>
            {showPhotoSection && !facePicture && (
              <Grid container alignItems="center" style={{ marginTop: 16 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptedTerms}
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<Check color="primary" fontSize="small" />}
                      onChange={(e) => {
                        const value = e.target.checked;
                        setAcceptedTerms(value);
                      }}
                      name="termsAndServices"
                    />
                  }
                  label={
                    <Grid container alignItems="center">
                      <Grid item xs={12}>
                        <Typography display="inline">{t("SelfieEnrollmentTerms") + " "}</Typography>
                        <Link
                          href={Data.PRIVACY_BIO}
                          variant="body1"
                          target={"_blank"}
                          rel="noopener"
                          display="inline"
                        >
                          {t("termsandconditions")}
                        </Link>
                        <Typography display="inline">{"."}</Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ marginTop: 16 }}>
              <Typography>{t("SelfieEnrollReadyToTakePhoto")}:</Typography>
            </Grid>
            {deviceType === ScreenType.Mobile ? (
              <Grid item className={classes.spaceTop}>
                <input
                  disabled={!acceptedTerms}
                  id="cameraFileInput"
                  type="file"
                  accept="image/*"
                  capture="user"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files) onChangePictureMobile(e.target.files[0]);
                  }}
                />
                <label htmlFor="cameraFileInput">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    disabled={!acceptedTerms}
                  >
                    {t("TakePhoto")}
                  </Button>
                </label>
              </Grid>
            ) : (
              <Grid item className={classes.spaceTop}>
                <Button
                  disabled={loading || !acceptedTerms}
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenTakePhoto(true)}
                >
                  {t("TakePhoto")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Collapse>

        <Collapse in={Boolean(facePicture)} mountOnEnter style={{ width: "100%" }}>
          {!faceError ? (
            <Grid container justify="center">
              <Typography>{t("AnalyzingThePhoto")}</Typography>
              <Grid container justify="center" className={classes.spaceTop}>
                <AlocityLoading />
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.spaceTop}>
              <Grid item xs={12} className={classes.justifyCenter}>
                <Typography style={{ textAlign: "center" }}>
                  {t("UnableProcessThePhoto")}
                </Typography>
                <Typography
                  color="error"
                  style={{ textAlign: "center" }}
                  className={classes.spaceTop}
                >
                  {t(faceError as ValidI18nKey)}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.justifyCenter}>
                <WarningRounded style={{ fontSize: 100 }} color="error" />
              </Grid>
              <Grid item xs={12} className={clsx(classes.justifyCenter, classes.spaceTop)}>
                {deviceType === ScreenType.Mobile ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    onClick={() => {
                      setFaceError("");
                      setFacePicture("");
                    }}
                  >
                    {t("Retake")}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setFaceError("");
                      setFacePicture("");
                      setOpenTakePhoto(true);
                    }}
                  >
                    {t("Retake")}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Collapse>
      </Grid>
    );
  } else if (provisioningKeyStatus !== null) {
    content = (
      <ProvisioningFaceStatusCard keyType="provisioningFace" status={provisioningKeyStatus} />
    );
  }

  return (
    <>
      <Container maxWidth="sm" className={classes.spaceTop} style={{ padding: 0 }}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container justify="center">
              <img src={"/images/alocity-logo.png"} alt="Alocity logo" className={classes.media} />
            </Grid>
            <Grid container justify="center">
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.faceEnrollmentTitle}
              >
                {t("ProvisioningFace")}
              </Typography>
            </Grid>
            {content}
          </CardContent>
          {loading ? <LinearProgress /> : null}
        </Card>
        <Box mt={2}>
          <Copyright />
        </Box>
      </Container>
      <DialogWrapper open={openTakePhoto} onClose={() => setOpenTakePhoto(false)} maxWidth="xl">
        <FaceCapture
          forceScreenshotSourceSize={false}
          hideSubmitButton
          onSuccess={async (image: string) => {
            setLoading(true);
            setFacePicture(image);
            setOpenTakePhoto(false);
            handleSendFacePicture(image);
          }}
        />
      </DialogWrapper>
    </>
  );
};

export default ProvisioningFaceInvitation;
