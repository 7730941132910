import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import {
  sideBarNavWidth,
  sideBarNavMiniWidth,
  transition,
} from "../../../assets/theme/generalTheme";
import Hidden from "@material-ui/core/Hidden";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: "15px 15px",
      minHeight: "100vh",
    },
    container: {
      ...transition,
      //in mobile screen
      marginLeft: 0,
      width: "100%",
      marginTop: (props: Props) => (props.isShowingNavigationBar ? theme.spacing(2) : 0),
      //this is not in mobile screen
      [theme.breakpoints.up("sm")]: {
        marginLeft: (props: Props) =>
          props.openSideBarNav ? sideBarNavWidth : sideBarNavMiniWidth,
        width: (props: Props) =>
          props.openSideBarNav
            ? `calc(100% - ${sideBarNavWidth}px)`
            : `calc(100% - ${sideBarNavMiniWidth}px)`,
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    fixedHeightPaper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      height: 240,
    },
  })
);
interface Props {
  children: any;
  openSideBarNav: boolean;
  isShowingNavigationBar?: boolean;
}
function MainContent(props: Props) {
  const classes = useStyles(props);
  return (
    <main className={classes.content}>
      <Toolbar />
      <Grid container className={classes.container}>
        {props.children}
      </Grid>
    </main>
  );
}

export default MainContent;
