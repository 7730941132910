import React, { useState } from "react";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      backgroundColor: theme.palette.warning.main,
      display: "flex",
      justifyContent: "center",
      padding: "2px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },

    textInfo: {
      marginLeft: "3px",
    },
  })
);
interface Props {
  startTime: string;
  durationInMinutes: number;
}

const BarLockdownActivated = ({ startTime, durationInMinutes }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.textContainer}>
        <Typography variant="caption" color="textPrimary" className={classes.textInfo}>
          {t("GeneralLockdownActive") +
            " - " +
            t("Time") +
            ": " +
            moment(startTime).format("L HH:mm") +
            (durationInMinutes ? " - " + t("Duration") + ": " + durationInMinutes : "")}
        </Typography>
      </div>
    </div>
  );
};

export default BarLockdownActivated;
