import { axiosCall, axiosInstance } from "./axiosConfig";
import { axiosMethods, endPoints } from "../Models/api";
import { WorkflowModel } from "../Models/workflow";

export const getWorkflows = (showInactives?: boolean) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/workflows",
    method: axiosMethods.get,
    params: {
      activeOnly: !showInactives,
    },
  });
};

export const updateWorkflow = (data: WorkflowModel) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/workflow",
    method: axiosMethods.put,
    data,
  });
};

export const getWorkflowsTriggers = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/workflow/codes",
    method: axiosMethods.get,
  });
};

export const getWorkflowsActors = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/workflow/actors",
    method: axiosMethods.get,
  });
};
export const addNewWorkflow = (data: WorkflowModel) => {
  const data1: any = data;
  delete data1.id;
  delete data1.createdAt;
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/workflow",
    method: axiosMethods.post,
    data: data1,
  });
};

export const getWorkflow = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/workflow/" + id,
    method: axiosMethods.get,
  });
};

export const startLockdownPlan = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/start-lockdown",
    method: axiosMethods.post,
  });
};

export const finishLockdownPlan = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/finish-lockdown",
    method: axiosMethods.post,
  });
};

export const deleteWorkflow = (id: string) => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/workflow/" + id,
    method: axiosMethods.delete,
  });
};

export const getLockdownPlanInfo = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/lockdown-info",
    method: axiosMethods.get,
  });
};

export const getLockdownWorkflows = () => {
  return axiosCall(axiosInstance, {
    url: endPoints.accountManagement + "/account/lockdown-workflows",
    method: axiosMethods.get,
  });
};
